import axios from "axios";
import { Base64 } from "js-base64";
import moment from "moment";
import Hmacsha1 from "crypto-js/hmac-sha1";
import CryptoBase64 from "crypto-js/enc-base64";
import AppConfig from "../config";
import Icoffice from "../icoffice";
import AreaData from "./area";

/*
//encrypt
var rawStr = "hello world!";
var wordArray = CryptoJS.enc.Utf8.parse(rawStr);
var base64 = CryptoJS.enc.Base64.stringify(wordArray);
  
//decrypt
var parsedWordArray = CryptoJS.enc.Base64.parse(base64);
var parsedStr = parsedWordArray.toString(CryptoJS.enc.Utf8);
*/

// 请求延时（毫秒数，如果请求话费超过了'timeout'的时间，请求将被中断）
//axios.defaults.timeout = 100000;

const _ocean_errors = {
  20001: "系统出错了",
  20002: "系统出错了",
  20003: "系统出错了",
  20004: "系统出错了",
  20005: "系统出错了",
  20006: "系统出错了",
  20007: "不允许该操作",
  20008: "系统出错了",

  80002: "用户不存在",
  80003: "用户已经存在",

  80004: "单品不存在",
  80005: "单品已经存在",
  80006: "单品正在使用",
  80007: "单品正在使用，不能删除",

  80008: "搭配不存在",
  80009: "搭配已经存在",
  80010: "搭配正在使用",

  80011: "标签组不存在",
  80012: "标签组已经存在",
  80013: "标签组正在使用",
  80014: "标签组正在使用，不能删除",
  80015: "标签组存在标签，不能删除",

  80016: "标签不存在",
  80017: "标签已经存在",
  80018: "标签正在使用",
  80019: "标签正在使用，不能删除",

  80020: "顾问号不存在",

  80021: "成员不存在",
  80022: "成员已经存在",

  80023: "成员申请不存在",
  80024: "成员申请已经存在",

  80025: "部门不存在",
  80026: "存在下级部门，不能删除",
  80027: "存在成员，不能删除",

  80028: "已经是管理员",
  80029: "不是管理员",
  80030: "添加管理员失败",

  80031: "服务不存在",
  80032: "服务已经存在",

  80033: "客户不存在",
  80034: "客户已经存在",

  80035: "标签组不存在",
  80036: "标签组已经存在",
  80037: "标签组正在使用",
  80038: "标签组正在使用，不能删除",
  80039: "标签组存在标签，不能删除",

  80040: "标签不存在",
  80041: "标签已经存在",
  80042: "标签正在使用",
  80043: "标签正在使用，不能删除",

  80044: "已经是会员",
  80045: "不是会员",

  80048: "订单不存在",
  80049: "订单已经存在",

  80050: "形象报告不存在",
  80051: "形象报告已经存在",
  80052: "形象报告删除失败",

  80053: "素材文件夹不存在",
  80054: "素材文件夹已经存在",

  80055: "图片素材不存在",
  80056: "图片素材已经存在",

  80057: "标签组不存在",
  80058: "标签组已经存在",
  80059: "标签组正在使用",
  80060: "标签组正在使用，不能删除",
  80061: "标签组存在标签，不能删除",

  80062: "标签不存在",
  80063: "标签已经存在",
  80064: "标签正在使用",
  80065: "标签正在使用，不能删除",

  80066: "单品不存在",
  80067: "单品已经存在",
  80068: "单品正在使用",
  80069: "单品正在使用，不能删除",

  80070: "搭配不存在",
  80071: "搭配已经存在",
  80072: "搭配正在使用",

  80073: "图片删除失败",

  80074: "照片删除失败",

  80075: "顾问不存在",
  80076: "顾问已经存在",

  80077: "照片不存在",
  80078: "照片已经存在",

  80079: "模板不存在",
  80080: "模板已经存在",
  80081: "模板删除失败",

  80082: "用户反馈不存在",
  80083: "用户反馈已经存在",

  80084: "惟若号已经存在",
  80085: "惟若号或密码错误",
  80086: "惟若密码错误",

  80087: "惟若密码已经设置",
  80088: "惟若密码未设置",

  80089: "搭配单品不存在",
  80090: "搭配单品已经存在",

  80091: "订单状态已经改变",
  80092: "不允许该操作",

  80093: "咨询不存在",
  80094: "删除失败",

  80095: "回复不存在",
  80096: "删除失败",

  80097: "不存在该评价",
  80098: "删除评价失败",

  80099: "成员已添加到顾问",

  80100: "客户设置了惟若账号，不能删除",

  80101: "服务产品已下架",
  80102: "服务产品已售罄",

  810002: "删除失败",

  820002: "注册失败！",
  820003: "登录失败！",
  820004: "系统出错了",
  820005: "系统出错了",
  820006: "系统出错了",
  820007: "系统出错了",
  820008: "微信绑定失败",
};

/** token填充字符，共 62 个 */
const _token_paddings =
  "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";

const _axios = axios.create({
  baseURL: AppConfig.oceanApi,
  timeout: 30000,
});

_axios.interceptors.response.use(
  (res) => {
    if (res.status === 200 && typeof res.data === "object") {
      if (res.data.code === 0) {
        //return Promise.resolve(res);
        return res;
      } else {
        res.data.msg = _ocean_errors[res.data.code];
        if (!res.data.msg) {
          res.data.msg = "系统出错了";
        }
        return Promise.reject(res);
      }
    } else {
      res.data = { msg: "系统出错了!" };
      return Promise.reject(res);
    }
  },
  (err) => {
    return Promise.reject(err);
  }
);

function getToken() {
  let token = "";

  let uidBase64 = Base64.encode("" + Icoffice.getUid(true));
  if (uidBase64) {
    /** 在uid base64每个字符前后插入3个随机得到的base64编码字符（前2个字符，后1个字符）； */
    for (let c of uidBase64) {
      let index = Math.floor(Math.random() * 62);
      token += _token_paddings[index];

      index = Math.floor(Math.random() * 62);
      token += _token_paddings[index];

      token += c;

      index = Math.floor(Math.random() * 62);
      token += _token_paddings[index];
    }
  }

  return token;
}

function request(options = {}, noToken) {
  let opts = Object.assign({}, options);

  // 时间戳
  if (!opts.timestamp) {
    opts.timestamp = moment().format("YYYY-MM-DD HH:mm:ss");
  }

  // 身份认证参数
  if (!noToken) {
    opts.token = getToken();
  }

  return _axios.post("", opts);
}

/************************************************
 *
 * 性别
 *
 ***********************************************/

const genderCode = {
  unkown: 0,
  /* 男 */
  male: 1,
  /* 女 */
  female: 2,
};

const genders = [
  { gender: 0, name: "" },
  { gender: 1, name: "男" },
  { gender: 2, name: "女" },
];

function getGenders() {
  return genders;
}

function getGenderName(gender) {
  let gend = genders[gender];
  if (gend) {
    return gend.name;
  }
  return "";
}

function getAreaList() {
  return AreaData;
}

/**
 *
 * @param {*} area1_id
 * @param {*} area2_id
 * @param {*} area3_id
 */
function getAreaNameById3(area1_id, area2_id, area3_id) {
  let area_name = "";

  if (area1_id) {
    area_name += getArea1Name(area1_id);
  }
  if (area2_id) {
    area_name += getArea2Name(area2_id);
  }
  if (area3_id) {
    area_name += getArea3Name(area3_id);
  }

  return area_name;
}

function getArea1Name(area1_id) {
  if (area1_id) {
    return AreaData.province_list[area1_id];
  }

  return "";
}

function getArea2Name(area2_id) {
  if (area2_id) {
    return AreaData.city_list[area2_id];
  }

  return "";
}

function getArea3Name(area3_id) {
  if (area3_id) {
    return AreaData.county_list[area3_id];
  }

  return "";
}

/**
 * 单品（搭配）匹配度
 */
const matchingScores = [
  { score: 0, name: "" },
  { score: 1, name: "非常差" },
  { score: 2, name: "差" },
  { score: 3, name: "一般" },
  { score: 4, name: "好" },
  { score: 5, name: "非常好" },
];

function getMatchingScoreName(score) {
  let name = "";
  if (score) {
    let sname = matchingScores[score];
    if (sname) {
      name = sname.name;
    }
  }

  return name;
}

/**
 * 成员/会员 申请状态
 */
const ApplyStates = [
  { state: 0, name: "待处理" },
  { state: 1, name: "已同意" },
  { state: 2, name: "已拒绝" },
  { state: 3, name: "已过期" },
];

function getMemberApplyStateName(state) {
  let astate = ApplyStates[state];
  if (astate) {
    return astate.name;
  }

  return "";
}

/**
 * 账户密码登录
 * 参数：
 *   ucode: ''
 */
function login(ucode, upwd) {
  let timestamp = moment().format("YYYY-MM-DD HH:mm:ss");
  let opts = {
    method: "user.login.acpwd",
    ucode,
    timestamp,
  };

  opts.ap_sign = CryptoBase64.stringify(Hmacsha1(ucode + timestamp, upwd));
  return request(opts, true);
}

/**
 * 获取用户信息
 * @param params: {
 *   id: 1,
 *   fields: [ '', '' ]
 * }
 */
function getUser(params) {
  let opts = {
    method: "user.get",
    id: params.id,
    fields: params.fields,
  };

  return request(opts);
}

/**
 * 获取最后切换的团队
 */
function getMemberLastTeam() {
  let opts = {
    method: "team.member.lastteam.get",
  };

  return request(opts);
}

/**
 * 更新最后切换的团队
 * @param {*} member_id 成员id
 */
function updateMemberLastTeam(member_id) {
  let opts = {
    method: "team.member.lastteam.update",
    member_id,
  };

  return request(opts);
}

/**
 * 获取顾问号详情
 * @param id
 * @param fields
 */
function getTeam(id, fields) {
  let opts = {
    method: "team.team.get",
    id,
    fields,
  };

  return request(opts);
}

/**
 * 获取顾问号信息（包括被删除顾问号）
 * @param {*} id 团队id
 * @param {*} fields
 */
function getTeamInfo(id, fields) {
  let opts = {
    method: "team.teaminfo.get",
    id,
    fields,
  };

  return request(opts);
}

/**
 * 获取成员参与的团队列表
 * @param params: {
 *   offset
 *   num
 * }
 */
function getMemberTeams(params) {
  let opts = {
    method: "team.member.teams.get",
    offset: params.offset,
    num: params.num,
  };

  return request(opts);
}

/**
 * 获取成员列表
 * @param params: {
 *  *team_id
 *   uid
 *   ids
 *   fields
 *   name
 *   department_id
 *   offset
 *   num
 * }
 */
function getMembers(params) {
  let opts = {
    method: "team.members.get",
    team_id: params.team_id,
    uid: params.uid,
    ids: params.ids,
    fields: params.fields,
    name: params.name,
    department_id: params.department_id,
    offset: params.offset,
    num: params.num,
  };

  return request(opts);
}

/**
 * 检查是否团队管理员
 * @param params: {
 *  *team_id
 *  *member_id
 * }
 */
function checkTeamAdmin(params) {
  let opts = {
    method: "team.admin.check",
    team_id: params.team_id,
    member_id: params.member_id,
  };
  return request(opts);
}

/**
 * 检查是否团队超级管理员
 * @param team_id
 */
function checkTeamSuperAdmin(team_id) {
  let opts = {
    method: "team.superadmin.check",
    team_id,
  };
  return request(opts);
}

const BizServiceState = {
  /** 正常服务中 */
  Normal: 0,

  /** 已下架 */
  OffShelf: 1,
};

const BizServiceMaxPrice = 99999999.99;
const BizServiceMaxNum = 99999;

/**
 * 获取服务列表
 * @param params: {
 *   ids: [1, 2], //id列表，ids和team_id至少传1个；
 *   team_id
 *   state
 *   fields
 *   name
 *   sort_by
 *   offset
 *   num
 * }
 */
function getBizServices(params) {
  let opts = {
    method: "team.bizservices.get",
    ids: params.ids,
    team_id: params.team_id,
    state: params.state,
    fields: params.fields,
    name: params.name,
    sort_by: params.sort_by,
    offset: params.offset,
    num: params.num,
  };

  return request(opts);
}

/**
 * 更新服务
 * @param service: {
 *  *id
 *   name
 *   imgreport
 *   clothes
 *   dapei
 *   zixun: false
 *   state: 0,
 *   img: '', //主图，OSS key；允许是空字符串，表示删除
 *   wideimg: '', //宽图，OSS key；允许是空字符串，表示删除
 *   brief: '', //简述（摘要）；允许是空字符串，表示删除
 *   description: '', //描述（图文），OSS key；允许是空字符串，表示删除
 *   specmode: 0, //规格模式
 *   spec_list: [ //规格信息列表
 *     {
 *       name: "", //规格名称
 *       items: ["",""], //规格项列表
 *     }
 *   ],
 *   su_list: [ //SU信息列表
 *     {
 *       price: 1.01, //价格
 *       num: 233, //数量
 *     }
 *   ],
 *   su_pos: 0, //默认SU的位置（索引）
 * }
 */
function updateBizService(service) {
  let opts = {
    method: "team.bizservice.update",
  };
  Object.assign(opts, service);
  return request(opts);
}

/**
 * 获取客户详情
 * @param *id
 * @param  fields
 */
function getCustomer(id, fields) {
  let opts = {
    method: "team.customer.get",
    id,
    fields,
  };
  return request(opts);
}

/** 订单状态 */
const BsOrderState = {
  /* 创建订单 */
  Created: 0,
  /* 已付款 */
  Paid: 10,
  /* 顾问号已接单 */
  Took: 20,
  /* 服务中 */
  InService: 30,
  /* 顾问服务完毕 */
  ServiceDone: 35,
  /* 已完成 */
  Completed: 40,
  /* 已取消 */
  Canceled: 50,
  /* 退款成功 */
  Refunded: 60,
};

/** 售后状态 */
const BsOrderAfterState = {
  /* 无售后 */
  None: 0,
  /* 等待顾问号取消订单 */
  WaitingTeamCancel: 10,
  /* 顾问号拒绝取消订单 */
  TeamRejectCancel: 15,
  /* 等待顾问号处理退款申请 */
  WaitingTeamRefund: 20,
  /* 顾问号拒绝退款申请 */
  TeamRejectRefund: 25,
  /* 取消成功 */
  Canceled: 40,
  /* 退款成功 */
  Refunded: 50,
};

/** Action */
const BsOrderAction = {
  // 创建（提交）订单
  Create: 1,
  // 修改订单信息
  Alter: 2,
  // 付款
  // Pay: 5,
  // 顾问号接单
  TeamTakeOrder: 10,
  // 顾问号服务完毕
  TeamServiceDone: 15,
  // 买家确认完成
  ConfirmCompletion: 20,
  // 买家申请取消订单
  ApplyCancel: 30,
  // 买家撤销<取消申请>
  RevokeCancel: 31,
  // 顾问号同意<取消订单>
  TeamAgreeCancel: 32,
  // 顾问号拒绝<取消订单>
  TeamRejectCancel: 33,
  // 买家取消订单（无需申请）
  CancelNoApply: 34,
  // 买家申请退款
  ApplyRefund: 40,
  // 买家撤销<退款申请>
  RevokeRefund: 41,
  // 顾问号同意退款
  TeamAgreeRefund: 42,
  // 顾问号拒绝退款
  TeamRejectRefund: 43,
};

/**
 * 获取订单状态名称
 * @param {*} state
 * @param {*} isTeam
 * @param {*} price
 */
function getBsOrderStateName(state, isTeam, price) {
  let stateName = "";
  switch (state) {
    case BsOrderState.Created:
      if (isTeam) {
        stateName = price ? "待买家付款" : "待接单";
      } else {
        stateName = price ? "待付款" : "待顾问号接单";
      }
      break;
    case BsOrderState.Paid:
      stateName = isTeam ? "买家已付款" : "已付款";
      break;
    case BsOrderState.Took:
      stateName = isTeam ? "已接单" : "顾问号已接单";
      break;
    case BsOrderState.InService:
      stateName = "服务中";
      break;
    case BsOrderState.ServiceDone:
      stateName = isTeam ? "服务完毕" : "顾问服务完毕";
      break;
    case BsOrderState.Completed:
      stateName = "已完成";
      break;
    case BsOrderState.Canceled:
      stateName = "已取消";
      break;
    case BsOrderState.Refunded:
      stateName = "退款成功";
      break;
    default:
      break;
  }

  return stateName;
}

/**
 * 获取售后状态名称
 * @param {*} state
 * @param {*} isTeam
 */
function getBsOrderAfterStateName(state, isTeam) {
  let stateName = "";
  switch (state) {
    case BsOrderAfterState.WaitingCancel:
      stateName = isTeam ? "申请取消订单" : "取消中";
      break;
    case BsOrderAfterState.WaitingRefund:
      stateName = isTeam ? "申请退款" : "退款中";
      break;
    case BsOrderAfterState.Canceled:
      stateName = "取消成功";
      break;
    case BsOrderAfterState.Refunded:
      stateName = "退款成功";
      break;
    default:
      break;
  }

  return stateName;
}

/**
 * 获取服务订单列表
 * @param params: {
 *   ids //订单id列表，ids、uid、team_id至少传1个；
 *   uid
 *   team_id
 *  *by_team
 *   fields
 *   states: [1, 2], //订单状态
 *   astates: [1, 2], //售后状态
 *   name
 *   price: 价格（等于）
 *   price_min: 价格最小值（大于等于）
 *   price_max: 价格最大值（小于等于）
 *   create_time_start: '', //下单时间(始)，格式：YYYY-MM-DD HH:mm:ss，或YYYY/MM/DD HH:mm:ss，其中MM、DD是2位补0；
 *   create_time_end: '', //下单时间(止)，格式：YYYY-MM-DD HH:mm:ss，或YYYY/MM/DD HH:mm:ss，其中MM、DD是2位补0；
 *   imgreport
 *   clothes
 *   dapei
 *   zixun: false
 *   sort_by
 *   offset
 *   num
 * }
 */
function getBizOrders(params) {
  let opts = {
    method: "team.bizorders.get",
    ids: params.ids,
    uid: params.uid,
    team_id: params.team_id,
    by_team: params.by_team,
    fields: params.fields,
    states: params.states,
    astates: params.astates,
    name: params.name,
    price: params.price,
    price_min: params.price_min,
    price_max: params.price_max,
    create_time_start: params.create_time_start,
    create_time_end: params.create_time_end,
    imgreport: params.imgreport,
    clothes: params.clothes,
    dapei: params.dapei,
    zixun: params.zixun,
    sort_by: params.sort_by,
    offset: params.offset,
    num: params.num,
  };

  return request(opts);
}

/**
 * 获取服务订单详情
 * @param *id
 * @param *by_team
 * @param  fields
 */
function getBizOrder(id, by_team, fields) {
  let opts = {
    method: "team.bizorder.get",
    id,
    by_team,
    fields,
  };
  return request(opts);
}

/**
 * 获取订单形象报告列表
 * @param params: {
 *   ids: [1, 2], //报告id列表
 *   orderid: 1, //订单id
 *   uid: 5, //用户id
 *   fields
 *   title
 *   offset
 *   num
 * }
 */
function getOrderImgRpts(params) {
  let opts = {
    method: "team.bizorder.imgrpts.get",
    ids: params.ids,
    orderid: params.orderid,
    uid: params.uid,
    fields: params.fields,
    title: params.title,
    offset: params.offset,
    num: params.num,
  };

  return request(opts);
}

/**
 * 更新订单形象报告
 * @param imgrpt: {
 *  *id
 *   title
 *   url
 *   doctype
 *   ueditable: false, //用户可编辑
 *   report_updated: false, //报告内容更新
 * }
 */
function updateOrderImgRpt(imgrpt) {
  let opts = {
    method: "team.bizorder.imgrpt.update",
  };
  Object.assign(opts, imgrpt);
  return request(opts);
}

/** 文档分享的权限 */
const TdsSharePermit = {
  // 没有权限
  No: 0,
  // 只读
  Read: 1,
  // 编辑
  Edit: 2,
};

/**
 * 获取文档列表
 * @param params: {
 *   ids
 *  *team_id
 *   parent_id
 *   dtype: 0, //类型
 *   fields
 *   name
 *   doctypes: ['rtf', 'doc'], //文档类型
 *   sort_by
 *   offset
 *   num
 * }
 */
function listTeamDsDoc(params) {
  let opts = {
    method: "team.dsdoc.list",
    ids: params.ids,
    team_id: params.team_id,
    parent_id: params.parent_id,
    dtype: params.dtype,
    fields: params.fields,
    name: params.name,
    doctypes: params.doctypes,
    sort_by: params.sort_by,
    offset: params.offset,
    num: params.num,
  };

  return request(opts);
}

/**
 * 获取文档详情
 * @param *id
 * @param  fields
 */
function getTeamDsDoc(id, fields) {
  let opts = {
    method: "team.dsdoc.get",
    id,
    fields,
  };
  return request(opts);
}

/**
 * 添加文档
 * @param dsDoc: {
 *  *team_id
 *   parent_id: 3, //上级（或所属文件夹）id
 *   dtype: 0, //类型
 *  *name
 *   rname
 *   doctype
 *   fromtype: 0
 *   fromid: 0
 *   shrpermit: 0, //分享权限，默认0
 * }
 */
function addTeamDsDoc(dsDoc) {
  let opts = {
    method: "team.dsdoc.add",
  };
  Object.assign(opts, dsDoc);
  return request(opts);
}

/**
 * 更新文档
 * @param dsDoc: {
 *  *id
 *   parent_id: 3, //上级（或所属文件夹）id，0表示顶层文档或文件夹
 *   name
 *   rname
 *   doctype
 *   shrpermit: 0, //分享权限
 *   oss_updated: false, //oss上文档内容更新
 * }
 */
function updateTeamDsDoc(dsDoc) {
  let opts = {
    method: "team.dsdoc.update",
  };
  Object.assign(opts, dsDoc);
  return request(opts);
}

function deleteTeamDsDoc(id) {
  let opts = {
    method: "team.dsdoc.delete",
    id,
  };
  return request(opts);
}

/**
 * 获取文档分享列表
 * @param params: {
 *   ids
 *   doc_id
 *   share_uid
 *   to_uid
 *   permits: [1, 2], //权限
 *   fields
 *   fields_doc
 *   sort_by
 *   offset
 *   num
 * }
 */
function listTeamDsDocShare(params) {
  let opts = {
    method: "team.dsdoc.share.list",
    ids: params.ids,
    doc_id: params.doc_id,
    share_uid: params.share_uid,
    to_uid: params.to_uid,
    permits: params.permits,
    fields: params.fields,
    fields_doc: params.fields_doc,
    sort_by: params.sort_by,
    offset: params.offset,
    num: params.num,
  };

  return request(opts);
}

/**
 * 获取文档分享详情
 * @param params: {
 *  *id
 *   fields
 *   fields_doc
 * }
 */
function getTeamDsDocShare(params) {
  let opts = {
    method: "team.dsdoc.share.get",
    id: params.id,
    fields: params.fields,
    fields_doc: params.fields_doc,
  };
  return request(opts);
}

/**
 * 添加文档分享
 * @param shr: {
 *  *doc_id
 *  *share_uid
 *  *to_uid
 *   permit: 1, //权限；默认0（没有权限）
 * }
 */
function addTeamDsDocShare(shr) {
  let opts = {
    method: "team.dsdoc.share.add",
  };
  Object.assign(opts, shr);
  return request(opts);
}

/**
 * 更新文档分享
 * @param shr: {
 *  *id
 *   permit: 1, //权限
 * }
 */
function updateTeamDsDocShare(shr) {
  let opts = {
    method: "team.dsdoc.share.update",
  };
  Object.assign(opts, shr);
  return request(opts);
}

function deleteTeamDsDocShare(id) {
  let opts = {
    method: "team.dsdoc.share.delete",
    id,
  };
  return request(opts);
}

/**
 * 添加富文本图片列表
 * @param params: {
 *  *rid
 *  *images: [ '','' ], //图片列表，当前是OSS key，或者OSS object name
 * }
 */
function AddRtfImages(params) {
  let opts = {
    method: "weiruo.rtf.imgs.add",
    rid: params.rid,
    images: params.images,
  };

  return request(opts);
}

/**
 * 获取形象报告模板列表
 * @param params: {
 *  *teamid
 *   ids
 *   fields
 *   name
 *   sort_by
 *   offset
 *   num
 * }
 */
function getTeamImgrptTemplates(params) {
  let opts = {
    method: "team.imgrpt.templates.get",
    teamid: params.teamid,
    ids: params.ids,
    fields: params.fields,
    name: params.name,
    sort_by: params.sort_by,
    offset: params.offset,
    num: params.num,
  };

  return request(opts);
}

/**
 * 更新形象报告模板
 * @param template: {
 *  *id
 *   name
 *   url
 *   doctype
 *   oss_updated: false, //oss上模板内容更新
 * }
 */
function updateTeamImgrptTemplate(template) {
  let opts = {
    method: "team.imgrpt.template.update",
  };
  Object.assign(opts, template);
  return request(opts);
}

function deleteOssObjects(bucket, objects) {
  let opts = {
    method: "oss.objects.delete",
    bucket,
    objects,
  };
  return request(opts);
}

function deleteOssObject(bucket, object) {
  let opts = {
    method: "oss.object.delete",
    bucket,
    object,
  };
  return request(opts);
}

/**
 *
 * @returns
 * {
 *   "credentials": {
 *     "accessKeyId": "STS.L4aBSCSJVMuKg5U1****",
 *     "accessKeySecret": "wyLTSmsyPGP1ohvvw8xYgB29dlGI8KMiH2pK****",
 *     "securityToken": "********",
 *     "expiration": "2015-04-09T11:52:19Z",
 *   }
 * }
 */
function getAliOssStsAssumerole() {
  let opts = {
    method: "alioss.sts.assumerole",
  };
  return request(opts);
}

export default {
  genderCode,
  getGenders,
  getGenderName,
  getAreaList,
  getAreaNameById3,
  getArea1Name,
  getArea2Name,
  getArea3Name,
  getMatchingScoreName,
  getMemberApplyStateName,
  login,
  getUser,
  getMemberLastTeam,
  updateMemberLastTeam,
  getTeam,
  getTeamInfo,
  getMemberTeams,
  getMembers,
  checkTeamAdmin,
  checkTeamSuperAdmin,
  BizServiceState,
  BizServiceMaxPrice,
  BizServiceMaxNum,
  getBizServices,
  updateBizService,
  getCustomer,
  BsOrderState,
  BsOrderAfterState,
  BsOrderAction,
  getBsOrderStateName,
  getBsOrderAfterStateName,
  getBizOrders,
  getBizOrder,
  getOrderImgRpts,
  updateOrderImgRpt,
  TdsSharePermit,
  listTeamDsDoc,
  getTeamDsDoc,
  addTeamDsDoc,
  updateTeamDsDoc,
  deleteTeamDsDoc,
  listTeamDsDocShare,
  getTeamDsDocShare,
  addTeamDsDocShare,
  updateTeamDsDocShare,
  deleteTeamDsDocShare,
  AddRtfImages,
  getTeamImgrptTemplates,
  updateTeamImgrptTemplate,
  deleteOssObjects,
  deleteOssObject,
  getAliOssStsAssumerole,
};
