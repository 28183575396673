/**
 *
 * @param {*} pageNum 页码，从1开始；
 * @param {*} pageSize
 * @returns
 */
function computePagingOffset(pageNum, pageSize = 10) {
  return pageSize * (pageNum - 1);
}

/**
 *
 * @param {*} pageNum 页码，从1开始；
 * @param {*} pageSize
 * @returns
 */
function computePagingPassedCount(pageNum, pageSize = 10) {
  return pageSize * (pageNum - 1);
}

/**
 *
 * @param {*} pageNum 页码，从1开始；
 * @param {*} pageSize
 * @returns
 */
function computePagingFullCount(pageNum, pageSize = 10) {
  return pageSize * pageNum;
}

/**
 *
 */
function computeViewColumnNum(width, columnWidth = 100) {
  let columnNum = width / columnWidth;
  if (columnNum > 1) {
    columnNum = Math.trunc(columnNum);
  } else {
    columnNum = 1;
  }
  return columnNum;
}

/**
 *
 * @param time 睡眠时间（毫秒）
 */
function sleep(time) {
  var start = new Date().getTime();
  while (new Date().getTime() - start < time) {
    continue;
  }
}

module.exports = {
  computePagingOffset,
  computePagingPassedCount,
  computePagingFullCount,
  computeViewColumnNum,
  sleep,
};
