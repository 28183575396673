<template>
  <div>
    <div class="header">
      <div class="hd-left">
        <div class="hd-pro" @click="onLogoClick">
          <svg-icon svg-name="logo" svg-class="hd-logo" />
          <span class="hd-proname">惟若顾问版</span>
        </div>
        <el-dropdown
          placement="bottom-start"
          :show-timeout="0"
          @command="onManualCommand"
          class="hd-manual"
        >
          <div class="hd-manual-lbl">
            <i class="el-icon-help hd-icon" style="padding-right: 3px" />
            帮助中心
          </div>
          <el-dropdown-menu slot="dropdown">
            <el-dropdown-item command="intro">惟若顾问版介绍</el-dropdown-item>
          </el-dropdown-menu>
        </el-dropdown>
      </div>
      <div style="flex: 1" />
      <div class="hd-right">
        <el-popover
          placement="bottom-end"
          trigger="hover"
          :visible-arrow="false"
          :width="180"
        >
          <div v-if="user" class="hd-upopup-container">
            <div class="hd-upopup-uname">
              {{ user && user.nickname ? user.nickname : "" }}
            </div>
            <div class="hd-upopup-ucode">
              惟若号: {{ user.ucode ? user.ucode : "" }}
            </div>
            <div class="hd-upopup-ops">
              <el-button
                @click="onLogoutClick"
                type="danger"
                class="hd-upopup-logout"
                >退出登录</el-button
              >
            </div>
          </div>
          <span slot="reference" class="hd-user">
            <el-image
              class="hd-user-avatar"
              fit="cover"
              :src="user ? user.avatar : ''"
            ></el-image>
            <span v-if="user" class="hd-user-name">{{ user.nickname }}</span>
          </span>
        </el-popover>
      </div>
    </div>
    <template v-if="team">
      <div class="left-nav">
        <div class="lnav-main">
          <div class="lnav-team">
            <el-popover
              placement="right-start"
              trigger="click"
              v-model="isMembTeamsPopShowing"
              @show="onShowMembTeamsPop"
              @hide="onHideMembTeamsPop"
            >
              <div class="wrs-flexrow-end-center">
                <el-button
                  icon="el-icon-close"
                  class="mteams-close"
                  @click="onCloseMembTeamsPop"
                ></el-button>
              </div>
              <div
                v-if="isShowMembTeams || membTeamLoading"
                class="mteams-container"
                v-loading="membTeamLoading"
                element-loading-text="加载中"
              >
                <el-table
                  :data="membTeams"
                  style="width: 100%"
                  max-height="320px"
                  :show-header="true"
                >
                  <el-table-column
                    label="顾问号"
                    width="300"
                    class-name="mteamtb-name"
                  >
                    <template slot-scope="scope">
                      <div class="mteamtb-name-cell">
                        <el-image
                          class="mteamtb-headimg"
                          :src="scope.row.headimgThumb"
                          fit="cover"
                        ></el-image>
                        <span style="margin-left: 8px" class="wrs-ellipsis">{{
                          scope.row.name
                        }}</span>
                      </div>
                    </template>
                  </el-table-column>
                  <el-table-column
                    label="操作"
                    width="100"
                    class-name="mteamtb-op"
                  >
                    <template
                      slot-scope="scope"
                      v-if="!team || scope.row.id != team.id"
                    >
                      <el-button
                        @click="onChangeTeamClick(scope.$index, scope.row)"
                        type="text"
                        size="small"
                        style="padding: 0"
                        >选择</el-button
                      >
                    </template>
                  </el-table-column>
                </el-table>
                <el-pagination
                  @current-change="onMembTeamPageChange"
                  :current-page="membTeamPageNum"
                  :page-size="10"
                  :page-sizes="[10]"
                  layout="sizes, prev, next, jumper"
                  class="mteamtb-pagination"
                >
                </el-pagination>
              </div>
              <el-empty
                v-else
                class="mteams-no"
                :image-size="60"
                description="暂无顾问号"
              ></el-empty>
              <div slot="reference" class="lnav-team-cell">
                <div class="lnav-team-name">
                  {{ team && team.name ? team.name : "请选择顾问号" }}
                  <i class="el-icon-arrow-right"></i>
                </div>
                <div v-if="team && team.name" class="lnav-team-switch">
                  点击切换顾问号
                </div>
              </div>
            </el-popover>
          </div>
          <el-divider class="lnav-divider" />
          <el-menu
            class="menu"
            background-color="#f5f5f5"
            :default-active="activeMenuIndex"
            @select="onSelectMenu"
          >
            <el-menu-item index="/coa/intro">
              <svg-icon svg-name="guwenhao-o" svg-class="lnav-menu-svgicon" />
              <span slot="title">简介</span>
            </el-menu-item>
            <el-menu-item index="/coa/bsorders">
              <svg-icon svg-name="order-o" svg-class="lnav-menu-svgicon" />
              <span slot="title">服务产品订单</span>
            </el-menu-item>
            <el-menu-item index="/coa/docspace">
              <i class="el-icon-files" />
              <span slot="title">文档空间</span>
            </el-menu-item>
            <el-submenu v-show="isAdmin" index="sub1">
              <template slot="title">
                <svg-icon svg-name="console-o" svg-class="lnav-menu-svgicon" />
                <span>顾问号管理</span>
              </template>
              <el-menu-item index="/coa/console/bservicemgr">
                <svg-icon svg-name="bservice-o" svg-class="lnav-menu-svgicon" />
                <span slot="title">服务产品管理</span>
              </el-menu-item>
              <!--
              <el-menu-item index="/coa/console/irtemplates">
                <svg-icon svg-name="moban-o" svg-class="lnav-menu-svgicon" />
                <span slot="title">形象报告模板</span>
              </el-menu-item>
              -->
            </el-submenu>
          </el-menu>
        </div>
      </div>
      <div class="coa-content">
        <router-view />
      </div>
    </template>
    <el-empty
      v-else-if="noTeam"
      class="coa-none"
      description="请先在微信小程序“惟若顾问版”上添加顾问号。"
    ></el-empty>
  </div>
</template>

<script>
import Icoffice from "../icoffice";
import AliOSS from "../api/alioss";
import Ocean from "../api/ocean";
import ViewContext from "../viewContext";
import UI from "../util/ui";

export default {
  data() {
    return {
      // 用户信息
      user: null,

      team: null,
      noTeam: false,

      /** 是否管理员 */
      isAdmin: false,
      /** 是否超级管理员 */
      isSuperAdmin: false,

      activeMenuIndex: this.$route.path,

      /**
       * 参与的团队列表
       *   [
       *     id:1, //团队id
       *     member_id: 2, //成员id
       *     name:'', //团队名称
       *     headimg:'', //头像
       *   ]
       */
      membTeams: [],
      membTeamPageNum: 1,
      membTeamLoading: false,
      isShowMembTeams: false,
      isMembTeamsPopShowing: false,
    };
  },
  async mounted() {
    this.user = Icoffice.getUser();
    if (this.user) {
      await this.getWorkTeam();
      await this.checkAdmin();
    }

    window.addEventListener("popstate", this.onPopState);
  },
  beforeDestroy() {
    window.removeEventListener("popstate", this.onPopState);
  },
  methods: {
    resetTeam() {
      this.team = null;
      this.noTeam = false;
      this.isAdmin = false;
      this.isSuperAdmin = false;
    },

    onLogoClick() {
      if (this.team) {
        this.navigateTo("/coa/intro");
      } else {
        this.navigateTo("/");
      }
    },

    onManualCommand(command) {
      switch (command) {
        case "intro":
          window.open("/manual/ce/introduction", "_blank");
          break;
        default:
          break;
      }
    },

    onLogoutClick() {
      Icoffice.removeUser();
      Icoffice.removeCookieUid();
      this.$router.push({
        path: "/login",
      });
    },

    /**
     * 获取工作团队
     */
    getWorkTeam: async function () {
      await Ocean.getMemberLastTeam()
        .then((res) => {
          if (res.data.team) {
            let rteam = res.data.team;
            ViewContext.setTeam(rteam);
            ViewContext.setMemberId(rteam.member_id);
            this.team = rteam;
            this.noTeam = false;
          } else {
            ViewContext.setTeam(null);
            ViewContext.setMemberId(0);
            this.noTeam = true;
          }
        })
        .catch((err) => {
          this.noTeam = true;
          this.$message.error(err.data.msg);
        });
    },

    /**
     *
     */
    checkAdmin: async function () {
      const team = this.team;
      const memberId = ViewContext.getMemberId();
      if (!team || !memberId) return;

      await Ocean.checkTeamAdmin({
        team_id: team.id,
        member_id: memberId,
      })
        .then((res) => {
          let rdata = res.data;
          this.isAdmin = rdata.is_admin;
          this.isSuperAdmin = rdata.is_super_admin;
        })
        .catch((err) => {
          this.$message.error(err.data.msg);
        });
    },

    /**
     * 加载参与的团队列表
     */
    getMemberTeams: function () {
      let params = {
        offset: UI.computePagingOffset(this.membTeamPageNum, 10),
        num: 10,
      };

      Ocean.getMemberTeams(params)
        .then((res) => {
          this.membTeams = [];
          if (res.data.teams) {
            for (let t of res.data.teams) {
              if (t.headimg) {
                let oss_object_key = AliOSS.getImageKey(t.headimg, t.id);
                t.headimgUrl = AliOSS.getCdnUrl(oss_object_key);
                t.headimgThumb = AliOSS.getImageThumbnailCdnUrl(oss_object_key);
              }

              this.membTeams.push(t);
            }
          }

          this.isShowMembTeams =
            this.membTeams.length > 0 || this.membTeamPageNum > 1;
          this.membTeamLoading = false;
        })
        .catch((err) => {
          this.membTeamLoading = false;
          this.$message.error(err.data.msg);
        });
    },

    /**
     * 更新最后工作顾问号
     * @param {*} memberId 成员id
     */
    updateWorkTeam: function (memberId) {
      Ocean.updateMemberLastTeam(memberId)
        .then((/* res */) => {})
        .catch((/* err */) => {});
    },

    onShowMembTeamsPop() {
      this.membTeams = [];
      this.membTeamLoading = true;
      this.isShowMembTeams = true;

      this.membTeamPageNum = 1;
      this.getMemberTeams();
    },
    onHideMembTeamsPop() {
      this.membTeamLoading = false;
    },
    onCloseMembTeamsPop() {
      this.isMembTeamsPopShowing = false;
    },

    onSelectMenu(index, path, item) {
      if (item.$attrs && item.$attrs.newwindow) {
        window.open(index, "_blank");
      } else {
        this.navigateTo(index);
      }
    },

    setActiveMenuPath(path) {
      if (path !== this.activeMenuIndex) {
        this.activeMenuIndex = path;
      }
    },

    navigateTo(path, params = {}) {
      this.setActiveMenuPath(path);
      this.$router.push({
        path,
        ...params,
      });
    },

    async onChangeTeamClick(index, row) {
      // 切换顾问号
      ViewContext.setTeam(row);
      ViewContext.setMemberId(row.member_id);

      this.resetTeam();
      this.team = row;
      await this.checkAdmin();

      // 更新最后工作顾问号
      this.updateWorkTeam(row.member_id);

      // 跳转到简介页面并刷新信息
      this.navigateTo("/coa/intro", {
        query: {
          tab: "0",
          id: row.id,
        },
      });
    },
    onMembTeamPageChange(pageNum) {
      this.membTeamPageNum = pageNum;
      this.getMemberTeams();
    },

    onPopState() {
      this.setActiveMenuPath(this.$route.path);
    },
  },
  //watch: {
  //  "$route.query": {
  //    immediate: true,
  //    handler(to, from) {
  //    },
  //  },
  //  $route: {
  //    immediate: true,
  //    handler(to, from) {
  //    },
  //  },
  //},
};
</script>

<style scoped lang="scss">
.header {
  position: fixed;
  top: 0;
  right: 0;
  left: 0;
  height: 50px;
  display: flex;
  align-items: center;
  font-size: 15px;
  background-color: #ffffff;
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.16);
  z-index: 999;
}

.hd-icon {
  font-size: 18px;
}

.hd-left {
  margin-left: 20px;
  display: flex;
  align-items: center;
}

.hd-pro {
  display: flex;
  align-items: center;
  cursor: pointer;
}

.hd-logo {
  width: 22px;
  height: 22px;
}

.hd-proname {
  padding: 0 0 0 6px;
  font-size: 16px;
  font-weight: 500;
}

.hd-manual {
  margin-left: 40px;
}
.hd-manual-lbl {
  display: flex;
  align-items: center;
  cursor: pointer;
}

.hd-right {
  margin-right: 20px;
  display: flex;
  align-items: center;
}

.hd-user {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  cursor: pointer;
}
.hd-user-avatar {
  width: 28px;
  height: 28px;
  border-radius: 50%;
  // border: 1px solid #e5e5e5;
  background-color: #f5f5f5;
}
.hd-user-name {
  margin-left: 8px;
}

.hd-upopup-container {
  color: #353535;
  font-size: 12px;
}

.hd-upopup-uname {
  font-weight: 600;
}

.hd-upopup-ucode {
  padding-top: 4px;
  color: #999999;
}

.hd-upopup-ops {
  padding: 16px 0 0 0;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
}

.hd-upopup-logout {
  width: 100%;
  padding: 8px 10px;
  box-sizing: border-box;
}

.left-nav {
  width: 220px;
  position: fixed;
  top: 0;
  bottom: 0;
  margin-top: 50px;
  padding-bottom: 60px;
  box-sizing: border-box;
  overflow: auto;
  transition: padding-top 0.3s;
  // transition: opacity .3s;
  background-color: #f5f5f5;
  z-index: 900;
  display: flex;
  flex-direction: column;
}

.lnav-team {
  margin-left: 20px;
  padding: 16px 0 12px 0;
}
.lnav-team-cell {
  cursor: pointer;
}
.lnav-team-name {
  font-size: 16px;
  font-weight: 500;
}
.lnav-team-switch {
  font-size: 11px;
  color: #c8c9cc;
}

.lnav-divider {
  width: auto;
  margin: 0 2px;
  flex-shrink: 0;
}

.menu {
  width: 100%;
  box-sizing: border-box;

  ::v-deep .el-menu-item:hover {
    background: #eaeaea !important;
    // color: #fff !important;
  }

  ::v-deep .el-submenu__title:hover {
    background: #eaeaea !important;
    // color: #fff !important;
  }

  ::v-deep .el-menu-item.is-active {
    background: #eaeaea !important;
    // color: #fff !important;
  }

  ::v-deep .el-submenu__title.is-active {
    background: #eaeaea !important;
    // color: #fff !important;
  }
}

.menu:not(.el-menu--collapse) {
  border: none;
}

.lnav-menu-svgicon {
  margin: 0 8px 0 3px;
  width: 16px;
  height: 16px;
  flex-shrink: 0;
  text-align: center;
  vertical-align: middle;
  color: #909399;
}
.el-menu-item.is-active .lnav-menu-svgicon {
  color: inherit;
}

.coa-none {
  position: absolute;
  top: 0;
  right: 0;
  left: 0;
  bottom: 0;
}

.mteams-close {
  padding: 4px;
  font-size: 18px;
  border: none;
  border-radius: 50%;
  background-color: #f5f5f5;
}

.mteams-container {
  min-height: 200px;
  padding: 0 8px 10px 8px;
  box-sizing: border-box;
}

.mteams-no {
  width: 480px;
  height: 200px;
}

.mteamtb-name-cell {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.mteamtb-headimg {
  width: 32px;
  height: 32px;
  border-radius: 6px;
  flex-shrink: 0;
}

::v-deep .mteamtb-op.el-table__cell {
  padding: 4px 0;
}

.mteamtb-pagination {
  padding-top: 8px;
}
</style>
