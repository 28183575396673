<template>
  <div class="content">
    <div class="header">
      <router-link to="/" class="logo-link">
        <svg-icon svg-name="logo" svg-class="hd-logo" />
        <div class="logo-text">惟若顾问版</div>
      </router-link>
      <el-dropdown
        placement="bottom-start"
        :show-timeout="0"
        @command="onManualCommand"
        class="hd-manual"
      >
        <div class="hd-manual-lbl">
          <i class="el-icon-help hd-icon" style="padding-right: 3px" />
          帮助中心
        </div>
        <el-dropdown-menu slot="dropdown">
          <el-dropdown-item command="intro">惟若顾问版介绍</el-dropdown-item>
        </el-dropdown-menu>
      </el-dropdown>
      <div style="flex: 1" />
      <div class="edition">登录</div>
    </div>

    <div class="login-content">
      <div style="font-size: 18px">账号登录</div>
      <el-input
        placeholder="惟若号"
        v-model="ucode"
        class="ucode"
        size="medium"
        clearable
        autocomplete="off"
      ></el-input>
      <el-input
        type="password"
        placeholder="密码"
        v-model="upassword"
        class="upassword"
        size="medium"
        clearable
        autocomplete="off"
      ></el-input>
      <div v-if="loginErrorMsg" class="login-error-msg">
        {{ loginErrorMsg }}
      </div>
      <el-button type="primary" class="loginbtn" @click="onLoginClicked"
        >登录</el-button
      >
    </div>
  </div>
</template>

<script>
import Ocean from "../api/ocean";
import Icoffice from "../icoffice";
import UserUtil from "../util/user";

export default {
  data() {
    return {
      ucode: "",
      upassword: "",
      loginErrorMsg: "",
    };
  },
  methods: {
    onManualCommand(command) {
      switch (command) {
        case "intro":
          window.open("/manual/ce/introduction", "_blank");
          break;
        default:
          break;
      }
    },

    onLoginClicked() {
      if (this.ucode && this.upassword) {
        this.loginErrorMsg = "";
        let ucode = this.ucode.trim();
        let upassword = this.upassword.trim();
        Ocean.login(ucode, upassword)
          .then((res) => {
            let user = res.data.user;
            user.ucode = ucode;
            user.avatar = UserUtil.makeAvatarThumb(user, {
              useDefault: true,
            });
            Icoffice.setUser(user);

            this.$router.replace({
              path: "/coa/intro",
            });
          })
          .catch(() => {
            this.loginErrorMsg = "账号名或密码不正确";
          });
      } else {
        this.loginErrorMsg = "请输入账号名和密码";
      }
    },
  },
};
</script>

<style scoped lang="scss">
.content {
  width: 100%;
  min-height: 100vh;
  background-image: url("../assets/img/login-bg.jpg");
  background-repeat: repeat;
  background-size: cover;
  -moz-background-size: cover;
  // background-size: 100% 100%;
  // -moz-background-size: 100% 100%;
  box-sizing: border-box;
}

.header {
  width: 100%;
  height: 50px;
  display: flex;
  align-items: center;
  font-size: 14px;
  background-color: #ffffff;
}

.hd-icon {
  font-size: 18px;
}

.logo-link {
  margin-left: 20px;
  display: flex;
  align-items: center;
  text-decoration: none;
  color: #2c3e50;
}

.hd-logo {
  width: 22px;
  height: 22px;
}

.logo-text {
  padding-left: 6px;
  font-size: 16px;
}

.hd-manual {
  margin-left: 40px;
}
.hd-manual-lbl {
  display: flex;
  align-items: center;
  cursor: pointer;
}

.edition {
  padding-right: 40px;
}

.login-content {
  width: 360px;
  margin: 60px 100px 0 auto;
  background-color: #ffffff;
  padding: 44px 50px;
  box-sizing: border-box;
  font-size: 14px;
}

.ucode {
  margin-top: 30px;

  ::v-deep .el-input__inner {
    border: none;
    border-bottom: 1px solid #d7d8d9;
    border-radius: 0;
    padding-left: 0;
    height: 34px;
    line-height: 34px;
  }
}

.upassword {
  margin-top: 20px;

  ::v-deep .el-input__inner {
    border: none;
    border-bottom: 1px solid #d7d8d9;
    border-radius: 0;
    padding-left: 0;
    height: 34px;
    line-height: 34px;
  }
}

.loginbtn {
  margin-top: 50px;
  margin-bottom: 20px;
  width: 100%;
  background-color: #ff6a00;
  border-color: #ff6a00;
}

.login-error-msg {
  display: inline-block;
  height: 20px;
  line-height: 20px;
  font-family: PingFangSC-Regular;
  font-size: 12px;
  color: #f15533;
  letter-spacing: 0.37px;
}
</style>
