import AliOSS from "../api/alioss";

const defaultAvatar = "/img/avatar-noset1.png";

/**
 * 获取用户头像
 * @param {Object} userInfo
 * {
 *   id
 *   avatar
 *   is_fullavatar
 * }
 * @param {Object} options
 * {
 *   useDefault
 * }
 */
function makeAvatar(userInfo = {}, options = {}) {
  let avatar = "";

  if (userInfo.avatar) {
    if (userInfo.is_fullavatar) {
      avatar = userInfo.avatar;
    } else {
      let avatarOssKey = AliOSS.getImageKey(userInfo.avatar, userInfo.id);
      avatar = AliOSS.getCdnUrl(avatarOssKey);
    }
  }

  if (!avatar && options.useDefault) {
    avatar = defaultAvatar;
  }

  return avatar;
}

/**
 * 获取用户头像缩略图
 * @param {Object} userInfo
 * {
 *   id
 *   avatar
 *   is_fullavatar
 * }
 * @param {Object} options
 * {
 *   useDefault
 * }
 */
function makeAvatarThumb(userInfo = {}, options = {}) {
  let thumb = "";

  if (userInfo.avatar) {
    if (userInfo.is_fullavatar) {
      thumb = userInfo.avatar;
    } else {
      let avatarOssKey = AliOSS.getImageKey(userInfo.avatar, userInfo.id);
      thumb = AliOSS.getImageThumbnailCdnUrl(avatarOssKey);
    }
  }

  if (!thumb && options.useDefault) {
    thumb = defaultAvatar;
  }

  return thumb;
}

export default {
  defaultAvatar,
  makeAvatar,
  makeAvatarThumb,
};
