import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import VueCookies from "vue-cookies";
//import Moment from 'moment';
import "moment/locale/zh-cn";

Vue.config.productionTip = false;

Vue.use(VueCookies);

import {
  Pagination,
  Dialog,
  Autocomplete,
  DatePicker,
  TimePicker,
  Dropdown,
  DropdownMenu,
  DropdownItem,
  Menu,
  Submenu,
  MenuItem,
  MenuItemGroup,
  Input,
  Checkbox,
  Button,
  Table,
  TableColumn,
  Popover,
  Tooltip,
  Form,
  FormItem,
  Tabs,
  TabPane,
  Tag,
  Icon,
  Upload,
  Container,
  Header,
  Aside,
  Main,
  Footer,
  Divider,
  Image,
  Calendar,
  Loading,
  MessageBox,
  Message,
  // Notification,
  Empty,
  Avatar,
  Scrollbar,
  Descriptions,
  DescriptionsItem,
  Drawer,
} from "element-ui";

Vue.use(Pagination);
Vue.use(Dialog);
Vue.use(Autocomplete);
Vue.use(DatePicker);
Vue.use(TimePicker);
Vue.use(Dropdown);
Vue.use(DropdownMenu);
Vue.use(DropdownItem);
Vue.use(Menu);
Vue.use(Submenu);
Vue.use(MenuItem);
Vue.use(MenuItemGroup);
Vue.use(Input);
Vue.use(Checkbox);
Vue.use(Button);
Vue.use(Table);
Vue.use(TableColumn);
Vue.use(Popover);
Vue.use(Tooltip);
Vue.use(Form);
Vue.use(FormItem);
Vue.use(Tabs);
Vue.use(TabPane);
Vue.use(Tag);
Vue.use(Icon);
Vue.use(Upload);
Vue.use(Container);
Vue.use(Header);
Vue.use(Aside);
Vue.use(Main);
Vue.use(Footer);
Vue.use(Divider);
Vue.use(Image);
Vue.use(Calendar);
Vue.use(Empty);
Vue.use(Avatar);
Vue.use(Scrollbar);
Vue.use(Descriptions);
Vue.use(DescriptionsItem);
Vue.use(Drawer);

Vue.use(Loading.directive);

Vue.prototype.$loading = Loading.service;
Vue.prototype.$msgbox = MessageBox;
Vue.prototype.$alert = MessageBox.alert;
Vue.prototype.$confirm = MessageBox.confirm;
Vue.prototype.$prompt = MessageBox.prompt;
Vue.prototype.$message = Message;
//Vue.prototype.$notify = Notification;

import SvgIcon from "./components/SvgIcon";
Vue.component(SvgIcon.name, SvgIcon);
// 自动导入svg文件
const requireAll = (requireContext) =>
  requireContext.keys().map(requireContext);
const req = require.context("./assets/icons/svg", true, /\.svg$/);
requireAll(req);

import "./assets/css/theme.css";
import "./assets/css/ellipsis.css";
import "./assets/css/utility.css";
import "./assets/css/element-ui.css";

// 全局注册组件
import DocSpaceSelector from "./views/components/DocSpaceSelector.vue";
Vue.component("doc-space-selector", DocSpaceSelector);

new Vue({
  router,
  store,
  render: (h) => h(App),
}).$mount("#app");
