<!--
 * URL查询参数:
 *   ri: id
 *   rn: name
-->

<template>
  <div class="container">
    <div class="head-bar">
      <template
        v-if="
          !routes ||
          routes.length === 0 ||
          (routes.length === 1 && routes[0].id === 0)
        "
      >
        <span class="page-title" style="padding: 10px 0">文档空间</span>
        <div style="flex: 1" />
      </template>
      <template v-else>
        <template v-if="routes[0].id !== 0">
          <button
            class="route-parent"
            style="margin-left: -10px"
            @click="onDocSpaceClick()"
          >
            <i class="el-icon-arrow-left" />文档空间
          </button>
          <el-divider direction="vertical" class="route-bar-vdivider" />
        </template>
        <div class="route-bar">
          <span
            v-for="(item, index) in routes"
            :key="item.id"
            class="wrs-flexrow--center"
          >
            <template v-if="index < routes.length - 1">
              <button
                class="route-parent"
                @click="onParentRouteClick(item, index)"
              >
                <span class="wrs-ellipsis">{{ item.name }}</span>
              </button>
              <span class="route-sep">/</span>
            </template>
            <template v-else>
              <el-dropdown trigger="click" @command="onCurrentRouteCommand">
                <button class="route-current">
                  <span class="wrs-ellipsis">{{ item.name }}</span>
                  <i
                    class="el-icon-arrow-down"
                    style="padding-left: 3px; font-size: 11px; line-height: 1"
                  ></i>
                </button>
                <el-dropdown-menu slot="dropdown">
                  <el-dropdown-item :command="2">
                    <span>新标签页打开</span>
                  </el-dropdown-item>
                  <el-dropdown-item :divided="true" :command="6">
                    <span>重命名</span>
                  </el-dropdown-item>
                  <el-dropdown-item :command="7">
                    <span>移 动</span>
                  </el-dropdown-item>
                  <el-dropdown-item :divided="true" :command="9">
                    <span style="color: #ff0000">删 除</span>
                  </el-dropdown-item>
                </el-dropdown-menu>
              </el-dropdown>
            </template>
          </span>
        </div>
      </template>

      <div class="toolbar">
        <el-popover
          trigger="click"
          placement="bottom"
          v-model="addPopover.show"
          popper-class="add-popover"
        >
          <div>
            <div class="dropdown-item" @click="onAddDirClick()">
              <svg-icon svg-name="folder" svg-class="dropdown-icon-dir" />
              文件夹
            </div>
            <div class="dropdown-item" @click="onAddDocClick()">
              <svg-icon svg-name="doc" svg-class="dropdown-icon-doc" />
              文档
            </div>
            <el-upload
              action="https://jsonplaceholder.typicode.com/posts/"
              :multiple="false"
              :show-file-list="false"
              accept=".pdf, .doc, .docx"
              :auto-upload="true"
              :before-upload="onBeforeUploadDoc"
              :http-request="onUploadDoc"
              :on-success="onUploadDocSuccess"
              class="doc-upload"
            >
              <div class="dropdown-item">
                <i class="dropdown-icon el-icon-upload2"></i>
                上传文件
              </div>
            </el-upload>
          </div>
          <template slot="reference">
            <span class="toolbar-item wrs-flexrow--center">
              <i class="icon el-icon-circle-plus-outline"></i>
              <span class="txt" style="padding-left: 3px">新建</span>
            </span>
          </template>
        </el-popover>
        <el-dropdown trigger="click" @command="onSortModeSelect">
          <el-tooltip content="排序" placement="top">
            <i class="toolbar-item icon el-icon-sort"></i>
          </el-tooltip>
          <el-dropdown-menu slot="dropdown">
            <el-dropdown-item
              v-for="item in sortModeList"
              :key="item.mode"
              :command="{ cmd: 0, value: item }"
            >
              <span
                :class="{ 'el-icon-check': currSortMode.mode === item.mode }"
                style="width: 20px; display: inline-block"
              ></span>
              {{ item.name }}
            </el-dropdown-item>
            <el-dropdown-item divided :command="{ cmd: 1 }">
              <span
                :class="{ 'el-icon-check': isDirTop }"
                style="width: 20px; display: inline-block"
              ></span>
              文件夹置顶
            </el-dropdown-item>
          </el-dropdown-menu>
        </el-dropdown>
        <el-divider direction="vertical" class="toolbar-vdivider" />
        <el-popover
          placement="left"
          trigger="manual"
          :visible-arrow="false"
          v-model="searchPopover.show"
        >
          <div>
            <div class="wrs-flexrow-end-center">
              <el-button
                icon="el-icon-close"
                class="search-popover-close"
                @click="onCloseSearchPopoverClick()"
              ></el-button>
            </div>
            <div class="wrs-flexrow--center" style="margin-top: 18px">
              <el-input
                ref="searchInput"
                v-model="searchPopover.searchName"
                placeholder="搜索名称"
                :maxlength="40"
                size="medium"
              />
              <el-button size="medium" @click="onDoSearchClick">
                搜索
              </el-button>
            </div>
            <el-table
              :data="searchPopover.docs"
              style="
                margin-top: 12px;
                max-width: calc(100vw - 500px);
                min-width: 800px;
              "
              :max-height="searchPopover.tableMaxHeight"
              :header-cell-style="{ 'background-color': '#eff3f8' }"
              @row-dblclick="onSearchDocRowDblClick"
            >
              <el-table-column label="名称">
                <template slot-scope="scope">
                  <span class="wrs-flexrow--center">
                    <svg-icon
                      :svg-name="scope.row.icon"
                      :svg-class="
                        scope.row.dtype === 0 ? 'dir-icon' : 'doc-icon'
                      "
                    />
                    <span
                      class="doc-name wrs-ellipsis"
                      @click="onSearchDocNameClick(scope.row, scope.$index)"
                    >
                      {{ scope.row.name }}
                    </span>
                  </span>
                </template>
              </el-table-column>
              <el-table-column
                prop="update_time"
                label="最后更新时间"
                width="170"
              ></el-table-column>
              <el-table-column
                prop="create_time"
                label="创建时间"
                width="170"
              ></el-table-column>
            </el-table>
            <el-pagination
              @current-change="onSearchDocPageNumChange"
              :current-page="searchPopover.pageNum"
              :page-size="docPageSize"
              :page-sizes="[10]"
              layout="sizes, prev, next, jumper"
              class="pagination-bar"
            >
            </el-pagination>
          </div>
          <template slot="reference">
            <el-tooltip content="搜索" placement="top">
              <i
                class="toolbar-item icon el-icon-search"
                @click="onSearchClick()"
              ></i>
            </el-tooltip>
          </template>
        </el-popover>
      </div>
    </div>
    <el-table
      :data="docs"
      style="width: 100%; min-width: 800px"
      :header-cell-style="{ 'background-color': '#eff3f8' }"
      @row-dblclick="onDocRowDblClick"
    >
      <el-table-column label="名称">
        <template slot-scope="scope">
          <span class="wrs-flexrow--center">
            <svg-icon
              :svg-name="scope.row.icon"
              :svg-class="scope.row.dtype === 0 ? 'dir-icon' : 'doc-icon'"
            />
            <span
              class="doc-name wrs-ellipsis"
              @click="onDocNameClick(scope.row, scope.$index)"
            >
              {{ scope.row.name }}
            </span>
          </span>
        </template>
      </el-table-column>
      <el-table-column
        prop="update_time"
        label="最后更新时间"
        width="170"
      ></el-table-column>
      <el-table-column
        prop="create_time"
        label="创建时间"
        width="170"
      ></el-table-column>
      <el-table-column width="80" align="center">
        <template slot-scope="scope">
          <i v-if="scope.row.loading" class="dropdown-icon el-icon-loading"></i>
          <el-dropdown
            v-else-if="scope.row.dtype === 0"
            trigger="click"
            @command="onDirMoreItemClick"
          >
            <el-button class="doc-more" type="text" size="medium">
              <i class="el-icon-more"></i>
            </el-button>
            <el-dropdown-menu slot="dropdown">
              <el-dropdown-item
                :command="{ cmd: 1, row: scope.row, index: scope.$index }"
              >
                <span>当前标签页打开</span>
              </el-dropdown-item>
              <el-dropdown-item
                :command="{ cmd: 2, row: scope.row, index: scope.$index }"
              >
                <span>新标签页打开</span>
              </el-dropdown-item>
              <el-dropdown-item
                :divided="true"
                :command="{ cmd: 6, row: scope.row, index: scope.$index }"
              >
                <span>重命名</span>
              </el-dropdown-item>
              <el-dropdown-item
                :command="{ cmd: 7, row: scope.row, index: scope.$index }"
              >
                <span>移 动</span>
              </el-dropdown-item>
              <el-dropdown-item
                :divided="true"
                :command="{ cmd: 9, row: scope.row, index: scope.$index }"
              >
                <span style="color: #ff0000">删 除</span>
              </el-dropdown-item>
            </el-dropdown-menu>
          </el-dropdown>
          <el-dropdown v-else trigger="click" @command="onDocMoreItemClick">
            <el-button class="doc-more" type="text" size="medium">
              <i class="el-icon-more"></i>
            </el-button>
            <el-dropdown-menu slot="dropdown">
              <el-dropdown-item
                :disabled="!scope.row.allowOpen"
                :command="{ cmd: 1, row: scope.row, index: scope.$index }"
              >
                <span>新标签页打开</span>
              </el-dropdown-item>
              <el-dropdown-item
                :disabled="!scope.row.allowDownload"
                :command="{ cmd: 3, row: scope.row, index: scope.$index }"
              >
                <span>下 载</span>
              </el-dropdown-item>
              <!--
              <el-dropdown-item
                :divided="true"
                :command="{ cmd: 4, row: scope.row, index: scope.$index }"
              >
                <span>分 享</span>
              </el-dropdown-item>
              <el-dropdown-item
                :command="{ cmd: 5, row: scope.row, index: scope.$index }"
              >
                <span>查看当前分享</span>
              </el-dropdown-item>
              -->
              <el-dropdown-item
                :divided="true"
                :command="{ cmd: 6, row: scope.row, index: scope.$index }"
              >
                <span>重命名</span>
              </el-dropdown-item>
              <el-dropdown-item
                :command="{ cmd: 7, row: scope.row, index: scope.$index }"
              >
                <span>移 动</span>
              </el-dropdown-item>
              <el-dropdown-item
                :command="{ cmd: 8, row: scope.row, index: scope.$index }"
              >
                <span>创建副本</span>
              </el-dropdown-item>
              <el-dropdown-item
                :divided="true"
                :command="{ cmd: 9, row: scope.row, index: scope.$index }"
              >
                <span style="color: #ff0000">删 除</span>
              </el-dropdown-item>
            </el-dropdown-menu>
          </el-dropdown>
        </template>
      </el-table-column>
    </el-table>
    <el-pagination
      @current-change="onDocPageNumChange"
      :current-page="docPageNum"
      :page-size="docPageSize"
      :page-sizes="[10]"
      :layout="!isDirTop ? 'sizes, prev, next, jumper' : 'sizes, prev, next'"
      class="pagination-bar"
    >
    </el-pagination>

    <doc-space-selector
      ref="docSelector"
      :visible="docSelector.show"
      :title="docSelector.title"
      selector-mode="0"
      :selected-ids="docSelector.selectedIds"
      :disable-ids="docSelector.disableIds"
      @close="closeDocSelector"
      @select="onDsSelect"
    ></doc-space-selector>

    <el-dialog
      :visible="createDirDialog.show"
      :close-on-click-modal="false"
      :show-close="false"
      append-to-body
      title="新建文件夹"
      class="create-dir-dialog"
    >
      <div>
        <el-input
          v-model="createDirDialog.name"
          placeholder="请输入文件夹名称"
          :maxlength="60"
          size="medium"
        />
      </div>
      <div class="wrs-flexrow-end-center" style="padding: 20px 0 10px 0">
        <el-button
          class="create-dir-ok"
          :disabled="!createDirDialog.name"
          type="primary"
          size="medium"
          @click="onCreateDirOkClick()"
        >
          确 定
        </el-button>
        <el-button
          size="medium"
          class="create-dir-cancel"
          @click="closeCreateDirDialog()"
        >
          取 消
        </el-button>
      </div>
    </el-dialog>

    <el-dialog
      :visible="createDocDialog.show"
      :close-on-click-modal="false"
      :show-close="false"
      append-to-body
      title="新建文档"
      class="create-doc-dialog"
    >
      <div>
        <el-input
          v-model="createDocDialog.name"
          placeholder="请输入文档名称"
          :maxlength="60"
          size="medium"
        />
      </div>
      <div class="wrs-flexrow-end-center" style="padding: 20px 0 10px 0">
        <el-button
          class="create-doc-ok"
          :disabled="!createDocDialog.name"
          type="primary"
          size="medium"
          @click="onCreateDocOkClick()"
        >
          确 定
        </el-button>
        <el-button
          size="medium"
          class="create-doc-cancel"
          @click="closeCreateDocDialog()"
        >
          取 消
        </el-button>
      </div>
    </el-dialog>

    <el-dialog
      :visible="renameDirDialog.show"
      :close-on-click-modal="false"
      :show-close="false"
      append-to-body
      title="重命名文件夹"
      class="rename-dir-dialog"
    >
      <div>
        <el-input
          v-model="renameDirDialog.name"
          placeholder="请输入文件夹名称"
          :maxlength="60"
          size="medium"
        />
      </div>
      <div class="wrs-flexrow-end-center" style="padding: 20px 0 10px 0">
        <el-button
          class="rename-dir-ok"
          :disabled="!renameDirDialog.name"
          type="primary"
          size="medium"
          @click="onRenameDirOkClick()"
        >
          确 定
        </el-button>
        <el-button
          size="medium"
          class="rename-dir-cancel"
          @click="closeRenameDirDialog()"
        >
          取 消
        </el-button>
      </div>
    </el-dialog>

    <el-dialog
      :visible="renameDocDialog.show"
      :close-on-click-modal="false"
      :show-close="false"
      append-to-body
      title="重命名文档"
      class="rename-doc-dialog"
    >
      <div>
        <el-input
          v-model="renameDocDialog.name"
          placeholder="请输入文档名称"
          :maxlength="60"
          size="medium"
        />
      </div>
      <div class="wrs-flexrow-end-center" style="padding: 20px 0 10px 0">
        <el-button
          class="rename-doc-ok"
          :disabled="!renameDocDialog.name"
          type="primary"
          size="medium"
          @click="onRenameDocOkClick()"
        >
          确 定
        </el-button>
        <el-button
          size="medium"
          class="rename-doc-cancel"
          @click="closeRenameDocDialog()"
        >
          取 消
        </el-button>
      </div>
    </el-dialog>

    <el-dialog
      :visible="deleteDocDialog.show"
      :close-on-click-modal="false"
      :show-close="false"
      append-to-body
      :title="deleteDocDialog.title"
      class="delete-doc-dialog"
    >
      <div>删除后将不能恢复，确认删除吗？</div>
      <div class="wrs-flexrow-end-center" style="padding: 20px 0 10px 0">
        <el-button
          class="delete-doc-ok"
          type="primary"
          size="medium"
          @click="onDeleteDocOkClick()"
        >
          确 定
        </el-button>
        <el-button
          size="medium"
          class="delete-doc-cancel"
          @click="closeDeleteDocDialog()"
        >
          取 消
        </el-button>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import moment from "moment";
import AppConfig from "../../config";
import Ocean from "../../api/ocean";
import AliOSS from "../../api/alioss";
import UI from "../../util/ui";
import FileUtil from "../../util/file";
import ViewContext from "../../viewContext";

const SortNewDocFirst = { mode: 1, name: "新文档优先" };
const SortOldDocFirst = { mode: 2, name: "旧文档优先" };

export default {
  data() {
    return {
      /**
       * 当前文件夹路径列表
       * 顶层文件夹显示'文档空间'
       * 最后一个是当前文件夹
       *
       * 格式：[
       *   {
       *     id: 0, // 文件夹id，顶层文件夹是0
       *     name: '' // 文件夹名
       *   }
       * ]
       */
      routes: [],

      /**
       * 文档列表
       *
       * 格式：[
       *   {
       *     id: 1,
       *     dtype: 0,
       *     name: '',
       *     doctype: '',
       *     rname: '',
       *     update_time
       *     create_time
       * ----------------------------
       *     icon: ''
       *     allowOpen
       *     allowDownload
       *     loading: true
       *   }
       * ]
       */
      docs: [],
      docPageNum: 1,
      /** 每页的数量 */
      docPageSize: 10,

      /** 排序方式列表 */
      sortModeList: [SortNewDocFirst, SortOldDocFirst],
      /** 当前排序方式 */
      currSortMode: SortNewDocFirst,

      /** 文件夹置顶 */
      isDirTop: false,

      /** 已获取全部文件夹 */
      isGotAllDirs: false,
      /** 文件夹数量 */
      dirNum: 0,

      addPopover: {
        show: false,
      },

      searchPopover: {
        show: false,
        searchName: "",
        docs: [],
        pageNum: 1,
        tableMaxHeight: 700,
      },

      docSelector: {
        show: false,
        title: "选择移动位置",
        selectedIds: [],
        disableIds: [],

        doc: null,

        indexOfDocs: 0,
        docs: [],
      },

      createDirDialog: {
        show: false,
        name: "",
      },

      createDocDialog: {
        show: false,
        name: "",
      },

      renameDirDialog: {
        show: false,
        name: "",
        dir: null,
      },

      renameDocDialog: {
        show: false,
        name: "",
        doc: null,
      },

      deleteDocDialog: {
        show: false,
        title: "",
        doc: null,

        indexOfDocs: 0,
        docs: [],
      },
    };
  },
  mounted() {
    this.readUrlQuery();

    if (!this.routes || this.routes.length === 0) {
      this.routes = this.getDefaultRoutes();
    }

    this.setSearchTableMaxHeight();

    this.loadDocs();
  },
  methods: {
    readUrlQuery() {
      let urlQuery = this.$route.query;

      if (urlQuery.ri && urlQuery.rn) {
        let rootRoute = {
          id: parseInt(urlQuery.ri),
          name: urlQuery.rn,
        };

        if (Number.isInteger(rootRoute.id)) {
          this.routes = [rootRoute];
        }
      }

      return true;
    },

    getDefaultRoutes() {
      let defRoutes = [
        {
          id: 0,
          name: "文档空间",
        },
      ];

      return defRoutes;
    },

    setSearchTableMaxHeight() {
      this.searchPopover.tableMaxHeight =
        Math.floor(document.documentElement.clientHeight * 0.95) - 180;
    },

    clearDocs() {
      this.docs = [];
      this.isGotAllDirs = false;
      this.dirNum = 0;
    },

    clearDirsInfo() {
      this.isGotAllDirs = false;
      this.dirNum = 0;
    },

    /**
     * 加载文档列表
     * @param {Object} param0
     *   {
     *     onSuccess: () => {}
     *     onError: () => {}
     *   }
     */
    loadDocs(param0 = {}) {
      if (this.isDirTop) {
        if (this.isGotAllDirs) {
          param0.offset =
            UI.computePagingOffset(this.docPageNum, this.docPageSize) -
            this.dirNum;
          this.getDocsForDirTop(param0);
        } else {
          this.getDirsForTop({
            onSuccess: (newDirs) => {
              if (this.isGotAllDirs && newDirs.length < this.docPageSize) {
                this.getDocsForDirTop({
                  current: this.docs,
                  offset: 0,
                  num: this.docPageSize - newDirs.length,
                  onSuccess: param0.onSuccess,
                  onError: param0.onError,
                });
              } else if (param0.onSuccess) {
                param0.onSuccess();
              }
            },
            onError: param0.onError,
          });
        }
      } else {
        this.getDocs(param0);
      }
    },

    /**
     * 重新加载文档列表
     * @param {Object} param0
     *   {
     *     onSuccess: () => {}
     *     onError: () => {}
     *   }
     */
    reloadDocs(param0 = {}) {
      this.docPageNum = 1;
      this.clearDocs();

      this.loadDocs(param0);
    },

    /**
     * 获取文档列表
     * @param {Object} param0
     *   {
     *     current
     *     dtype: 0, // 获取类型
     *
     *     onSuccess: (newDocs) => {}
     *     onError: (err) => {}
     *   }
     */
    getDocs(param0 = {}) {
      const team = ViewContext.getTeam();
      if (!team) return;

      let parent = this.routes[this.routes.length - 1];
      let docs = param0.current ? param0.current : [];
      let paramsForList = {
        team_id: team.id,
        parent_id: parent.id,
        fields: [
          "id",
          "parentid",
          "dtype",
          "name",
          "doctype",
          "rname",
          "update_time",
          "create_time",
        ],
        sort_by: this.currSortMode.mode,
        offset: UI.computePagingOffset(this.docPageNum, this.docPageSize),
        num: this.docPageSize,
      };

      if (Number.isInteger(param0.dtype)) {
        paramsForList.dtype = param0.dtype;
      }

      Ocean.listTeamDsDoc(paramsForList)
        .then((res) => {
          let rdocs = res.data.docs;

          if (rdocs) {
            for (let doc of rdocs) {
              this.makeDocForView(doc);
              docs.push(doc);
            }
          }

          this.docs = docs;

          if (param0.onSuccess) {
            rdocs = rdocs ? rdocs : [];
            param0.onSuccess(rdocs);
          }
        })
        .catch((err) => {
          if (param0.onError) {
            param0.onError(err);
          }
          this.$message.error(err.data.msg);
        });
    },

    /**
     * 获取文件夹列表（文件夹置顶）
     * @param {Object} param0
     *   {
     *     current
     *     onSuccess: (newDirs) => {}
     *     onError: (err) => {}
     *   }
     */
    getDirsForTop(param0 = {}) {
      const team = ViewContext.getTeam();
      if (!team) return;

      let parent = this.routes[this.routes.length - 1];
      let docs = param0.current ? param0.current : [];
      let paramsForList = {
        team_id: team.id,
        parent_id: parent.id,
        dtype: 0,
        fields: [
          "id",
          "parentid",
          "dtype",
          "name",
          "doctype",
          "rname",
          "update_time",
          "create_time",
        ],
        sort_by: this.currSortMode.mode,
        offset: UI.computePagingOffset(this.docPageNum, this.docPageSize),
        num: this.docPageSize,
      };

      Ocean.listTeamDsDoc(paramsForList)
        .then((res) => {
          let rdocs = res.data.docs ? res.data.docs : [];

          for (let doc of rdocs) {
            this.makeDocForView(doc);
            docs.push(doc);
          }

          this.docs = docs;
          this.dirNum += rdocs.length;
          this.isGotAllDirs = rdocs.length < paramsForList.num;

          if (param0.onSuccess) {
            param0.onSuccess(rdocs);
          }
        })
        .catch((err) => {
          if (param0.onError) {
            param0.onError(err);
          }
          this.$message.error(err.data.msg);
        });
    },

    /**
     * 获取文档列表（文件夹置顶）
     * @param {Object} param0
     *   {
     *     current
     *     offset
     *     num
     *     onSuccess: (newDocs) => {}
     *     onError: (err) => {}
     *   }
     */
    getDocsForDirTop(param0 = {}) {
      const team = ViewContext.getTeam();
      if (!team) return;

      let parent = this.routes[this.routes.length - 1];
      let docs = param0.current ? param0.current : [];
      let offset = param0.offset && param0.offset >= 0 ? param0.offset : 0;
      let num = param0.num && param0.num > 0 ? param0.num : this.docPageSize;
      let paramsForList = {
        team_id: team.id,
        parent_id: parent.id,
        dtype: 1,
        fields: [
          "id",
          "parentid",
          "dtype",
          "name",
          "doctype",
          "rname",
          "update_time",
          "create_time",
        ],
        sort_by: this.currSortMode.mode,
        offset,
        num,
      };

      Ocean.listTeamDsDoc(paramsForList)
        .then((res) => {
          let rdocs = res.data.docs;

          if (rdocs) {
            for (let doc of rdocs) {
              this.makeDocForView(doc);
              docs.push(doc);
            }
          }

          this.docs = docs;

          if (param0.onSuccess) {
            rdocs = rdocs ? rdocs : [];
            param0.onSuccess(rdocs);
          }
        })
        .catch((err) => {
          if (param0.onError) {
            param0.onError(err);
          }
          this.$message.error(err.data.msg);
        });
    },

    /**
     * 搜索文档
     * @param {Object} param0
     *   {
     *     current
     *     onSuccess: (newDocs) => {}
     *     onError: (err) => {}
     *   }
     */
    getSearchDocs(param0 = {}) {
      const team = ViewContext.getTeam();
      if (!team) return;

      let docs = param0.current ? param0.current : [];
      let paramsForList = {
        team_id: team.id,
        fields: [
          "id",
          "dtype",
          "name",
          "doctype",
          "rname",
          "update_time",
          "create_time",
        ],
        sort_by: SortNewDocFirst.mode,
        offset: UI.computePagingOffset(
          this.searchPopover.pageNum,
          this.docPageSize
        ),
        num: this.docPageSize,
      };

      if (this.searchPopover.searchName) {
        paramsForList.name = this.searchPopover.searchName;
      }

      Ocean.listTeamDsDoc(paramsForList)
        .then((res) => {
          let rdocs = res.data.docs;

          if (rdocs) {
            for (let doc of rdocs) {
              this.makeDocForView(doc);
              docs.push(doc);
            }
          }

          this.searchPopover.docs = docs;

          if (param0.onSuccess) {
            rdocs = rdocs ? rdocs : [];
            param0.onSuccess(rdocs);
          }
        })
        .catch((err) => {
          if (param0.onError) {
            param0.onError(err);
          }
          this.$message.error(err.data.msg);
        });
    },

    makeDocForView(doc) {
      if (doc) {
        if (doc.dtype === 0) {
          doc.icon = "folder";
        } else {
          const fileModel = FileUtil.getFileModel(doc.doctype);
          doc.icon = fileModel.icon;
          doc.allowOpen = FileUtil.isRTF(doc.doctype);
          doc.allowDownload = !doc.allowOpen;
        }
      }
    },

    /**
     * 添加文档
     * @param {Object} doc
     * @param {*} onSuccess: (doc) => {}
     * @param {*} onError: (err) => {}
     */
    addDoc(doc, onSuccess, onError) {
      Ocean.addTeamDsDoc(doc)
        .then((res) => {
          doc.id = res.data.id;

          if (onSuccess) {
            onSuccess(doc);
          }
        })
        .catch((err) => {
          if (onError) {
            onError(err);
          }
          this.$message.error(err.data.msg);
        });
    },

    /**
     * 更新文档
     * @param {Object} doc
     * @param {*} onSuccess: (doc) => {}
     * @param {*} onError: (err) => {}
     */
    updateDoc(doc, onSuccess, onError) {
      Ocean.updateTeamDsDoc(doc)
        .then(() => {
          if (onSuccess) {
            onSuccess(doc);
          }
        })
        .catch((err) => {
          if (onError) {
            onError(err);
          }
          this.$message.error(err.data.msg);
        });
    },

    /**
     * 删除文档
     * @param {Object} doc
     * @param {*} onSuccess: () => {}
     * @param {*} onError: (err) => {}
     */
    deleteDoc(id, onSuccess, onError) {
      Ocean.deleteTeamDsDoc(id)
        .then(() => {
          if (onSuccess) {
            onSuccess();
          }
        })
        .catch((err) => {
          if (onError) {
            onError(err);
          }
          this.$message.error(err.data.msg);
        });
    },

    insertNewDocForView(doc) {
      if (this.currSortMode.mode === SortNewDocFirst.mode) {
        this.docs.splice(0, 0, doc);
      } else if (this.currSortMode.mode === SortOldDocFirst.mode) {
        this.docs.push(doc);
      }
    },

    onDocSpaceClick() {
      this.routes = this.getDefaultRoutes();
      this.reloadDocs();
    },

    onParentRouteClick(route, index) {
      this.routes.splice(index + 1);
      this.reloadDocs();
    },

    onCurrentRouteCommand(command) {
      let currDir =
        this.routes && this.routes.length > 0
          ? this.routes[this.routes.length - 1]
          : null;

      if (currDir) {
        switch (command) {
          case 2:
            this.openDirToNewWindow(currDir);
            break;
          case 6:
            this.renameDirDialog.name = currDir.name;
            this.renameDirDialog.dir = currDir;
            this.renameDirDialog.show = true;
            break;
          case 7:
            this.docSelector.doc = currDir;
            this.docSelector.selectedIds = [currDir.parentid];
            this.docSelector.disableIds = [currDir.id];
            this.docSelector.show = true;
            break;
          case 9:
            this.deleteDocDialog.title = `删除文件夹[${currDir.name}]`;
            this.deleteDocDialog.doc = currDir;
            this.deleteDocDialog.show = true;
            break;
          default:
            break;
        }
      }
    },

    onAddDirClick() {
      this.addPopover.show = false;
      this.createDirDialog.show = true;
    },

    onAddDocClick() {
      this.addPopover.show = false;
      this.createDocDialog.show = true;
    },

    onBeforeUploadDoc(file) {
      if (file.size > AppConfig.docSpace.maxDocSize) {
        this.$message.error(AppConfig.docSpace.maxDocSizeError);

        // 停止上传
        return false;
      }

      let fileExt = FileUtil.getFileExtension(file.name);
      if (!AppConfig.docSpace.whiteDocTypes.includes(fileExt)) {
        this.$message.error(AppConfig.docSpace.docTypeAllowMessage);

        // 停止上传
        return false;
      }

      this.addPopover.show = false;

      const team = ViewContext.getTeam();
      let currDir =
        this.routes && this.routes.length > 0
          ? this.routes[this.routes.length - 1]
          : null;
      if (!team || !currDir) {
        return false;
      }

      let doc = {
        team_id: team.id,
        parent_id: currDir.id,
        dtype: 1,
        name: file.name,
        rname: AliOSS.getDocName(fileExt),
        doctype: fileExt,
      };

      this.addDoc(doc, () => {
        doc.create_time = moment().format("YYYY-MM-DD HH:mm:ss");
        doc.update_time = doc.create_time;
        this.makeDocForView(doc);

        doc.loading = true;
        this.insertNewDocForView(doc);

        this.uploadDocToOSS(file, doc);
      });

      // 如需自定义上传动作，返回 false，否则会自动执行上传。
      // 返回 false 会停止默认的上传行为。
      return false;
    },

    onUploadDoc(res) {
      console.log("upload doc", res);
    },

    onUploadDocSuccess(response, file, fileList) {
      console.log("upload success", response, file, fileList);
    },

    uploadDocToOSS: async function (file, doc) {
      Promise.resolve()
        .then(async () => {
          const ossClient = await AliOSS.getClient();
          let docOssKey = AliOSS.getDocKey(doc.rname, doc.id);
          return ossClient.put(docOssKey, file);
        })
        .then(() => {
          doc.loading = false;
        })
        .catch(() => {
          doc.loading = false;
          this.$message.error("文档上传失败");
        });
    },

    openDoc(doc) {
      if (FileUtil.isRTF(doc.doctype)) {
        window.open(
          `/coa/dsrtf?i=${doc.id}&n=${doc.rname}&t=1&title=${doc.name}`,
          "_blank"
        );
      } else {
        this.downloadDoc(doc);
      }
    },

    downloadDoc: async function (doc) {
      let ossClient = await AliOSS.getClientCdn();
      const fileModel = FileUtil.getFileModel(doc.doctype);
      let link = document.createElement("a");
      link.target = fileModel.aTarget;
      link.download = doc.name;
      if (!link.download.endsWith(`.${doc.doctype}`)) {
        link.download += `.${doc.doctype}`;
      }
      const docOssKey = AliOSS.getDocKey(doc.rname, doc.id);
      link.href = ossClient.signatureUrl(docOssKey, {
        response: {
          "content-disposition": `attachment; filename=${encodeURIComponent(
            link.download
          )}`,
        },
      });
      link.click();
    },

    copyDoc(doc) {
      const team = ViewContext.getTeam();
      let currDir =
        this.routes && this.routes.length > 0
          ? this.routes[this.routes.length - 1]
          : null;

      if (doc && team && currDir) {
        let docContent = null;
        let newDoc = {
          team_id: team.id,
          parent_id: currDir.id,
          dtype: 1,
          name: doc.name + " 副本",
          rname: AliOSS.getDocName(doc.doctype),
          doctype: doc.doctype,
          fromtype: 1,
          fromid: doc.id,
        };

        Promise.resolve()
          .then(async () => {
            try {
              const ossClient = await AliOSS.getClient();
              const result = await ossClient.get(
                AliOSS.getDocKey(doc.rname, doc.id)
              );
              docContent = result.content.toString();

              return Ocean.addTeamDsDoc(newDoc);
            } catch (err) {
              // 404（目标Object不存在），参考https://help.aliyun.com/document_detail/31980.htm?spm=a2c4g.11186623.0.0.b7ff7dbbVRQuEJ#reference-ccf-rgd-5db
              if (!err || err.status !== 404) {
                throw "无法读取文档";
              }
            }
          })
          .then(async (res) => {
            if (res && res.data && Number.isInteger(res.data.id)) {
              newDoc.id = res.data.id;
              newDoc.create_time = moment().format("YYYY-MM-DD HH:mm:ss");
              newDoc.update_time = newDoc.create_time;
              this.makeDocForView(newDoc);

              this.insertNewDocForView(newDoc);

              if (docContent != null) {
                try {
                  const ossClient = await AliOSS.getClient();

                  const docBuffer = new AliOSS.OSS.Buffer(docContent);
                  await ossClient.put(
                    AliOSS.getDocKey(newDoc.rname, newDoc.id),
                    docBuffer
                  );
                } catch (err) {
                  throw "创建副本失败";
                }
              }
            }
          })
          .catch((err) => {
            if (typeof err === "string") {
              this.$message.error(err);
            } else {
              this.$message.error(err.data.msg);
            }
          });
      }
    },

    openDir(dir) {
      this.routes.push(dir);
      this.reloadDocs();
    },

    /**
     * 新标签页打开文件夹
     * @param {Object} dir
     *   id
     *   name
     */
    openDirToNewWindow(dir) {
      window.open(`/coa/docspace?ri=${dir.id}&rn=${dir.name}`, "_blank");
    },

    onSortModeSelect(command) {
      if (command.cmd === 0) {
        // 排序方式
        this.currSortMode = command.value;
        this.reloadDocs();
      } else if (command.cmd === 1) {
        // 文件夹置顶
        this.isDirTop = !this.isDirTop;
        this.reloadDocs();
      }
    },

    onSearchClick() {
      this.searchPopover.show = !this.searchPopover.show;
      setTimeout(() => {
        if (this.$refs.searchInput) {
          this.$refs.searchInput.focus();
        }
      }, 100);
    },

    onDocRowDblClick(row /*, column, event */) {
      if (row.dtype === 0) {
        this.openDir(row);
      } else {
        this.openDoc(row);
      }
    },

    /**
     * 点击<文件夹>/<文档>
     */
    onDocNameClick(row /*, index */) {
      if (row.dtype === 0) {
        this.openDir(row);
      } else {
        this.openDoc(row);
      }
    },

    onDirMoreItemClick(command) {
      switch (command.cmd) {
        case 1:
          this.openDir(command.row);
          break;
        case 2:
          this.openDirToNewWindow(command.row);
          break;
        case 6:
          this.renameDirDialog.name = command.row.name;
          this.renameDirDialog.dir = command.row;
          this.renameDirDialog.show = true;
          break;
        case 7:
          this.docSelector.doc = command.row;
          this.docSelector.selectedIds = [command.row.parentid];
          this.docSelector.disableIds = [command.row.id];
          this.docSelector.indexOfDocs = command.index;
          this.docSelector.docs = this.docs;
          this.docSelector.show = true;
          break;
        case 9:
          this.deleteDocDialog.title = `删除文件夹[${command.row.name}]`;
          this.deleteDocDialog.doc = command.row;
          this.deleteDocDialog.indexOfDocs = command.index;
          this.deleteDocDialog.docs = this.docs;
          this.deleteDocDialog.show = true;
          break;
        default:
          break;
      }
    },

    onDocMoreItemClick(command) {
      switch (command.cmd) {
        case 1:
          this.openDoc(command.row);
          break;
        case 3:
          this.openDoc(command.row);
          break;
        case 6:
          this.renameDocDialog.name = command.row.name;
          this.renameDocDialog.doc = command.row;
          this.renameDocDialog.show = true;
          break;
        case 7:
          this.docSelector.doc = command.row;
          this.docSelector.selectedIds = [command.row.parentid];
          this.docSelector.disableIds = [command.row.id];
          this.docSelector.indexOfDocs = command.index;
          this.docSelector.docs = this.docs;
          this.docSelector.show = true;
          break;
        case 8:
          this.copyDoc(command.row);
          break;
        case 9:
          this.deleteDocDialog.title = `删除文档[${command.row.name}]`;
          this.deleteDocDialog.doc = command.row;
          this.deleteDocDialog.indexOfDocs = command.index;
          this.deleteDocDialog.docs = this.docs;
          this.deleteDocDialog.show = true;
          break;
        default:
          break;
      }
    },

    onDocPageNumChange(pageNum) {
      let oldPageNum = this.docPageNum;
      this.docPageNum = pageNum;

      if (this.isDirTop && pageNum <= oldPageNum) {
        if (this.isGotAllDirs) {
          if (
            UI.computePagingOffset(this.docPageNum, this.docPageSize) <=
            this.dirNum
          ) {
            this.isGotAllDirs = false;
            this.dirNum = UI.computePagingOffset(
              this.docPageNum,
              this.docPageSize
            );
          }
        } else {
          this.dirNum = UI.computePagingOffset(
            this.docPageNum,
            this.docPageSize
          );
        }
      }

      this.loadDocs();
    },

    onCloseSearchPopoverClick() {
      this.searchPopover.show = false;
    },

    onDoSearchClick() {
      if (this.searchPopover.searchName) {
        this.searchPopover.pageNum = 1;
        this.getSearchDocs();
      } else {
        this.$message.error("请输入搜索名称");
      }
    },

    onSearchDocRowDblClick(row /*, column, event */) {
      if (row.dtype === 0) {
        this.openDirToNewWindow(row);
      } else {
        this.openDoc(row);
      }
    },

    onSearchDocNameClick(row /*, index */) {
      if (row.dtype === 0) {
        this.openDirToNewWindow(row);
      } else {
        this.openDoc(row);
      }
    },

    onSearchDocPageNumChange(pageNum) {
      this.searchPopover.pageNum = pageNum;
      this.getSearchDocs();
    },

    closeDocSelector() {
      this.docSelector.show = false;
      this.docSelector.selectedIds = [];
      this.docSelector.disableIds = [];
      this.docSelector.doc = null;
      this.docSelector.indexOfDocs = 0;
      this.docSelector.docs = [];
    },

    onDsSelect(e) {
      let { doc: moveDoc, indexOfDocs, docs } = this.docSelector;
      let routes = this.routes;
      let currDir =
        routes && routes.length > 0 ? routes[routes.length - 1] : null;

      if (moveDoc && moveDoc.id !== e.doc.id) {
        let upDocParams = {
          id: moveDoc.id,
          parent_id: e.doc.id,
        };

        this.updateDoc(upDocParams, () => {
          docs.splice(indexOfDocs, 1);
          if (currDir && moveDoc.id === currDir.id) {
            // 移动当前文件夹
            routes.splice(routes.length - 1, 1);
            if (routes.length === 0) {
              this.routes = this.getDefaultRoutes();
            }
            this.reloadDocs();
          } else if (moveDoc.dtype === 0 && this.isDirTop) {
            this.dirNum -= 1;
          }
        });
      }

      this.closeDocSelector();
    },

    onCreateDirOkClick() {
      if (!this.createDirDialog.name) {
        this.$message.error("请输入文件夹名称");
        return;
      }

      const team = ViewContext.getTeam();
      let currDir =
        this.routes && this.routes.length > 0
          ? this.routes[this.routes.length - 1]
          : null;
      if (!team || !currDir) return;

      let doc = {
        team_id: team.id,
        parent_id: currDir.id,
        dtype: 0,
        name: this.createDirDialog.name,
      };

      this.addDoc(doc, () => {
        doc.create_time = moment().format("YYYY-MM-DD HH:mm:ss");
        doc.update_time = doc.create_time;
        this.makeDocForView(doc);

        this.insertNewDocForView(doc);

        // 不计入<文件夹数量>

        this.closeCreateDirDialog();
      });
    },

    closeCreateDirDialog() {
      this.createDirDialog.show = false;
    },

    onCreateDocOkClick() {
      if (!this.createDocDialog.name) {
        this.$message.error("请输入文档名称");
        return;
      }

      const team = ViewContext.getTeam();
      let currDir =
        this.routes && this.routes.length > 0
          ? this.routes[this.routes.length - 1]
          : null;
      if (!team || !currDir) return;

      let doc = {
        team_id: team.id,
        parent_id: currDir.id,
        dtype: 1,
        name: this.createDocDialog.name,
        rname: AliOSS.getDocName("rtf"),
        doctype: "rtf",
      };

      this.addDoc(doc, () => {
        doc.create_time = moment().format("YYYY-MM-DD HH:mm:ss");
        doc.update_time = doc.create_time;
        this.makeDocForView(doc);

        this.insertNewDocForView(doc);

        this.closeCreateDocDialog();
      });
    },

    closeCreateDocDialog() {
      this.createDocDialog.show = false;
    },

    onRenameDirOkClick() {
      if (!this.renameDirDialog.name) {
        this.$message.error("请输入文件夹名称");
        return;
      }

      if (this.renameDirDialog.dir) {
        let upDirParams = {
          id: this.renameDirDialog.dir.id,
          name: this.renameDirDialog.name,
        };

        this.updateDoc(upDirParams, () => {
          this.renameDirDialog.dir.name = upDirParams.name;

          this.closeRenameDirDialog();
        });
      }
    },

    closeRenameDirDialog() {
      this.renameDirDialog.show = false;
      this.renameDirDialog.name = "";
      this.renameDirDialog.dir = null;
    },

    onRenameDocOkClick() {
      if (!this.renameDocDialog.name) {
        this.$message.error("请输入文档名称");
        return;
      }

      if (this.renameDocDialog.doc) {
        let upDocParams = {
          id: this.renameDocDialog.doc.id,
          name: this.renameDocDialog.name,
        };

        this.updateDoc(upDocParams, () => {
          this.renameDocDialog.doc.name = upDocParams.name;

          this.closeRenameDocDialog();
        });
      }
    },

    closeRenameDocDialog() {
      this.renameDocDialog.show = false;
      this.renameDocDialog.name = "";
      this.renameDocDialog.doc = null;
    },

    onDeleteDocOkClick() {
      let { doc: delDoc, indexOfDocs, docs } = this.deleteDocDialog;
      let routes = this.routes;
      let currDir =
        routes && routes.length > 0 ? routes[routes.length - 1] : null;

      if (delDoc) {
        this.deleteDoc(delDoc.id, () => {
          if (delDoc.dtype === 0) {
            if (currDir && delDoc.id === currDir.id) {
              // 删除当前文件夹
              routes.splice(routes.length - 1, 1);
              if (routes.length === 0) {
                this.routes = this.getDefaultRoutes();
              }
              this.reloadDocs();
            } else {
              // 删除文件夹
              docs.splice(indexOfDocs, 1);
              if (this.isDirTop) {
                this.dirNum -= 1;
              }
            }
          } else {
            // 删除文档
            docs.splice(indexOfDocs, 1);
          }

          this.closeDeleteDocDialog();
        });
      }
    },

    closeDeleteDocDialog() {
      this.deleteDocDialog.show = false;
      this.deleteDocDialog.title = "";
      this.deleteDocDialog.doc = null;
      this.deleteDocDialog.indexOfDocs = 0;
      this.deleteDocDialog.docs = [];
    },
  },
};
</script>

<style lang="scss">
.add-popover.el-popover {
  min-width: initial;
  padding: 10px 0;
}

.doc-upload .el-upload {
  width: 100%;
}

.toolbar-vdivider.el-divider--vertical,
.route-bar-vdivider.el-divider--vertical {
  height: 26px;
}

.delete-doc-dialog .el-dialog,
.rename-doc-dialog .el-dialog,
.rename-dir-dialog .el-dialog,
.create-doc-dialog .el-dialog,
.create-dir-dialog .el-dialog {
  margin-top: 20vh !important;
  padding: 0 10px;
  width: 600px;
}

.delete-doc-dialog .el-dialog__body,
.rename-doc-dialog .el-dialog__body,
.rename-dir-dialog .el-dialog__body,
.create-doc-dialog .el-dialog__body,
.create-dir-dialog .el-dialog__body {
  padding-top: 10px;
  padding-bottom: 10px;
}
</style>

<style scoped lang="scss">
.container {
  width: 100%;
  padding: 50px 50px 60px 270px;
  box-sizing: border-box;
}

.head-bar {
  padding: 16px 0;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
}

.route-bar {
  flex: 1;
  width: auto;
  display: inline-block;
  overflow-x: auto;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
}

.route-parent,
.route-current {
  max-width: 150px;
  padding: 6px 10px;
  box-sizing: border-box;
  font-size: 15px;
  display: inline-flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  border: none;
  background: transparent;
  text-decoration: none;
  cursor: pointer;
  user-select: none;
  border-radius: 2px;
  outline: currentcolor none medium;
  flex-shrink: 0;
}
.route-parent {
  color: #606266;
}
.route-current {
  color: #41464b;
}
.route-parent:hover,
.route-current:hover {
  background-color: #fafafa;
}

.route-sep {
  padding: 0 2px;
  font-size: 15px;
  color: #41464b;
  flex-shrink: 0;
}

.toolbar {
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  align-items: center;
  flex-shrink: 0;
}

.toolbar-item {
  padding: 6px 8px;
  border-radius: 4px;
  cursor: pointer;
  outline: currentcolor none medium;
  flex-shrink: 0;
}
.toolbar-item:hover {
  background-color: #fafafa;
}

.toolbar-item.icon,
.toolbar-item .icon {
  font-size: 16px;
}

.toolbar-item.txt,
.toolbar-item .txt {
  font-size: 15px;
}

.dropdown-item {
  padding: 9px 20px;
  cursor: pointer;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
}
.dropdown-item:hover {
  background-color: #f5f5f5;
}

.dropdown-icon {
  margin-right: 7px;
  width: 14px;
  height: 14px;
}

.dropdown-icon-dir {
  margin-left: -2px;
  margin-right: 5px;
  width: 18px;
  height: 18px;
}

.dropdown-icon-doc {
  margin-left: -4px;
  width: 14px;
  height: 14px;
  padding: 0 7px 0 4px;
}

.search-popover-close {
  padding: 4px;
  font-size: 16px;
  border: none;
  border-radius: 50%;
  background-color: #f5f5f5;
}

.doc-name {
  padding: 4px 10px;
  cursor: pointer;
}
.doc-name:hover {
  text-decoration: underline;
}

.dir-icon,
.doc-icon {
  display: inline-block;
  border-radius: 3px;
  flex-shrink: 0;
}

.dir-icon {
  width: 34px;
  height: 34px;
}

.doc-icon {
  width: 26px;
  height: 26px;
  padding: 4px 4px;
}

.doc-more {
  padding: 6px 6px;
  border-radius: 3px;
  color: #727272;
  flex-shrink: 0;
}
.doc-more:hover {
  background-color: #ececec;
}

.delete-doc-ok,
.rename-doc-ok,
.rename-dir-ok,
.create-doc-ok,
.create-dir-ok {
  padding: 8px 16px;
  outline: currentcolor none medium;
}

.delete-doc-cancel,
.rename-doc-cancel,
.rename-dir-cancel,
.create-doc-cancel,
.create-dir-cancel {
  padding: 8px 16px;
  outline: currentcolor none medium;
}
</style>
