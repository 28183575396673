<template>
  <div ref="ttContainer" class="tt-container">
    <editor-content ref="ttContent" class="tt-content" :editor="editor" />

    <bubble-menu
      v-if="editor"
      :editor="editor"
      class="tt-menu tt-table-menubar"
      pluginKey="tableMenubar"
      :tippy-options="tableTippyOptions"
      :shouldShow="showTableMenu"
    >
    </bubble-menu>
    <bubble-menu
      v-if="editor"
      :editor="editor"
      class="tt-menu tt-link-menu"
      pluginKey="linkMenu"
      :tippy-options="linkTippyOptions"
      :shouldShow="showLinkMenu"
    >
    </bubble-menu>
  </div>
</template>

<script>
import extend from "extend";

import { Editor, EditorContent, BubbleMenu } from "@tiptap/vue-2";
import { findParentNodeClosestToPos } from "@tiptap/core";

import { create as createEditor } from "@weiruo/tiptap-editor/src/editor";
import "@weiruo/tiptap-editor/src/editor.css";

import { Toolbar } from "./toolbar";
import "./toolbar.css";

import { TableMenubar, LinkMenu } from "./bubbleMenu";
import "tippy.js/dist/tippy.css";
import "tippy.js/dist/border.css";
import "./bubbleMenu.css";

var thisRef = null;

export default {
  // name: "tiptap-editor",
  components: {
    EditorContent,
    BubbleMenu,
  },
  data() {
    return {
      editor: null,
      toolbar: null,
      /* 内容发生改变 */
      changed: false,
      tableTippyOptions: {
        // placement: 'top',
        getReferenceClientRect: () => {
          const { selection } = thisRef.editor.state;
          const table = findParentNodeClosestToPos(
            selection.ranges[0].$from,
            (node) => {
              return node.type.name === "table";
            }
          );

          const node = thisRef.editor.view.nodeDOM(table.pos); // as HTMLElement
          if (node) {
            return node.getBoundingClientRect();
          }
        },
        onCreate(instance) {
          const menubar = instance.popper.querySelector(".tt-table-menubar");
          thisRef.tableMenubar = new TableMenubar(
            thisRef,
            {
              container: menubar,
            },
            instance
          );
        },
        onDestroy(/* instance */) {
          delete thisRef.tableMenubar;
        },
        onHide(/* instance */) {
          thisRef.tableMenubar.closePickers();
        },
      },
      linkTippyOptions: {
        onCreate(instance) {
          const menubar = instance.popper.querySelector(".tt-link-menu");
          thisRef.linkMenu = new LinkMenu(
            thisRef,
            {
              container: menubar,
            },
            instance
          );
        },
        onDestroy(/* instance */) {
          delete thisRef.linkMenu;
        },
        onShow(/* instance */) {
          thisRef.linkMenu.fillLink();
        },
        onHide(/* instance */) {
          thisRef.linkMenu.preview();
        },
      },
    };
  },
  props: {
    content: String,
    value: String,
    /**
     * {
     *   toolbar: {
     *     container, // [String, HTMLElement, undefined]
     *     items, // [Array, undefined]
     *     image: {
     *       options: []
     *       upload(files, editor): Function
     *       insertNetResourceImage(): Function
     *     },
     *     shouldShow: true, // 始终显示toolbar，包括编辑器不能编辑时。
     *   }
     * }
     */
    options: {
      type: Object,
      required: false,
      default: () => ({}),
    },
    editable: {
      type: Boolean,
      default: false,
    },
  },
  watch: {
    content(newVal /* oldVal */) {
      if (!this.editor) return;

      const isSame = this.editor.getHTML() === newVal;
      // const isSame = JSON.stringify(this.editor.getJSON()) === JSON.stringify(newVal);
      if (isSame) return;

      this.editor.commands.setContent(newVal, false);
    },
    value(newVal /* oldVal */) {
      if (!this.editor) return;

      const isSame = this.editor.getHTML() === newVal;
      // const isSame = JSON.stringify(this.editor.getJSON()) === JSON.stringify(newVal);
      if (isSame) return;

      this.editor.commands.setContent(newVal, false);
    },
    editable(newVal, oldVal) {
      if (this.editor && newVal != oldVal) {
        if (newVal) {
          this.openEditable();
        } else {
          this.closeEditable();
        }
      }
    },
  },
  beforeCreate() {
    thisRef = this;
  },
  mounted() {},
  methods: {
    initialize() {
      const editorOptions = extend(true, {}, this.options);

      this.toolbarOptions = editorOptions.toolbar;
      delete editorOptions.toolbar;

      // 防止：添加初始内容后，将初始内容作为第一个编辑历史，执行undo将编辑器内容变为空白。
      if (this.value || this.content) {
        editorOptions.content = this.value || this.content;
      }

      this.editor = createEditor(Editor, editorOptions);
      if (this.editable) {
        this.openEditable();
      } else {
        this.editor.setEditable(false);
      }
    },
    initToolbar(options) {
      this.toolbar = new Toolbar(this, options || {});
    },
    onUpdate(/*{ editor }*/) {
      if (!this.changed) {
        this.changed = true;
      }

      // HTML: editor.getHTML()
      // JSON: editor.getJSON()
      this.$emit("input", this);
    },
    onSelectionUpdate({ editor }) {
      this.toolbar.update();

      if (
        thisRef.linkMenu &&
        editor.isActive("link") &&
        !thisRef.linkMenu.isEditing()
      ) {
        thisRef.linkMenu.fillLink();
      }
    },
    openEditable() {
      if (!this.editor.isEditable) {
        this.editor.setEditable(true);
      }
      this.editor.on("update", this.onUpdate);
      this.editor.on("selectionUpdate", this.onSelectionUpdate);

      if (!this.toolbar) {
        this.initToolbar(this.toolbarOptions);
      }
    },
    closeEditable() {
      if (this.editor.isEditable) {
        this.editor.setEditable(false);
      }
      this.editor.off("update", this.onUpdate);
      this.editor.off("selectionUpdate", this.onSelectionUpdate);
    },
    showTableMenu({ editor /* view, state, oldState, from, to */ }) {
      return editor.isActive("table");
    },
    showLinkMenu({ editor /* view, state, oldState, from, to */ }) {
      return editor.isActive("link");
    },
    setEditorHeight(height) {
      this.$refs.ttContainer.style.height = `${height}px`;
    },
    setContent(content) {
      this.editor.commands.setContent(content, false);
    },
  },
  beforeDestroy() {
    if (this.editor) {
      this.editor.destroy();
      delete this.editor;
    }
  },
};
</script>

<style scoped lang="scss">
.tt-container {
  width: 100%;
  padding: 3px 20px 20px 20px;
  box-sizing: border-box;
  position: relative;
}

.tt-content {
  width: 100%;
  height: 100%;
  margin: 0;
  font-family: "PingFang SC", "Helvetica Neue", Arial, "Hiragino Sans GB",
    "Microsoft YaHei UI", "Microsoft YaHei", SimSun, sans-serif;
  font-size: 11pt;
  position: relative;
}

::v-deep .ProseMirror:focus-visible {
  outline: none;
}

::v-deep .ProseMirror {
  max-width: 760px;
  height: auto;
  min-height: 100vh;
  padding: 40px 100px 200px 100px;
  margin: 0 auto;
}
</style>
