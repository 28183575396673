import Vue from "vue";
import Ocean from "./api/ocean";
import UserUtil from "./util/user";

var _data = {
  /**
   * 登录用户；
   * {
   *   id
   *   ucode
   *   nickname
   *   is_fullavatar
   *   avatar
   * }
   */
  user: null,
  authTime: null,
  authStatus: {
    // 认证状态，0：未登录，1：登录成功，2：登录失败；3：...；
    code: 0,
  },
};

async function Authenticate() {
  if (!_data.user) {
    let uid = getCookieUid();

    if (uid) {
      // 登录未过期，获取用户信息
      let params = {
        fields: ["id", "ucode", "nickname", "is_fullavatar", "avatar"],
      };
      await Ocean.getUser(params)
        .then((res) => {
          let ruser = res.data;

          _data.authTime = new Date();
          _data.user = ruser;
          _data.user.avatar = UserUtil.makeAvatarThumb(ruser, {
            useDefault: true,
          });

          _data.authStatus.code = 1;
        })
        .catch(() => {
          _data.authStatus.code = 2;
        });
    } else {
      // 未登录或登录过期
      _data.authStatus.code = 0;
    }
  }

  if (_data.user) {
    // 如果_data.user之前就存在，则再次设置cookie，用于更新过期时间。
    setCookieUid(_data.user.id);

    if (_data.authStatus.code !== 1) {
      _data.authStatus.code = 1;
    }

    return true;
  }

  return false;
}

function getAuthenticateStatus() {
  return _data.authStatus;
}

function getUser() {
  return _data.user;
}

function setUser(user) {
  _data.user = user;
}

function removeUser() {
  _data.user = null;
}

function getUserName() {
  if (_data.user) {
    return _data.user.nickname;
  }

  return "";
}

function getUserAvatar(withDefault) {
  if (_data.user) {
    return _data.user.avatar;
  }

  if (withDefault) {
    return UserUtil.defaultAvatar;
  }

  return "";
}

function getUid(fromCookie) {
  if (_data.user) {
    return _data.user.id;
  }

  if (fromCookie) {
    let cookieUid = Vue.$cookies.get("u");
    if (cookieUid) {
      return parseInt(cookieUid);
    }
  }
}

function getCookieUid() {
  let cookieUid = Vue.$cookies.get("u");
  if (cookieUid) {
    return parseInt(cookieUid);
  }
}

function setCookieUid(uid) {
  Vue.$cookies.set("u", uid, "60d");

  // never expire
  //Vue.$cookies.set("u","GH1.1.1689020474.1484362313", Infinity);
  //Vue.$cookies.set("u","GH1.1.1689020474.1484362313", -1);
}

function removeCookieUid() {
  Vue.$cookies.remove("u");
}

//const _ucodeRegExp = /^[a-zA-Z][a-zA-Z0-9_-]*$/
const _ucodeRegExp = /^[a-zA-Z]{1}[-_a-zA-Z0-9]{5,19}$/;

function testValidUcode(ucode) {
  return _ucodeRegExp.test(ucode);
}

export default {
  Authenticate,
  getAuthenticateStatus,
  getUser,
  setUser,
  removeUser,
  getUserName,
  getUserAvatar,
  getUid,
  getCookieUid,
  setCookieUid,
  removeCookieUid,
  testValidUcode,
};
