/*
 * A JavaScript implementation of the RSA Data Security, Inc. MD5 Message
 * Digest Algorithm, as defined in RFC 1321.
 * Version 1.1 Copyright (C) Paul Johnston 1999 - 2002.
 * Code also contributed by Greg Holt
 * See http://pajhome.org.uk/site/legal.html for details.
 */

function getFileName(filepath) {
  let filename = "";

  if (filepath) {
    let pathIndex = Math.max(
      filepath.lastIndexOf("/"),
      filepath.lastIndexOf("\\")
    );

    if (pathIndex < 0) {
      filename = filepath;
    } else {
      filename = filepath.substring(pathIndex + 1);
    }
  }

  return filename;
}

function getFileExtension(file) {
  let ext = "";
  let filename = getFileName(file);

  if (filename) {
    let extIndex = filename.lastIndexOf(".");
    if (extIndex >= 0) {
      ext = filename.substring(extIndex + 1);
    }
  }

  return ext;
}

const FileModels = {
  txt: {
    type: "txt",
    icon: "doc",
    iconClass: "",
    aTarget: "_blank",
  },
  doc: {
    type: "doc",
    icon: "word",
    iconClass: "",
    aTarget: "_self",
  },
  docx: {
    type: "docx",
    icon: "word",
    iconClass: "",
    aTarget: "_self",
  },
  pdf: {
    type: "pdf",
    icon: "pdf",
    iconClass: "",
    aTarget: "_blank",
  },
  rtf: {
    type: "rtf",
    icon: "doc",
    iconClass: "",
    aTarget: "_blank",
  },
};

/**
 * 获取文件模型
 * @param {*} type
 */
function getFileModel(type) {
  const model = FileModels[type];
  if (model) {
    return model;
  }
  return FileModels["txt"];
}

/**
 * 获取文件图标
 * @param {*} type
 */
function getFileIcon(type) {
  let model = FileModels[type];
  if (!model) {
    model = FileModels["txt"];
  }
  return model.icon;
}

function isRTF(type) {
  return type === FileModels.rtf.type;
}

export default {
  getFileName,
  getFileExtension,
  FileModels,
  getFileModel,
  getFileIcon,
  isRTF,
};
