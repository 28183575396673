<!--
 * 参数：
 *   title
 *   visible
 *   selectorMode
 *   selectedIds
 *   disableIds
 *   excludeIds
 *   doctypes
 *   tableHeight
 *   tableMaxHeight
 * 事件：
 *   close()
 *   select({doc})
-->

<template>
  <el-dialog
    :visible="visible"
    :close-on-click-modal="false"
    :show-close="false"
    append-to-body
    class="ds-selector"
  >
    <div slot="title" class="wrs-flexrow--center">
      <span class="ds-selector-title">
        {{ title }}
      </span>
      <template v-if="isSearch">
        <div
          class="wrs-flexrow-center-center"
          style="flex: 1; padding: 0 40px 0 50px"
        >
          <el-input
            ref="searchInput"
            v-model="searchName"
            placeholder="搜索名称"
            :maxlength="40"
            size="medium"
          />
          <el-button size="medium" @click="onDoSearchClick">搜索</el-button>
        </div>
        <span class="ds-selector-tool back-routes" @click="onBackRoutesClick()">
          <span style="margin-left: 3px">返回浏览</span>
          <i class="el-icon-arrow-right"></i>
        </span>
      </template>
      <template v-else>
        <div class="wrs-flexrow-center-center" style="flex: 1; padding: 0 20px">
          <el-popover
            v-model="routePicker.show"
            placement="bottom-start"
            trigger="click"
            :visible-arrow="false"
            popper-class="ds-selector-route-picker"
          >
            <div>
              <div
                v-for="(item, index) in routes"
                :key="item.id"
                class="ds-selector-route-item"
                :class="{ current: index === routes.length - 1 }"
                @click="onRouteItemClick(item, index)"
              >
                <template v-if="item.id === 0">
                  <i class="ds-selector-dropdown-icon el-icon-files"></i>
                </template>
                <template v-else>
                  <svg-icon
                    svg-name="folder"
                    svg-class="ds-selector-dropdown-icon-dir"
                  />
                </template>
                <span class="wrs-ellipsis">
                  {{ item.name }}
                </span>
              </div>
              <template
                v-if="routes && routes.length > 0 && routes[0].id !== 0"
              >
                <el-divider
                  direction="horizontal"
                  class="ds-selector-dropdown-divider"
                />
                <div class="ds-selector-route-item" @click="onRouteDsClick()">
                  <i class="ds-selector-dropdown-icon el-icon-files"></i>
                  <span class="wrs-ellipsis">文档空间</span>
                </div>
              </template>
            </div>
            <span slot="reference" class="ds-selector-route-label">
              <template v-if="currDir && currDir.id === 0">
                <i class="ds-selector-dropdown-icon el-icon-files"></i>
              </template>
              <template v-else>
                <svg-icon
                  svg-name="folder"
                  svg-class="ds-selector-dropdown-icon-dir"
                />
              </template>
              <span class="wrs-ellipsis" style="flex: 1">
                {{ currDir ? currDir.name : "" }}
              </span>
              <i
                class="el-icon-caret-bottom"
                style="margin-left: 5px; font-size: 10px"
              ></i>
            </span>
          </el-popover>
        </div>
        <el-dropdown trigger="click" @command="onSortModeSelect">
          <el-tooltip content="排序" placement="top">
            <i class="ds-selector-tool icon el-icon-sort"></i>
          </el-tooltip>
          <el-dropdown-menu slot="dropdown">
            <el-dropdown-item
              v-for="item in sortModeList"
              :key="item.mode"
              :command="{ cmd: 0, value: item }"
            >
              <span
                :class="{ 'el-icon-check': currSortMode.mode === item.mode }"
                style="width: 20px; display: inline-block"
              ></span>
              {{ item.name }}
            </el-dropdown-item>
            <el-dropdown-item divided :command="{ cmd: 1 }">
              <span
                :class="{ 'el-icon-check': isDirTop }"
                style="width: 20px; display: inline-block"
              ></span>
              文件夹置顶
            </el-dropdown-item>
          </el-dropdown-menu>
        </el-dropdown>
        <el-tooltip content="新建文件夹" placement="top">
          <i
            class="ds-selector-tool icon el-icon-folder-add"
            @click="onCreateDirClick()"
          ></i>
        </el-tooltip>
        <el-tooltip content="文档空间" placement="top">
          <i
            class="ds-selector-tool icon el-icon-files"
            @click="onDsRootClick()"
          ></i>
        </el-tooltip>
        <el-divider direction="vertical" class="ds-selector-tool-vdivider" />
        <el-tooltip content="搜索" placement="top">
          <i
            class="ds-selector-tool icon el-icon-search"
            @click="onSearchClick()"
          ></i>
        </el-tooltip>
      </template>
    </div>
    <el-divider class="ds-selector-table-divider"></el-divider>
    <template v-if="!isSearch">
      <el-table
        :data="docs"
        :show-header="false"
        style="width: 100%"
        :height="tableHeight ? tableHeight : ''"
        :max-height="tableMaxHeight ? tableMaxHeight : ''"
        :header-cell-style="{ 'background-color': '#eff3f8' }"
        @row-click="onDocRowClick"
      >
        <el-table-column label="名称">
          <template slot-scope="scope">
            <span class="wrs-flexrow--center">
              <svg-icon
                :svg-name="scope.row.icon"
                :svg-class="
                  scope.row.dtype === 0
                    ? 'ds-selector-dir-icon'
                    : 'ds-selector-doc-icon'
                "
              />
              <span class="ds-selector-doc-name wrs-ellipsis">
                {{ scope.row.name }}
              </span>
            </span>
          </template>
        </el-table-column>
        <el-table-column width="80" align="center">
          <template slot-scope="scope">
            <i
              v-if="scope.row.selected"
              class="el-icon-check"
              style="color: #d9d9d9"
            ></i>
            <template
              v-else-if="
                (selectorMode === '0' && scope.row.dtype === 0) ||
                (selectorMode === '1' && scope.row.dtype === 1)
              "
            >
              <el-button
                v-if="!scope.row.disabled && !scope.row.excluded"
                class="ds-selector-doc-select"
                type="primary"
                size="medium"
                @click="onSelectDocClick(scope.row, scope.$index)"
              >
                选择
              </el-button>
            </template>
          </template>
        </el-table-column>
      </el-table>
      <div class="wrs-flexrow--center" style="padding-top: 8px">
        <el-pagination
          @current-change="onDocPageNumChange"
          :current-page="docPageNum"
          :page-size="docPageSize"
          :page-sizes="[10]"
          :layout="
            !isDirTop ? 'sizes, prev, next, jumper' : 'sizes, prev, next'
          "
          style="flex: 1"
        >
        </el-pagination>
        <el-button
          v-if="selectorMode === '0'"
          class="ds-selector-current-dir-select"
          :disabled="
            !(
              currDir &&
              !currDirSelected &&
              !currDirDisabled &&
              !currDirExcluded
            )
          "
          type="primary"
          size="medium"
          @click="onSelectCurrentDirClick()"
        >
          <span>选择[</span>
          <span class="wrs-ellipsis">{{ currDir ? currDir.name : "" }}</span>
          <span>]</span>
        </el-button>
        <el-button
          size="medium"
          class="ds-selector-cancel"
          @click="onCloseClick()"
        >
          取 消
        </el-button>
      </div>
    </template>
    <template v-else>
      <el-table
        :data="searchDocs"
        :show-header="false"
        style="width: 100%"
        :height="tableHeight ? tableHeight : ''"
        :max-height="tableMaxHeight ? tableMaxHeight : ''"
        :header-cell-style="{ 'background-color': '#eff3f8' }"
        @row-click="onSearchDocRowClick"
      >
        <el-table-column label="名称">
          <template slot-scope="scope">
            <span class="wrs-flexrow--center">
              <svg-icon
                :svg-name="scope.row.icon"
                :svg-class="
                  scope.row.dtype === 0
                    ? 'ds-selector-dir-icon'
                    : 'ds-selector-doc-icon'
                "
              />
              <span class="ds-selector-doc-name wrs-ellipsis">
                {{ scope.row.name }}
              </span>
            </span>
          </template>
        </el-table-column>
        <el-table-column width="80" align="center">
          <template slot-scope="scope">
            <i
              v-if="scope.row.selected"
              class="el-icon-check"
              style="color: #d9d9d9"
            ></i>
            <el-button
              v-else-if="!scope.row.disabled && !scope.row.excluded"
              class="ds-selector-doc-select"
              type="primary"
              size="medium"
              @click="onSelectSearchDocClick(scope.row, scope.$index)"
            >
              选择
            </el-button>
          </template>
        </el-table-column>
      </el-table>
      <div class="wrs-flexrow--center" style="padding-top: 8px">
        <el-pagination
          @current-change="onSearchPageNumChange"
          :current-page="searchPageNum"
          :page-size="searchPageSize"
          :page-sizes="[10]"
          layout="sizes, prev, next, jumper"
          style="flex: 1"
        >
        </el-pagination>
        <el-button
          size="medium"
          class="ds-selector-cancel"
          @click="onCloseClick()"
        >
          取 消
        </el-button>
      </div>
    </template>

    <el-dialog
      :visible="createDirDialog.show"
      :close-on-click-modal="false"
      :show-close="false"
      append-to-body
      title="新建文件夹"
      class="ds-selector-create-dir-dialog"
    >
      <div>
        <el-input
          v-model="createDirDialog.name"
          placeholder="请输入文件夹名称"
          :maxlength="60"
          size="medium"
        />
      </div>
      <div class="wrs-flexrow-end-center" style="padding: 20px 0 10px 0">
        <el-button
          class="ds-selector-create-dir-ok"
          :disabled="!createDirDialog.name"
          type="primary"
          size="medium"
          @click="onCreateDirOkClick()"
        >
          确 定
        </el-button>
        <el-button
          size="medium"
          class="ds-selector-create-dir-cancel"
          @click="closeCreateDirDialog()"
        >
          取 消
        </el-button>
      </div>
    </el-dialog>
  </el-dialog>
</template>

<script>
import moment from "moment";
import Ocean from "../../api/ocean";
import UI from "../../util/ui";
import FileUtil from "../../util/file";
import ViewContext from "../../viewContext";

const SortNewDocFirst = { mode: 1, name: "新文档优先" };
const SortOldDocFirst = { mode: 2, name: "旧文档优先" };

const SelectorMode = {
  /** 选择文件夹 */
  Dir: "0",

  /** 选择文档 */
  Doc: "1",
};

export default {
  data() {
    return {
      /** 是否搜索模式 */
      isSearch: false,

      /**
       * 当前文件夹路径列表
       * 顶层文件夹显示'文档空间'
       * 最后一个是当前文件夹
       *
       * 格式：[
       *   {
       *     id: 0, // 文件夹id，顶层文件夹是0
       *     name: '' // 文件夹名
       *   }
       * ]
       */
      routes: [],

      currDir: null,
      currDirSelected: false,
      currDirDisabled: false,
      currDirExcluded: false,

      /**
       * 文档列表
       *
       * 格式：[
       *   {
       *     id: 1,
       *     dtype: 0,
       *     name: '',
       *     doctype: '',
       *     rname: '',
       * ----------------------------
       *     icon: ''
       *     selected
       *     disabled
       *     excluded
       *   }
       * ]
       */
      docs: [],
      docPageNum: 1,

      /** 每页的数量 */
      docPageSize: 10,

      /** 排序方式列表 */
      sortModeList: [SortNewDocFirst, SortOldDocFirst],
      /** 当前排序方式 */
      currSortMode: SortNewDocFirst,

      /** 文件夹置顶 */
      isDirTop: false,

      /** 已获取全部文件夹 */
      isGotAllDirs: false,
      /** 文件夹数量 */
      dirNum: 0,

      searchName: "",

      /**
       * 搜索文档列表
       * 格式参考docs
       */
      searchDocs: [],
      searchPageNum: 1,

      /** 每页的数量 */
      searchPageSize: 10,

      routePicker: {
        show: false,
      },

      createDirDialog: {
        show: false,
        name: "",
      },
    };
  },
  props: {
    title: String,

    visible: {
      type: Boolean,
      default: false,
    },

    selectorMode: {
      type: [String, Number],
      default: SelectorMode.Dir,
    },

    /** 已选文件夹或文档的id列表，不允许选择；0表示顶层文件夹 */
    selectedIds: {
      type: Array,
      default: () => [],
    },

    /** 禁用文件夹或文档的id列表 */
    disableIds: {
      type: Array,
      default: () => [],
    },

    /** 排除文件夹或文档的id列表 */
    excludeIds: {
      type: Array,
      default: () => [],
    },

    /** 选择文档类型列表 */
    doctypes: {
      type: Array,
      default: () => [],
    },

    tableHeight: {
      type: [String, Number],
      default: Math.max(
        Math.min(
          Math.floor(document.documentElement.clientHeight * 0.7) - 120,
          700
        ),
        300
      ),
    },

    tableMaxHeight: {
      type: [String, Number],
      default: Math.max(
        Math.min(
          Math.floor(document.documentElement.clientHeight * 0.7) - 120,
          700
        ),
        300
      ),
    },

    /** 显示文件夹路径所需数量 */
    //showRoutesMinNum: 1,
  },
  watch: {
    visible(newVal /*, oldVal */) {
      if (newVal) {
        this.loadDocs();
      } else {
        this.clear();
      }
    },
    selectedIds(newVal /*, oldVal */) {
      if (this.currDir) {
        this.currDirSelected = newVal.includes(this.currDir.id);
      } else {
        this.currDirSelected = false;
      }

      if (this.docs) {
        for (let doc of this.docs) {
          doc.selected = newVal.includes(doc.id);
        }
      }

      if (this.searchDocs) {
        for (let srhDoc of this.searchDocs) {
          srhDoc.selected = newVal.includes(srhDoc.id);
        }
      }
    },
    disableIds(newVal /*, oldVal */) {
      if (this.currDir) {
        this.currDirDisabled = newVal.includes(this.currDir.id);
      } else {
        this.currDirDisabled = false;
      }

      if (this.docs) {
        for (let doc of this.docs) {
          doc.disabled = newVal.includes(doc.id);
        }
      }

      if (this.searchDocs) {
        for (let srhDoc of this.searchDocs) {
          srhDoc.disabled = newVal.includes(srhDoc.id);
        }
      }
    },
    excludeIds(newVal /*, oldVal */) {
      if (this.currDir) {
        this.currDirExcluded = newVal.includes(this.currDir.id);
      } else {
        this.currDirExcluded = false;
      }

      if (this.docs) {
        for (let doc of this.docs) {
          doc.excluded = newVal.includes(doc.id);
        }
      }

      if (this.searchDocs) {
        for (let srhDoc of this.searchDocs) {
          srhDoc.excluded = newVal.includes(srhDoc.id);
        }
      }
    },
  },
  mounted() {
    this.routes = this.getDefaultRoutes();
    this.setCurrentDir(this.routes[this.routes.length - 1]);
  },
  methods: {
    getDefaultRoutes() {
      let defRoutes = [
        {
          id: 0,
          name: "文档空间",
        },
      ];

      return defRoutes;
    },

    setCurrentDir(currDir) {
      this.currDir = currDir;
      if (this.currDir) {
        this.currDirSelected = this.selectedIds.includes(this.currDir.id);
        this.currDirDisabled = this.disableIds.includes(this.currDir.id);
        this.currDirExcluded = this.excludeIds.includes(this.currDir.id);
      } else {
        this.currDirSelected = false;
        this.currDirDisabled = false;
        this.currDirExcluded = false;
      }
    },

    clear() {
      this.isSearch = false;

      this.routes = this.getDefaultRoutes();
      this.setCurrentDir(this.routes[this.routes.length - 1]);

      this.docPageNum = 1;
      this.clearDocs();

      this.searchName = "";

      this.searchDocs = [];
      this.searchPageNum = 1;

      this.routePicker.show = false;

      this.createDirDialog.show = false;
      this.createDirDialog.name = "";
    },

    clearDocs() {
      this.docs = [];
      this.isGotAllDirs = false;
      this.dirNum = 0;
    },

    clearDirsInfo() {
      this.isGotAllDirs = false;
      this.dirNum = 0;
    },

    onClose() {
      this.$emit("close");
    },

    onSelect(doc) {
      this.$emit("select", {
        doc,
      });
    },

    onCloseClick() {
      this.onClose();
    },

    /**
     * 加载文档列表
     * @param {Object} param0
     *   {
     *     onSuccess: () => {}
     *     onError: () => {}
     *   }
     */
    loadDocs(param0 = {}) {
      if (this.selectorMode == SelectorMode.Dir) {
        param0.dtype = 0;
        this.getDocs(param0);
      } else {
        if (this.isDirTop) {
          if (this.isGotAllDirs) {
            param0.offset =
              UI.computePagingOffset(this.docPageNum, this.docPageSize) -
              this.dirNum;
            this.getDocsForDirTop(param0);
          } else {
            this.getDirsForTop({
              onSuccess: (newDirs) => {
                if (this.isGotAllDirs && newDirs.length < this.docPageSize) {
                  this.getDocsForDirTop({
                    current: this.docs,
                    offset: 0,
                    num: this.docPageSize - newDirs.length,
                    onSuccess: param0.onSuccess,
                    onError: param0.onError,
                  });
                } else if (param0.onSuccess) {
                  param0.onSuccess();
                }
              },
              onError: param0.onError,
            });
          }
        } else {
          this.getDocs(param0);
        }
      }
    },

    /**
     * 重新加载文档列表
     * @param {Object} param0
     *   {
     *     onSuccess: () => {}
     *     onError: () => {}
     *   }
     */
    reloadDocs(param0 = {}) {
      this.docPageNum = 1;
      this.clearDocs();

      this.loadDocs(param0);
    },

    /**
     * 获取文档列表
     * @param {Object} param0
     *   {
     *     current
     *     dtype: 0, // 获取类型
     *
     *     onSuccess: (newDocs) => {}
     *     onError: (err) => {}
     *   }
     */
    getDocs(param0 = {}) {
      const team = ViewContext.getTeam();
      if (!team) return;

      let docs = param0.current ? param0.current : [];
      let paramsForList = {
        team_id: team.id,
        fields: ["id", "dtype", "name", "doctype", "rname"],
        sort_by: this.currSortMode.mode,
        offset: UI.computePagingOffset(this.docPageNum, this.docPageSize),
        num: this.docPageSize,
      };

      if (this.routes && this.routes.length > 0) {
        let parent = this.routes[this.routes.length - 1];
        paramsForList.parent_id = parent.id;
      }

      if (Number.isInteger(param0.dtype)) {
        paramsForList.dtype = param0.dtype;
      }

      if (
        paramsForList.dtype !== 0 &&
        this.doctypes &&
        this.doctypes.length > 0
      ) {
        paramsForList.doctypes = [...this.doctypes];
      }

      Ocean.listTeamDsDoc(paramsForList)
        .then((res) => {
          let rdocs = res.data.docs;

          if (rdocs) {
            for (let doc of rdocs) {
              this.makeDocForView(doc);
              docs.push(doc);
            }
          }

          this.docs = docs;

          if (param0.onSuccess) {
            rdocs = rdocs ? rdocs : [];
            param0.onSuccess(rdocs);
          }
        })
        .catch((err) => {
          if (param0.onError) {
            param0.onError(err);
          }
          this.$message.error(err.data.msg);
        });
    },

    /**
     * 获取文件夹列表（文件夹置顶）
     * @param {Object} param0
     *   {
     *     current
     *     onSuccess: (newDirs) => {}
     *     onError: (err) => {}
     *   }
     */
    getDirsForTop(param0 = {}) {
      const team = ViewContext.getTeam();
      if (!team) return;

      let docs = param0.current ? param0.current : [];
      let paramsForList = {
        team_id: team.id,
        dtype: 0,
        fields: ["id", "dtype", "name", "doctype", "rname"],
        sort_by: this.currSortMode.mode,
        offset: UI.computePagingOffset(this.docPageNum, this.docPageSize),
        num: this.docPageSize,
      };

      if (this.routes && this.routes.length > 0) {
        let parent = this.routes[this.routes.length - 1];
        paramsForList.parent_id = parent.id;
      }

      Ocean.listTeamDsDoc(paramsForList)
        .then((res) => {
          let rdocs = res.data.docs ? res.data.docs : [];

          for (let doc of rdocs) {
            this.makeDocForView(doc);
            docs.push(doc);
          }

          this.docs = docs;
          this.dirNum += rdocs.length;
          this.isGotAllDirs = rdocs.length < paramsForList.num;

          if (param0.onSuccess) {
            param0.onSuccess(rdocs);
          }
        })
        .catch((err) => {
          if (param0.onError) {
            param0.onError(err);
          }
          this.$message.error(err.data.msg);
        });
    },

    /**
     * 获取文档列表（文件夹置顶）
     * @param {Object} param0
     *   {
     *     current
     *     offset
     *     num
     *     onSuccess: (newDocs) => {}
     *     onError: (err) => {}
     *   }
     */
    getDocsForDirTop(param0 = {}) {
      const team = ViewContext.getTeam();
      if (!team) return;

      let docs = param0.current ? param0.current : [];
      let offset = param0.offset && param0.offset >= 0 ? param0.offset : 0;
      let num = param0.num && param0.num > 0 ? param0.num : this.docPageSize;
      let paramsForList = {
        team_id: team.id,
        dtype: 1,
        fields: ["id", "dtype", "name", "doctype", "rname"],
        sort_by: this.currSortMode.mode,
        offset,
        num,
      };

      if (this.routes && this.routes.length > 0) {
        let parent = this.routes[this.routes.length - 1];
        paramsForList.parent_id = parent.id;
      }

      if (this.doctypes && this.doctypes.length > 0) {
        paramsForList.doctypes = [...this.doctypes];
      }

      Ocean.listTeamDsDoc(paramsForList)
        .then((res) => {
          let rdocs = res.data.docs;

          if (rdocs) {
            for (let doc of rdocs) {
              this.makeDocForView(doc);
              docs.push(doc);
            }
          }

          this.docs = docs;

          if (param0.onSuccess) {
            rdocs = rdocs ? rdocs : [];
            param0.onSuccess(rdocs);
          }
        })
        .catch((err) => {
          if (param0.onError) {
            param0.onError(err);
          }
          this.$message.error(err.data.msg);
        });
    },

    reloadSearchDocs() {
      this.searchPageNum = 1;
      this.getSearchDocs();
    },

    /**
     * 获取搜索文档列表
     * @param {Object} param0
     *   {
     *     current
     *     dtype: 0, // 获取类型
     *
     *     onSuccess: (newDocs) => {}
     *     onError: (err) => {}
     *   }
     */
    getSearchDocs(param0 = {}) {
      const team = ViewContext.getTeam();
      if (!team) return;

      let docs = param0.current ? param0.current : [];
      let paramsForList = {
        team_id: team.id,
        fields: ["id", "dtype", "name", "doctype", "rname"],
        sort_by: SortNewDocFirst.mode,
        offset: UI.computePagingOffset(this.searchPageNum, this.searchPageSize),
        num: this.searchPageSize,
      };

      if (this.searchName) {
        paramsForList.name = this.searchName;
      }

      if (Number.isInteger(param0.dtype)) {
        paramsForList.dtype = param0.dtype;
      }

      if (
        paramsForList.dtype !== 0 &&
        this.doctypes &&
        this.doctypes.length > 0
      ) {
        paramsForList.doctypes = [...this.doctypes];
      }

      Ocean.listTeamDsDoc(paramsForList)
        .then((res) => {
          let rdocs = res.data.docs;

          if (rdocs) {
            for (let doc of rdocs) {
              this.makeDocForView(doc);
              docs.push(doc);
            }
          }

          this.searchDocs = docs;

          if (param0.onSuccess) {
            rdocs = rdocs ? rdocs : [];
            param0.onSuccess(rdocs);
          }
        })
        .catch((err) => {
          if (param0.onError) {
            param0.onError(err);
          }
          this.$message.error(err.data.msg);
        });
    },

    makeDocForView: async function (doc) {
      if (doc) {
        doc.selected = this.selectedIds.includes(doc.id);
        doc.disabled = this.disableIds.includes(doc.id);
        doc.excluded = this.excludeIds.includes(doc.id);

        if (doc.dtype === 0) {
          doc.icon = "folder";
        } else {
          const fileModel = FileUtil.getFileModel(doc.doctype);
          doc.icon = fileModel.icon;
        }
      }
    },

    /**
     * 添加文档
     * @param {Object} doc
     * @param {*} onSuccess: (doc) => {}
     * @param {*} onError: (err) => {}
     */
    addDoc(doc, onSuccess, onError) {
      Ocean.addTeamDsDoc(doc)
        .then((res) => {
          doc.id = res.data.id;

          if (onSuccess) {
            onSuccess(doc);
          }
        })
        .catch((err) => {
          if (onError) {
            onError(err);
          }
          this.$message.error(err.data.msg);
        });
    },

    onDoSearchClick() {
      this.reloadSearchDocs();
    },

    onBackRoutesClick() {
      this.isSearch = false;
    },

    onRouteItemClick(route, index) {
      // 如果当前文件夹没变，刷新数据

      if (index < this.routes.length - 1) {
        this.routes.splice(index + 1);
        this.setCurrentDir(this.routes[this.routes.length - 1]);
      }

      this.reloadDocs();

      this.routePicker.show = false;
    },

    onRouteDsClick() {
      this.routePicker.show = false;
      this.openDsRoot();
    },

    onSortModeSelect(command) {
      if (command.cmd === 0) {
        // 排序方式
        this.currSortMode = command.value;
        this.reloadDocs();
      } else if (command.cmd === 1) {
        // 文件夹置顶
        this.isDirTop = !this.isDirTop;
        this.reloadDocs();
      }
    },

    onCreateDirClick() {
      this.createDirDialog.show = true;
    },

    openDsRoot() {
      this.routes = this.getDefaultRoutes();
      this.setCurrentDir(this.routes[this.routes.length - 1]);

      this.reloadDocs();
    },

    onDsRootClick() {
      this.openDsRoot();
    },

    onSearchClick() {
      this.isSearch = true;
    },

    /**
     * 点击<文件夹>/<文档>
     */
    onDocRowClick(row /*, column, event */) {
      if (row.dtype === 0) {
        this.routes.push(row);
        this.setCurrentDir(this.routes[this.routes.length - 1]);

        if (this.isSearch) {
          this.isSearch = false;
        }

        this.reloadDocs();
      }
    },

    onSelectDocClick(row /*, index */) {
      this.onSelect(row);
    },

    onDocPageNumChange(pageNum) {
      let oldPageNum = this.docPageNum;
      this.docPageNum = pageNum;

      if (this.isDirTop && pageNum <= oldPageNum) {
        if (this.isGotAllDirs) {
          if (
            UI.computePagingOffset(this.docPageNum, this.docPageSize) <=
            this.dirNum
          ) {
            this.isGotAllDirs = false;
            this.dirNum = UI.computePagingOffset(
              this.docPageNum,
              this.docPageSize
            );
          }
        } else {
          this.dirNum = UI.computePagingOffset(
            this.docPageNum,
            this.docPageSize
          );
        }
      }

      this.loadDocs();
    },

    onSelectCurrentDirClick() {
      if (
        this.currDir &&
        !this.currDirSelected &&
        !this.currDirDisabled &&
        !this.currDirExcluded
      ) {
        this.onSelect(this.currDir);
      }
    },

    onSearchDocRowClick(row /*, column, event */) {
      if (row.dtype === 0) {
        this.routes = [row];
        this.setCurrentDir(this.routes[this.routes.length - 1]);

        if (this.isSearch) {
          this.isSearch = false;
        }

        this.reloadDocs();
      }
    },

    onSelectSearchDocClick(row /*, index */) {
      this.onSelect(row);
    },

    onSearchPageNumChange(pageNum) {
      this.searchPageNum = pageNum;
      this.getSearchDocs();
    },

    closeCreateDirDialog() {
      this.createDirDialog.show = false;
    },

    onCreateDirOkClick() {
      if (!this.createDirDialog.name) {
        this.$message.error("请输入文件夹名称");
        return;
      }

      const team = ViewContext.getTeam();
      if (!team || !this.currDir) return;

      let doc = {
        team_id: team.id,
        parent_id: this.currDir.id,
        dtype: 0,
        name: this.createDirDialog.name,
      };

      this.addDoc(doc, () => {
        doc.create_time = moment().format("YYYY-MM-DD HH:mm:ss");
        doc.update_time = doc.create_time;
        this.makeDocForView(doc);

        if (this.currSortMode.mode === SortNewDocFirst.mode) {
          this.docs.splice(0, 0, doc);
        } else if (this.currSortMode.mode === SortOldDocFirst.mode) {
          this.docs.push(doc);
        }

        // 不计入<文件夹数量>

        this.closeCreateDirDialog();
      });
    },
  },
};
</script>

<style lang="scss">
.ds-selector .el-dialog {
  width: 800px;
  // min-width: 800px;
  // max-width: 1000px;
}

.ds-selector .el-dialog__header {
  padding: 10px 20px 0 20px;
}

.ds-selector .el-dialog__body {
  padding-top: 10px;
  padding-bottom: 10px;
}

.ds-selector-route-picker.el-popover {
  margin-top: 2px;
  padding: 0;
}

.ds-selector-tool-vdivider.el-divider--vertical {
  height: 20px;
  margin: 0 12px 0 12px;
}

.ds-selector-dropdown-divider.el-divider--horizontal {
  margin: 0 0;
  background-color: #ebeef5;
}

.ds-selector-table-divider.el-divider--horizontal {
  margin: 0 0;
  background-color: #ebeef5;
}

.ds-selector-create-dir-dialog .el-dialog {
  margin-top: 30vh !important;
  padding: 0 10px;
  width: 600px;
}
.ds-selector-create-dir-dialog .el-dialog__body {
  padding-top: 10px;
  padding-bottom: 10px;
}
</style>

<style scoped lang="scss">
.ds-selector-title {
  line-height: 24px;
  font-size: 18px;
  color: #303133;
}

.ds-selector-route-label {
  width: 300px;
  // height: 32px;
  // line-height: 24px;
  padding: 5px 10px;
  box-sizing: border-box;
  // font-size: 12px;
  display: inline-flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  -moz-box-align: center;
  border-width: 1px;
  border-style: solid;
  border-color: rgba(65, 70, 75, 0.1);
  background: #ffffff;
  box-shadow: inset 0 1px 3px #e5e5e5;
  border-radius: 2px;
  outline: currentcolor none medium;
  cursor: pointer;
}

.ds-selector-route-item {
  min-width: 300px;
  max-width: 400px;
  width: 200px;
  padding: 8px 10px;
  box-sizing: border-box;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  cursor: pointer;
}
.ds-selector-route-item.current {
  color: #d9d9d9;
}
.ds-selector-route-item:hover {
  background-color: #fafafa;
}

.ds-selector-tool {
  padding: 6px 8px;
  border-radius: 4px;
  cursor: pointer;
  flex-shrink: 0;
}
.ds-selector-tool:hover {
  background-color: #fafafa;
}

.ds-selector-tool.icon,
.ds-selector-tool .icon {
  font-size: 16px;
}

.ds-selector-tool.txt,
.ds-selector-tool .txt {
  font-size: 14px;
}

.ds-selector-tool.back-routes {
  margin-right: -8px;
  font-size: 14px;
}

.ds-selector-dropdown-icon {
  margin-right: 7px;
  width: 14px;
  height: 14px;
}
.ds-selector-dropdown-icon-dir {
  margin-left: -2px;
  margin-right: 5px;
  width: 18px;
  height: 18px;
}

.ds-selector-doc-name {
  padding: 4px 10px;
  cursor: pointer;
}
.ds-selector-doc-name:hover {
  text-decoration: underline;
}

.ds-selector-dir-icon,
.ds-selector-doc-icon {
  display: inline-block;
  border-radius: 3px;
  flex-shrink: 0;
}
.ds-selector-dir-icon {
  width: 34px;
  height: 34px;
}
.ds-selector-doc-icon {
  width: 26px;
  height: 26px;
  padding: 4px 4px;
}

.ds-selector-doc-select {
  padding: 6px 12px;
  border-radius: 3px;
  flex-shrink: 0;
}

.ds-selector-current-dir-select {
  margin-left: 10px;
  max-width: 150px;
  padding: 8px 16px;
}

.ds-selector-create-dir-cancel,
.ds-selector-cancel {
  padding: 8px 16px;
  outline: currentcolor none medium;
}

.ds-selector-create-dir-ok {
  padding: 8px 16px;
  outline: currentcolor none medium;
}
</style>
