/**
 * 顾问号
 * {
 *   id:1, // 顾问号id
 *   name: '', // 顾问号名称
 * }
 */
var team = null;

var memberId = 0;

function getTeam() {
  return team;
}

function setTeam(vTeam) {
  team = vTeam;
}

function getMemberId() {
  return memberId;
}

function setMemberId(id) {
  memberId = id;
}

export default {
  getTeam,
  setTeam,
  getMemberId,
  setMemberId,
};
