import Vue from "vue";
import VueRouter from "vue-router";
import Icoffice from "../icoffice";
import Env from "../env";

const originalPush = VueRouter.prototype.push;
VueRouter.prototype.push = function push(location) {
  return originalPush.call(this, location).catch((err) => err);
};

const originalReplace = VueRouter.prototype.replace;
VueRouter.prototype.replace = function replace(location) {
  return originalReplace.call(this, location).catch((err) => err);
};

Vue.use(VueRouter);

const routes = [
  {
    path: "/",
    redirect: "/coa/intro",
  },
  {
    path: "/login",
    name: "login",
    component: () => import("../views/LoginView.vue"),
  },
  {
    path: "/coa",
    component: () => import("../views/CoaWorkbench.vue"),
    children: [
      {
        path: "",
        // alias: ["intro"],
        redirect: "/coa/intro",
      },
      {
        path: "intro",
        component: () => import("../views/coa/IntroductionView.vue"),
      },
      {
        path: "bsorders",
        name: "bsorders",
        component: () => import("../views/coa/BsOrdersList.vue"),
        meta: {
          keepAlive: true, // 缓存
        },
      },
      {
        path: "docspace",
        component: () => import("../views/coa/DocSpace.vue"),
      },
      {
        path: "console/bservicemgr",
        component: () => import("../views/coa/admin/BServicesList.vue"),
      },
      // {
      //   path: "console/irtemplates",
      //   component: () =>
      //     import("../views/coa/admin/ImageReportTemplatesList.vue"),
      // },
    ],
  },
  {
    path: "/coa/bsdesc",
    name: "bsdesc",
    component: () => import("../views/coa/BsDescription.vue"),
  },
  {
    path: "/coa/bsfimgreport",
    name: "bsfimgreport",
    component: () => import("../views/coa/BsfImageReport.vue"),
  },
  {
    path: "/coa/bsimagereport",
    name: "bsimagereport",
    component: () => import("../views/coa/BsImageReport.vue"),
  },
  // {
  //   path: "/coa/bsimagereportmpl",
  //   name: "bsimagereportmpl",
  //   component: () => import("../views/coa/BsImageReportTemplate.vue"),
  // },
  {
    path: "/coa/dsrtf",
    name: "dsrtf",
    component: () => import("../views/coa/DocSpaceRtf.vue"),
  },
  {
    path: "/manual",
    component: () => import("../views/manual/ManualContainer.vue"),
    children: [
      {
        path: "",
        redirect: "/manual/ce/introduction",
      },
      {
        path: "ce/introduction",
        component: () =>
          import("../views/manual/consultant-edition/IntroductionView.vue"),
      },
    ],
  },
  {
    path: "/m/manual",
    component: () => import("../views/mobile/manual/ManualContainer_m.vue"),
    children: [
      {
        path: "",
        redirect: "/m/manual/ce/introduction",
      },
      {
        path: "ce/introduction",
        component: () =>
          import(
            "../views/mobile/manual/consultant-edition/IntroductionView_m.vue"
          ),
      },
    ],
  },
];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes,
});

router.beforeEach(async (to, from, next) => {
  // 如果是 manual，适配PC/移动端
  if (to.path.indexOf("/manual") !== -1) {
    if (Env.isMobileStyle()) {
      if (to.path.indexOf("/m/") === -1) {
        next({
          path: to.path.replace("/manual", "/m/manual"),
        });
      } else {
        next();
      }
    } else {
      if (to.path.indexOf("/m/") !== -1) {
        next({
          path: to.path.replace("/m/", "/"),
        });
      } else {
        next();
      }
    }

    return;
  }

  if (to.name !== "login" && !(await Icoffice.Authenticate())) {
    next({ name: "login" });
  } else {
    next();
  }
});

export default router;
