<template>
  <div class="container">
    <div>
      <div id="intro" class="title">惟若顾问版介绍</div>
      <div class="desc">
        <div>
          惟若是个人形象服务平台，以互联网、技术让用户发现与享受时尚，赋能形象顾问。
        </div>
        <div>
          APP/软件有惟若（微信小程序）、惟若顾问版（微信小程序）、网页版（https://www.wiruo.com），网页版目前提供顾问版功能。
        </div>
        <div>
          惟若顾问版是顾问及团队协同办公与形象服务的软件，助力顾问提升工作效率，提供更好的个人形象设计等服务。形象顾问可以是个人形象设计师、服装搭配师等。
        </div>
      </div>
      <div>
        <div id="advantage" class="module1-title" style="margin-top: 20px">
          产品优势
        </div>
        <div>
          <div class="advantage-item">
            <!--
            <div class="seq">
              <div class="seq-num">1</div>
            </div>
            -->
            <div class="adv-brief">找形象顾问更简单</div>
            <div class="adv-description">
              惟若是垂直平台，只入驻个人形象顾问，用户登录即可发现和联系顾问，支持通过地区、服务与品质、价格等方式找到合意的顾问。
            </div>
          </div>
          <div class="advantage-item">
            <!--
            <div class="seq">
              <div class="seq-num">2</div>
            </div>
            -->
            <div class="adv-brief">汇聚精准客户</div>
            <div class="adv-description">
              汇聚想要改善或提高个人外在形象，追求时尚的用户，带来更多更精准的客户流量
            </div>
          </div>
          <div class="advantage-item">
            <!--
            <div class="seq">
              <div class="seq-num">3</div>
            </div>
            -->
            <div class="adv-brief">赋能个人形象服务</div>
            <div class="adv-description">
              提供满足个人形象服务所需的功能，助力顾问提升服务品质和效率，降低成本
            </div>
          </div>
          <div class="advantage-item">
            <!--
            <div class="seq">
              <div class="seq-num">4</div>
            </div>
            -->
            <div class="adv-brief">协同办公</div>
            <div class="adv-description">
              支持顾问团队成员一起登录惟若，协同办公和提供个人形象服务
            </div>
          </div>
          <div class="advantage-item">
            <!--
            <div class="seq">
              <div class="seq-num">5</div>
            </div>
            -->
            <div class="adv-brief">行业化客户管理</div>
            <div class="adv-description">
              提供更适合个人形象服务和行业特点的客户管理，提高服务效率和客户体验
            </div>
          </div>
        </div>
      </div>
      <div>
        <div id="ability" class="module1-title" style="margin-top: 20px">
          产品功能
        </div>
        <div>
          <div id="abl-gwh" class="ability-item">
            <div class="abl-intro">
              <div class="abl-left">
                <div class="abl-title">顾问号</div>
                <div class="abl-brief">
                  顾问号是顾问及团队办公与提供个人形象服务的平台，可以在惟若顾问版上创建。
                </div>
              </div>
            </div>
            <div>
              <div class="ability-col">
                <div class="ac-title">团队管理</div>
                <div class="ac-desc">
                  顾问号可以邀请（或接受主动加入）其他成员，管理组织架构。
                </div>
              </div>
              <div class="ability-col">
                <div class="ac-title">协同办公</div>
                <div class="ac-desc">
                  所有成员可以一起登录惟若，在顾问号上协同办公和提供个人形象服务。
                </div>
              </div>
            </div>
          </div>
          <div id="abl-ds" class="ability-item">
            <div class="abl-intro">
              <div class="abl-left">
                <div class="abl-title">文档空间</div>
                <div class="abl-brief">
                  网页版和微信小程序都已经上线“文档空间”功能，可以新建、上传和管理文档，目前支持上传pdf、doc、docx格式的文档。
                </div>
              </div>
            </div>
            <div>
              <div class="ability-col">
                <div class="ac-title">文档分享</div>
                <div class="ac-desc">
                  顾问号可以把文档分享给客户，目前仅能分享到微信朋友。
                </div>
              </div>
              <div class="ability-col">
                <div class="ac-title">给分享设置权限</div>
                <div class="ac-desc">
                  文档分享前后，可以设置权限：只能阅读、可以编辑、禁止访问。
                </div>
              </div>
              <div class="ability-col">
                <div class="ac-title">在线文档编辑</div>
                <div class="ac-desc">
                  网页版和微信小程序都提供了多功能文档编辑器。（见下图）
                </div>
              </div>
            </div>
            <div>
              <el-image
                :src="rtfEditor1Path"
                :preview-src-list="[rtfEditor1Path]"
                class="img-f"
                style="margin-top: 20px"
                fit="contain"
                lazy
              >
              </el-image>
              <div class="img-f-note">文档编辑器（网页版）</div>
            </div>
          </div>
          <div id="abl-service" class="ability-item">
            <div class="abl-intro">
              <div class="abl-left">
                <div class="abl-title">服务产品和服务项目</div>
                <div class="abl-brief">
                  服务产品是顾问号向用户提供的个人形象服务，可以在惟若上浏览和下单购买。
                </div>
              </div>
            </div>
            <div>
              <div class="ability-col">
                <div class="ac-title">服务项目</div>
                <div class="ac-desc">
                  <div>
                    服务产品包含个人形象服务所需的服务项目，创建服务产品时需选择1个或多个服务项目。
                  </div>
                  <div>
                    目前服务项目包括形象报告、单品、搭配、咨询，可以按需任意组合。客户下单后，订单详情中显示了服务产品的服务项目。
                  </div>
                </div>
              </div>
              <div class="ability-col">
                <div class="ac-title">形象报告</div>
                <div class="ac-desc">
                  <div>
                    该服务项目用于给客户提供形象报告，可以新建、上传或从文档空间创建，目前支持上传pdf、doc、docx格式的文档。
                  </div>
                  <div>
                    新建或从文档空间创建的报告，可以设置客户是否可以编辑。
                  </div>
                </div>
              </div>
              <div class="ability-col">
                <div class="ac-title">单品</div>
                <div class="ac-desc">
                  <div>
                    该服务项目用于给客户提供服饰单品。在单品详情中，可以查看该单品的所有搭配。
                  </div>
                </div>
              </div>
              <div class="ability-col">
                <div class="ac-title">搭配</div>
                <div class="ac-desc">
                  <div>该服务项目用于给客户提供服饰搭配。</div>
                </div>
              </div>
              <div class="ability-col">
                <div class="ac-title">咨询</div>
                <div class="ac-desc">
                  <div>
                    服务产品包含咨询功能时，客户可以按订单选择的规格向顾问发布咨询。
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div id="abl-clothing" class="ability-item">
            <div class="abl-intro">
              <div class="abl-left">
                <div class="abl-title">服饰库（规划中）</div>
                <div class="abl-brief">
                  <div>
                    目的是辅助顾问收录、筛选和管理服饰，在个人形象服务过程中应用这些服饰，提高服务效率和品质，降低工作成本。
                  </div>
                  <div>
                    诚邀您加入我们的形象顾问客服群，提出意见和建议。（进群方法见最后）
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div id="abl-cm" class="ability-item">
            <div class="abl-intro">
              <div class="abl-left">
                <div class="abl-title">客户管理</div>
                <div class="abl-brief">
                  惟若提供更适合行业的客户管理，能更好地满足个人形象服务和行业特点所需，提高服务效率和客户体验。
                </div>
              </div>
            </div>
            <div>
              <div class="ability-col">
                <div class="ac-title">专属文档空间</div>
                <div class="ac-desc">
                  可以在文档空间中给客户建立专属文档空间。
                </div>
              </div>
              <div class="ability-col">
                <div class="ac-title">用户绑定客户资料</div>
                <div class="ac-desc">
                  顾问号可以给用户或者买家绑定自己的客户资料。绑定客户资料后，订单、咨询（服务项目）、消息等页面中，用户昵称都会显示为客户名，用以提高办公和形象服务效率。
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div>
        <div id="feedback" class="module1-title">建议与反馈</div>
        <div style="padding: 12px 16px 0 16px">
          诚邀您加入顾问客服群，提出意见和建议。
        </div>
        <div class="icwg-qr-wrap">
          <el-image
            :src="imageConsultantWxGrpQrPath"
            :preview-src-list="[imageConsultantWxGrpQrPath]"
            class="icwg-qr"
            fit="contain"
            lazy
          >
          </el-image>
        </div>
        <div class="img-f-note">形象顾问客服群</div>
      </div>
    </div>
    <div class="toc-btn" @click="tocVisible = true">目录</div>
    <el-drawer
      :visible.sync="tocVisible"
      direction="rtl"
      size="50%"
      :show-close="false"
      :with-header="false"
      append-to-body
    >
      <!-- table of contents -->
      <div class="toc-list">
        <a href="#intro" class="toc-l1">介绍</a>
        <a href="#advantage" class="toc-l1">产品优势</a>
        <a href="#ability" class="toc-l1">产品功能</a>
        <a href="#abl-gwh" class="toc-l2">顾问号</a>
        <a href="#abl-ds" class="toc-l2">文档空间</a>
        <a href="#abl-service" class="toc-l2">服务产品和服务项目</a>
        <a href="#abl-clothing" class="toc-l2">服饰库（规划中）</a>
        <a href="#abl-cm" class="toc-l2">客户管理</a>
        <a href="#feedback" class="toc-l1">建议与反馈</a>
      </div>
    </el-drawer>
  </div>
</template>

<script>
export default {
  data() {
    return {
      tocVisible: false,
      rtfEditor1Path: require("@/assets/img/manual/rtf-editor-1.png"),
      imageConsultantWxGrpQrPath: require("@/assets/img/manual/image-consultant-wechat-group-qr.png"),
    };
  },
};
</script>

<style scoped lang="scss">
.container {
  padding-bottom: 120px;
}

.img-f {
  width: 100%;
  display: inline-block;
}

.img-f-note {
  margin-top: 5px;
  text-align: center;
  color: #bdbdbd;
}

.title {
  padding: 34px 16px 14px 16px;
  font-size: 30px;
  color: #181818;
  line-height: 1;
  font-weight: 600;
}

.desc {
  padding: 0 16px;
  font-size: 14px;
  line-height: 24px;
  color: #3d3d3d;
}

.module1-title {
  padding: 14px 16px;
  font-size: 20px;
  // color: rgb(24, 24, 24);
  color: #ffffff;
  line-height: 1;
  background-color: #4d4d4d;
}

.advantage-item {
  padding: 10px 16px;
  box-sizing: border-box;
}
.advantage-item .seq {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}
.advantage-item .seq-num {
  width: 44px;
  height: 44px;
  border-radius: 50%;
  font-size: 24px;
  color: #4d4d4d;
  background-color: #dcdcdc;
  flex-shrink: 0;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}
.advantage-item .adv-brief {
  height: 24px;
  line-height: 24px;
  font-size: 18px;
  color: #181818;
  font-weight: 700;
  text-align: center;
  overflow: hidden;
  margin-top: 18px;
  margin-bottom: 8px;
}
.advantage-item .adv-description {
  line-height: 24px;
  font-size: 14px;
  color: #999;
  text-align: center;
  opacity: 0.8;
  overflow: hidden;
}

.ability-item {
  padding: 40px 16px;
  border-bottom: 1px solid #ebecec;
}
.ability-item:last-child {
  border-bottom: 0;
}

.abl-title {
  font-size: 20px;
  font-weight: 700;
  color: #373d41;
  line-height: 36px;
}
.abl-brief {
  font-size: 14px;
  color: #373d41;
  line-height: 24px;
  margin-top: 2px;
}

.ability-col {
  // float: left;
  // width: 33.33333%;
  box-sizing: border-box;
  // padding: 0 10px;
  padding-left: 10px;
  margin-top: 16px;
  border-left: 2px solid #ff6a00;
}
.ability-col .ac-title {
  font-size: 14px;
  font-weight: 700;
  color: #373d41;
  line-height: 20px;
}
.ability-col .ac-desc {
  margin-top: 12px;
  font-size: 14px;
  color: #373d41;
  line-height: 21px;
}

.icwg-qr-wrap {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}
.icwg-qr {
  margin-top: 16px;
  max-width: 50%;
  height: 200px;
  display: inline-block;
}

.toc-btn {
  position: fixed;
  bottom: calc(50px + env(safe-area-inset-bottom));
  right: 24px;
  width: 44px;
  height: 44px;
  font-weight: 600;
  border-radius: 50%;
  border: 1px solid #bdbdbd;
  background: rgba(255, 255, 255, 0.75);
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}

.toc-list {
  padding-top: 10px;
}

.toc-l1 {
  margin: 10px 0;
  padding: 0 20px;
  display: block;
  font-size: 16px;
  color: #181818;
  font-weight: 600;
  text-decoration: none;
}
.toc-l2 {
  margin: 8px 0;
  padding: 0 20px 0 40px;
  display: block;
  font-size: 15px;
  color: #4d4d4d;
  text-decoration: none;
}
</style>
