<template>
  <div class="container">
    <h2 class="title">简介</h2>
    <el-tabs class="tabs" v-model="activeTabName" @tab-click="onTabClick">
      <el-tab-pane label="基本信息" name="0" class="tab-pane">
        <div v-if="team">
          <div class="logo-cell">
            <el-image
              class="logo-img"
              :src="team.headimgThumb"
              fit="cover"
            ></el-image>
          </div>
          <div class="cell">
            <span class="cell-label">名称</span>
            <span class="cell-content">{{ team.name }}</span>
          </div>
          <div class="cell">
            <span class="cell-label">简介</span>
            <span class="cell-content">{{ team.introduce }}</span>
          </div>
          <div class="cell">
            <span class="cell-label">地址</span>
            <span class="cell-content">{{ team.addr }}</span>
          </div>
          <div class="cell">
            <span class="cell-label">联系方式</span>
            <span class="cell-content-end">{{ team.contact }}</span>
          </div>
        </div>
      </el-tab-pane>
      <el-tab-pane
        label="资质"
        name="1"
        :class="
          qualifications_thumb && qualifications_thumb.length
            ? 'tab-pane'
            : 'tab-pane-empty'
        "
      >
        <div
          v-if="qualifications_thumb && qualifications_thumb.length"
          class="qualifications-container"
        >
          <el-image
            v-for="item in qualifications_thumb"
            :key="item"
            :src="item"
            class="qualification-img"
            fit="contain"
          >
            <span slot="placeholder" class="qualification-img-loading">
              <i class="el-icon-loading"></i> 加载中
            </span>
          </el-image>
        </div>
        <el-empty v-else description="暂无资质"></el-empty>
      </el-tab-pane>
    </el-tabs>
  </div>
</template>

<script>
import AliOSS from "../../api/alioss";
import Ocean from "../../api/ocean";
import ViewContext from "../../viewContext";

const TabNames = ["0", "1"];

export default {
  data() {
    return {
      activeTabName: TabNames[0],

      activeTeamId: 0,

      team: null,

      qualifications_loaded: false,
      qualifications: [],
      qualifications_thumb: [],
    };
  },
  mounted() {
    this.onTabChanged();
  },
  methods: {
    reset() {
      this.team = null;
      this.qualifications_loaded = false;
      this.qualifications = [];
      this.qualifications_thumb = [];
    },

    onTabClick(/*tab, event*/) {
      this.onTabChanged();
    },

    onTabChanged() {
      const vcTeam = ViewContext.getTeam();
      if (vcTeam) {
        if (this.activeTeamId !== vcTeam.id) {
          this.reset();
          this.activeTeamId = vcTeam.id;
        }

        if (this.activeTabName === TabNames[0]) {
          if (!this.team || this.team.id !== vcTeam.id) {
            this.getTeam(vcTeam.id);
          }
        } else if (this.activeTabName === TabNames[1]) {
          if (!this.qualifications_loaded) {
            this.loadQualifications(vcTeam.id);
          }
        }
      } else {
        this.reset();
        this.activeTeamId = 0;
      }
    },

    getTeam(id) {
      let fields = [
        "id",
        "name",
        "headimg",
        "area1_id",
        "area2_id",
        "area3_id",
        "introduce",
        "area_addr",
        "contact",
      ];
      Ocean.getTeamInfo(id, fields)
        .then((res) => {
          this.team = res.data.team;

          // 地址
          this.team.addr = Ocean.getAreaNameById3(
            this.team.area1_id,
            this.team.area2_id,
            this.team.area3_id
          );
          if (this.team.area_addr) {
            if (this.team.addr) {
              this.team.addr += " ";
            }
            this.team.addr += this.team.area_addr;
          }

          // 头像
          if (this.team.headimg) {
            let oss_object_key = AliOSS.getImageKey(
              this.team.headimg,
              this.team.id
            );
            this.team.headimgThumb =
              AliOSS.getImageThumbnailCdnUrl(oss_object_key);
          } else {
            this.team.headimgThumb = "";
          }
        })
        .catch((err) => {
          this.$message.error(err.data.msg);
        });
    },

    /**
     * 加载资质信息。
     */
    loadQualifications: function (teamid) {
      let fields = ["id", "qualifications"];
      Ocean.getTeamInfo(teamid, fields)
        .then((res) => {
          let rteam = res.data.team;
          if (rteam.qualifications) {
            this.qualifications = rteam.qualifications;
            for (let img of rteam.qualifications) {
              if (img) {
                let oss_object_key = AliOSS.getImageKey(img, teamid);
                //qualifications_url.push( AliOSS.getCdnUrl(oss_object_key) );
                this.qualifications_thumb.push(
                  AliOSS.getImageThumbnailCdnUrl(oss_object_key)
                );
              } else {
                //this.qualifications_url.push("");
                this.qualifications_thumb.push("");
              }
            }
          }
          this.qualifications_loaded = true;
        })
        .catch((err) => {
          this.$message.error(err.data.msg);
        });
    },
  },
  watch: {
    "$route.query": {
      immediate: true,
      handler(to /* , from */) {
        if (to && to.tab) {
          this.activeTabName = to.tab;
        }
        this.onTabChanged();
      },
    },
  },
};
</script>

<style scoped lang="scss">
.container {
  width: 100%;
  min-height: 100vh;
  padding: 50px 50px 60px 270px;
  box-sizing: border-box;
}

.title {
  padding-top: 10px;
  font-size: 24px;
  font-weight: 400;
  line-height: 1;
}

.tabs {
  ::v-deep .el-tabs__item {
    font-size: 16px;
  }

  /*
  ::v-deep .el-tabs__nav-wrap::after {
    position: static !important;
    border-bottom: 1px solid #e0e1e2 !important;
  }
  */
}

.tab-pane {
  margin-top: 16px;
  margin-bottom: 60px;
  padding: 0 20px;
  box-sizing: border-box;
}

.tab-pane-empty {
  margin-top: 16px;
  margin-bottom: 60px;
}

.logo-cell {
  width: 100%;
  padding: 20px 0 10px 0;
  box-sizing: border-box;
  text-align: center;
}

.logo-img {
  width: 84px;
  height: 84px;
  /* border-radius: 50%; */
  border-radius: 3px;
}

.cell {
  display: flex;
}

.cell-label {
  width: 100px;
  padding: 20px 10px 20px 0;
  box-sizing: border-box;
  color: #9a9a9a;
}

.cell-content {
  flex: 1;
  padding: 20px 0;
  border-bottom: 1px solid #e4e8eb;
}

.cell-content-end {
  flex: 1;
  padding: 20px 0;
}

.qualifications-container {
  min-height: calc(100vh - 340px);
}

.qualification-img {
  width: 200px;
  height: 200px;
  margin: 6px 6px;
  border-radius: 3px;
}

.qualification-img-loading {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 14px;
  color: #c0c4cc;
  vertical-align: middle;
  background: #f5f7fa;
}
</style>
