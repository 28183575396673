/*
 * A JavaScript implementation of the RSA Data Security, Inc. MD5 Message
 * Digest Algorithm, as defined in RFC 1321.
 * Version 1.1 Copyright (C) Paul Johnston 1999 - 2002.
 * Code also contributed by Greg Holt
 * See http://pajhome.org.uk/site/legal.html for details.
 */

import Ocean from "../api/ocean";

/**
 *
 * @param {*} order {
 *   state
 *   astate
 * }
 * @returns {
 *   allowTake: false, // 允许接单
 *   allowAgreeCancel: false,
 *   allowRejectCancel: false, // 拒绝取消订单
 *   allowAgreeRefund: false,
 *   allowRejectRefund: false, // 拒绝退款申请
 *   allowServiceDone: false, // 顾问服务完毕
 *   allowAlter: false, // 修改订单信息
 * }
 */
function checkOps(order) {
  let ops = {};
  const { BsOrderState, BsOrderAfterState } = Ocean;
  if (order.astate === BsOrderAfterState.WaitingTeamCancel) {
    ops.allowAgreeCancel = true;
    ops.allowRejectCancel = true;
  } else if (order.astate === BsOrderAfterState.WaitingTeamRefund) {
    ops.allowAgreeRefund = true;
    ops.allowRejectRefund = true;
  } else if (
    order.astate === BsOrderAfterState.None ||
    order.astate === BsOrderAfterState.TeamRejectCancel ||
    order.astate === BsOrderAfterState.TeamRejectRefund
  ) {
    if (
      order.state === BsOrderState.Created ||
      order.state === BsOrderState.Paid
    ) {
      ops.allowTake = true;
    }
    if (order.state === BsOrderState.InService) {
      ops.allowServiceDone = true;
    }
  }

  if (order.state < BsOrderState.Completed) {
    ops.allowAlter = true;
  }

  return ops;
}

/**
 *
 * @param {*} order {
 *   state
 *   astate
 * }
 * @returns true/false
 */
function checkAllowDelete(order) {
  let allowDelete = false;
  const { BsOrderState, BsOrderAfterState } = Ocean;

  if (order.state === BsOrderState.Completed) {
    allowDelete =
      order.astate === BsOrderAfterState.None ||
      order.astate === BsOrderAfterState.Canceled ||
      order.astate === BsOrderAfterState.Refunded;
  } else {
    allowDelete =
      order.state === BsOrderState.Canceled ||
      order.state === BsOrderState.Refunded;
  }

  return allowDelete;
}

export default {
  checkOps,
  checkAllowDelete,
};
