import Icons from "../../assets/icons";

export function toggleAriaAttribute(element, attribute) {
  element.setAttribute(
    attribute,
    !(element.getAttribute(attribute) === "true")
  );
}

export function checkUrl(url) {
  // if (!url) return 'http://about:blank';
  if (!url) return "";
  if (url.indexOf("://") === -1) {
    return `http://${url}`;
  }
  return url;
}

export class Tool {
  constructor(toolbar) {
    this.toolbar = toolbar;
  }
}

export class Separator {
  constructor() {
    this.container = document.createElement("div");
    this.container.classList.add("tt-separator");
    this.container.tabIndex = "-1";
  }
}

export class Button extends Tool {
  constructor(toolbar) {
    super(toolbar);
  }

  /**
   * icon
   * text
   * class
   * tip
   * onclick
   */
  buildButton(params) {
    const button = document.createElement("button");
    button.tabIndex = "0";
    button.setAttribute("type", "button");
    if (params.class) {
      button.setAttribute("class", params.class);
    }
    if (params.tip) {
      button.setAttribute("title", params.tip);
    }

    if (params.icon) {
      const icon = document.createElement("span");
      icon.classList.add("tt-icon");
      icon.innerHTML = params.icon;
      button.appendChild(icon);
    } else if (params.text) {
      button.innerHTML = params.text;
    }

    button.addEventListener("click", () => {
      if (params.onclick) {
        params.onclick();
        this.toolbar.update();
      }
    });

    this.button = button;
  }

  update() {
    if (this.isActive) {
      let active = this.isActive();
      this.button.classList.toggle("is-active", active);
    }
  }
}

export class BoldButton extends Button {
  constructor(toolbar) {
    super(toolbar);

    this.buildButton({
      icon: Icons.bold,
      class: "tt-bold tt-button",
      tip: "加粗",
      onclick: () => {
        this.toolbar.editor().chain().focus().toggleBold().run();
      },
    });
    this.isActive = () => {
      return this.toolbar.editor().isActive("bold");
    };
  }
}

export class ItalicButton extends Button {
  constructor(toolbar) {
    super(toolbar);

    this.buildButton({
      icon: Icons.italic,
      class: "tt-italic tt-button",
      tip: "斜体",
      onclick: () => {
        this.toolbar.editor().chain().focus().toggleItalic().run();
      },
    });
    this.isActive = () => {
      return this.toolbar.editor().isActive("italic");
    };
  }
}

export class UnderlineButton extends Button {
  constructor(toolbar) {
    super(toolbar);

    this.buildButton({
      icon: Icons.underline,
      class: "tt-underline tt-button",
      tip: "下划线",
      onclick: () => {
        this.toolbar.editor().chain().focus().toggleUnderline().run();
      },
    });
    this.isActive = () => {
      return this.toolbar.editor().isActive("underline");
    };
  }
}

export class StrikeButton extends Button {
  constructor(toolbar) {
    super(toolbar);

    this.buildButton({
      icon: Icons.strike,
      class: "tt-strike tt-button",
      tip: "删除线",
      onclick: () => {
        this.toolbar.editor().chain().focus().toggleStrike().run();
      },
    });
    this.isActive = () => {
      return this.toolbar.editor().isActive("strike");
    };
  }
}

export class OrderedListButton extends Button {
  constructor(toolbar) {
    super(toolbar);

    this.buildButton({
      icon: Icons.list.numbered,
      class: "tt-list-ordered tt-button",
      tip: "有序列表",
      onclick: () => {
        this.toolbar.editor().chain().focus().toggleOrderedList().run();
      },
    });
    this.isActive = () => {
      return this.toolbar.editor().isActive("orderedList");
    };
  }
}

export class BulletListButton extends Button {
  constructor(toolbar) {
    super(toolbar);

    this.buildButton({
      icon: Icons.list.bullet,
      class: "tt-list-bullet tt-button",
      tip: "无序列表",
      onclick: () => {
        this.toolbar.editor().chain().focus().toggleBulletList().run();
      },
    });
    this.isActive = () => {
      return this.toolbar.editor().isActive("bulletList");
    };
  }
}

export class TaskListButton extends Button {
  constructor(toolbar) {
    super(toolbar);

    this.buildButton({
      icon: Icons.list.check,
      class: "tt-list-task tt-button",
      tip: "任务列表",
      onclick: () => {
        this.toolbar.editor().chain().focus().toggleTaskList().run();
      },
    });
    this.isActive = () => {
      return this.toolbar.editor().isActive("taskList");
    };
  }
}

export class SuperscriptButton extends Button {
  constructor(toolbar) {
    super(toolbar);

    this.buildButton({
      icon: Icons.script.super,
      class: "tt-superscript tt-button",
      tip: "上标",
      onclick: () => {
        this.toolbar.editor().chain().focus().toggleSuperscript().run();
      },
    });
    this.isActive = () => {
      return this.toolbar.editor().isActive("superscript");
    };
  }
}

export class SubscriptButton extends Button {
  constructor(toolbar) {
    super(toolbar);

    this.buildButton({
      icon: Icons.script.sub,
      class: "tt-subscript tt-button",
      tip: "下标",
      onclick: () => {
        this.toolbar.editor().chain().focus().toggleSubscript().run();
      },
    });
    this.isActive = () => {
      return this.toolbar.editor().isActive("subscript");
    };
  }
}

export class BlockquoteButton extends Button {
  constructor(toolbar) {
    super(toolbar);

    this.buildButton({
      icon: Icons.blockquote,
      class: "tt-blockquote tt-button",
      tip: "引用",
      onclick: () => {
        this.toolbar.editor().chain().focus().toggleBlockquote().run();
      },
    });
    this.isActive = () => {
      return this.toolbar.editor().isActive("blockquote");
    };
  }
}

export class IndentButton extends Button {
  constructor(toolbar) {
    super(toolbar);

    this.buildButton({
      icon: Icons.indent,
      class: "tt-indent tt-button",
      tip: "增加缩进",
      onclick: () => {
        this.toolbar.editor().chain().focus().indent().run();
      },
    });
    //this.isActive = () => { return this.toolbar.editor().isActive('indent'); }
  }
}

export class OutdentButton extends Button {
  constructor(toolbar) {
    super(toolbar);

    this.buildButton({
      icon: Icons.outdent,
      class: "tt-outdent tt-button",
      tip: "减少缩进",
      onclick: () => {
        this.toolbar.editor().chain().focus().outdent().run();
      },
    });
    //this.isActive = () => { return !this.toolbar.editor().isActive('indent'); }
  }
}

export class LinkButton extends Button {
  constructor(toolbar) {
    super(toolbar);

    this.buildButton({
      icon: Icons.link,
      class: "tt-link tt-button",
      tip: "超链接",
      onclick: () => {
        const ttEditor = this.toolbar.ttEditor;
        if (ttEditor.linkMenu) {
          ttEditor.linkMenu.show(true);
        }
      },
    });
    this.isActive = () => {
      return this.toolbar.editor().isActive("link");
    };
  }
}

export class HorizontalLineButton extends Button {
  constructor(toolbar) {
    super(toolbar);

    this.buildButton({
      icon: Icons.horizontalLine,
      class: "tt-horizontal-line tt-button",
      tip: "分隔线",
      onclick: () => {
        this.toolbar.editor().chain().focus().setHorizontalRule().run();
      },
    });
  }
}

export class RedoButton extends Button {
  constructor(toolbar) {
    super(toolbar);

    this.buildButton({
      icon: Icons.redo,
      class: "tt-redo tt-button",
      tip: "重做",
      onclick: () => {
        this.toolbar.editor().chain().focus().redo().run();
      },
    });
  }
}

export class UndoButton extends Button {
  constructor(toolbar) {
    super(toolbar);

    this.buildButton({
      icon: Icons.undo,
      class: "tt-undo tt-button",
      tip: "撤销",
      onclick: () => {
        this.toolbar.editor().chain().focus().undo().run();
      },
    });
  }
}

export class Picker extends Tool {
  /**
   *
   * @param {*} toolbar
   */
  constructor(toolbar) {
    super(toolbar);
    this.container = document.createElement("div");

    /**
     *
     * element: HTMLElement
     * isActive: Function
     */
    this.actives = [];
  }

  togglePicker() {
    let expanded = this.container.classList.contains("tt-expanded");
    this.container.classList.toggle("tt-expanded");
    this.label.root.setAttribute("aria-expanded", !expanded);
    this.panel.setAttribute("aria-hidden", expanded);
  }

  escape() {
    // Close menu and return focus to trigger label
    this.close();
    // Need setTimeout for accessibility to ensure that the browser executes
    // focus on the next process thread and after any DOM content changes
    setTimeout(() => this.label.focus(), 1);
  }

  close() {
    this.container.classList.remove("tt-expanded");
    this.label.root.setAttribute("aria-expanded", "false");
    this.panel.setAttribute("aria-hidden", "true");
  }

  isExpanded() {
    return this.container.classList.contains("tt-expanded");
  }

  addOptionClickEventListener(element, option, shouldSelect) {
    element.addEventListener("click", () => {
      if (option.handler) {
        const editor = this.toolbar.editor();
        option.handler({ editor, option, element });
      }
      if (shouldSelect) {
        this.selectItem(option);
      }
      if (!option.notClosePanel) {
        this.close();
      }
      this.toolbar.update();
    });
  }

  addOptionKeyboardEventListener(element, option, shouldSelect) {
    element.addEventListener("keydown", (event) => {
      switch (event.key) {
        case "Enter":
          if (option.handler) {
            const editor = this.toolbar.editor();
            option.handler({ editor, option, element });
          }
          if (shouldSelect) {
            this.selectItem(option);
          }
          if (!option.notClosePanel) {
            this.close();
          }
          this.toolbar.update();
          event.preventDefault();
          break;
        case "Escape":
          this.escape();
          event.preventDefault();
          break;
        default:
      }
    });
  }

  fillAttributes(attributes) {
    if (attributes) {
      Object.keys(attributes).forEach((key) => {
        this.container.setAttribute(key, attributes[key]);
      });
    }
    this.container.classList.add("tt-picker");
  }

  /**
   *
   * @param {*} params
   *   text: 名称
   *   icon：图标
   *   tip: 提示
   * @returns label
   *   {
   *     root
   *     text
   *     icon
   *     arrow
   *   }
   */
  buildButtonLabel(params) {
    const label = {};

    label.root = document.createElement("button");
    label.root.setAttribute("class", "tt-picker-label tt-button");
    label.root.tabIndex = "0";
    label.root.setAttribute("type", "button");
    if (params.tip) {
      label.root.setAttribute("title", params.tip);
    }

    label.root.setAttribute("aria-expanded", "false");

    if (params.text) {
      label.text = document.createElement("span");
      label.text.classList.add("tt-picker-text");
      label.text.innerHTML = params.text;
      label.root.appendChild(label.text);
    } else if (params.icon) {
      label.icon = document.createElement("span");
      label.icon.classList.add("tt-picker-icon");
      label.icon.innerHTML = params.icon;
      label.root.appendChild(label.icon);
    }

    label.arrow = document.createElement("span");
    label.arrow.classList.add("tt-picker-arrow");
    label.arrow.innerHTML = Icons.dropdownArrow;
    label.root.appendChild(label.arrow);

    label.root.addEventListener("mousedown", () => {
      this.togglePicker();
    });
    label.root.addEventListener("keydown", (event) => {
      switch (event.key) {
        case "Enter":
          this.togglePicker();
          break;
        case "Escape":
          this.escape();
          event.preventDefault();
          break;
        default:
      }
    });

    this.container.appendChild(label.root);
    return label;
  }

  /**
   *
   * @param {*} params
   *   text: 名称
   *   icon：图标
   *   tip: 提示
   *   handler: 处理函数
   *   isActive(editor): Function
   *   arrowTip: 提示
   * @returns label
   *   {
   *     root
   *     textButton
   *     text
   *     iconButton
   *     icon
   *     arrow
   *   }
   */
  buildSplitLabel(params) {
    const label = {};

    label.root = document.createElement("div");
    label.root.classList.add("tt-picker-splitlabel");
    label.root.tabIndex = "-1";

    label.root.setAttribute("aria-expanded", "false");

    let labelButton;
    if (params.icon) {
      label.iconButton = document.createElement("button");
      // tt-splitbutton
      label.iconButton.classList.add("tt-button");
      label.iconButton.tabIndex = "0";
      label.iconButton.setAttribute("type", "button");
      if (params.tip) {
        label.iconButton.setAttribute("title", params.tip);
      }

      label.icon = document.createElement("span");
      label.icon.classList.add("tt-picker-icon");
      label.icon.innerHTML = params.icon;

      label.iconButton.appendChild(label.icon);
      label.root.appendChild(label.iconButton);

      labelButton = label.iconButton;
    } else {
      label.textButton = document.createElement("button");
      // tt-splitbutton
      label.textButton.classList.add("tt-button");
      label.textButton.tabIndex = "0";
      label.textButton.setAttribute("type", "button");
      if (params.tip) {
        label.textButton.setAttribute("title", params.tip);
      }

      label.text = document.createElement("span");
      label.text.classList.add("tt-picker-text");
      if (params.text) {
        label.text.innerHTML = params.text;
      }

      label.textButton.appendChild(label.text);
      label.root.appendChild(label.textButton);

      labelButton = label.textButton;
    }

    if (params.isActive) {
      this.actives.push({
        element: labelButton,
        isActive: params.isActive,
      });
    }

    label.arrow = document.createElement("button");
    label.arrow.setAttribute("class", "tt-button tt-picker-splitarrow");
    label.arrow.tabIndex = "0";
    label.arrow.innerHTML = Icons.dropdownArrow;
    if (params.arrowTip || params.tip) {
      label.arrow.setAttribute("title", params.arrowTip || params.tip);
    }
    label.root.appendChild(label.arrow);

    labelButton.addEventListener("click", () => {
      if (params.handler) {
        params.handler(label);
      }
    });
    labelButton.addEventListener("keydown", (event) => {
      switch (event.key) {
        case "Enter":
          if (params.handler) {
            params.handler(label);
          }
          event.preventDefault();
          break;
        default:
      }
    });

    label.arrow.addEventListener("mousedown", () => {
      this.togglePicker();
    });
    label.arrow.addEventListener("keydown", (event) => {
      switch (event.key) {
        case "Enter":
          this.togglePicker();
          break;
        case "Escape":
          this.escape();
          event.preventDefault();
          break;
        default:
      }
    });

    this.container.appendChild(label.root);
    return label;
  }

  buildPanel(options) {
    const panel = document.createElement("div");
    panel.classList.add("tt-picker-panel");

    // Don't want screen readers to read this until panel are visible
    panel.setAttribute("aria-hidden", "true");
    panel.tabIndex = "-1";

    this.panel = panel;

    this.buildOptions(options);

    this.container.appendChild(this.panel);
  }

  /**
   *
   * @param {*} options
   *   [
   *     {
   *       text: 名称
   *       icon：图标
   *       class: String
   *       tip: 提示
   *       handler: 处理函数
   *       isActive(editor): Function
   *       notClosePanel: true,
   *     }
   *   ]
   */
  buildOptions(options) {
    options.forEach((option) => {
      const item = this.buildRowItem(option);
      this.panel.appendChild(item);
    });
  }

  buildRowItem(option) {
    const row = document.createElement("div");
    row.classList.add("tt-panel-row");
    const item = this.buildItem(option);
    row.appendChild(item);
    return row;
  }

  buildItem(option) {
    const item = document.createElement("button");
    item.tabIndex = "0";
    item.setAttribute("type", "button");

    if (option.class) {
      item.setAttribute("class", option.class);
    }

    if (option.tip) {
      item.setAttribute("title", option.tip);
    }

    if (option.icon) {
      const icon = document.createElement("span");
      icon.classList.add("tt-panel-icon");
      icon.innerHTML = option.icon;
      item.appendChild(icon);
    }

    if (option.text) {
      const text = document.createElement("span");
      text.classList.add("tt-panel-text");
      text.innerHTML = option.text;
      item.appendChild(text);
    }

    this.addOptionClickEventListener(item, option, true);
    this.addOptionKeyboardEventListener(item, option, true);

    if (option.isActive) {
      this.actives.push({
        element: item,
        isActive: option.isActive,
      });
    }

    return item;
  }

  /**
   *
   * @param {*} option
   *   {
   *     icon：图标
   *     text: 名称
   *     active
   *   }
   * @param {*} closePanel
   */
  selectItem({ icon, text, active }, closePanel = false) {
    if (icon && this.label.icon) {
      this.label.icon.innerHTML = icon;
    }

    if (text && this.label.text) {
      this.label.text.innerHTML = text;
    }

    if (active === true) {
      this.label.root.classList.add("is-active");
    } else if (active === false) {
      this.label.root.classList.remove("is-active");
    }

    if (closePanel) {
      this.close();
    }
  }

  update() {
    const editor = this.toolbar.editor();
    this.actives.forEach((item) => {
      item.element.classList.toggle("is-active", item.isActive(editor));
    });
  }
}

export const HEADINGS = [
  {
    text: "正文",
    class: "tt-full tt-button",
    handler: ({ editor /* option, element, */ }) => {
      const { level } = editor.getAttributes("heading");
      if (level || level === 0) {
        editor.chain().focus().toggleHeading({ level }).run();
      }
    },
    isActive: (editor) => {
      const { level } = editor.getAttributes("heading");
      return !(level || level === 0);
    },
  },
  {
    text: "标题 1",
    class: "tt-full tt-button tt-heading1-panel-text",
    handler: ({ editor /* option, element, */ }) => {
      editor.chain().focus().toggleHeading({ level: 1 }).run();
    },
    isActive: (editor) => {
      return editor.isActive("heading", { level: 1 });
    },
  },
  {
    text: "标题 2",
    class: "tt-full tt-button tt-heading2-panel-text",
    handler: ({ editor /* option, element, */ }) => {
      editor.chain().focus().toggleHeading({ level: 2 }).run();
    },
    isActive: (editor) => {
      return editor.isActive("heading", { level: 2 });
    },
  },
  {
    text: "标题 3",
    class: "tt-full tt-button tt-heading3-panel-text",
    handler: ({ editor /* option, element, */ }) => {
      editor.chain().focus().toggleHeading({ level: 3 }).run();
    },
    isActive: (editor) => {
      return editor.isActive("heading", { level: 3 });
    },
  },
  {
    text: "标题 4",
    class: "tt-full tt-button tt-heading4-panel-text",
    handler: ({ editor /* option, element, */ }) => {
      editor.chain().focus().toggleHeading({ level: 4 }).run();
    },
    isActive: (editor) => {
      return editor.isActive("heading", { level: 4 });
    },
  },
  {
    text: "标题 5",
    class: "tt-full tt-button tt-heading5-panel-text",
    handler: ({ editor /* option, element, */ }) => {
      editor.chain().focus().toggleHeading({ level: 5 }).run();
    },
    isActive: (editor) => {
      return editor.isActive("heading", { level: 5 });
    },
  },
  {
    text: "标题 6",
    class: "tt-full tt-button tt-heading6-panel-text",
    handler: ({ editor /* option, element, */ }) => {
      editor.chain().focus().toggleHeading({ level: 6 }).run();
    },
    isActive: (editor) => {
      return editor.isActive("heading", { level: 6 });
    },
  },
];

export class HeadingPicker extends Picker {
  constructor(toolbar) {
    super(toolbar);

    this.fillAttributes({
      class: "tt-heading",
    });
    this.label = this.buildButtonLabel({
      text: HEADINGS[0].text,
      tip: "标题",
    });
    this.buildPanel(HEADINGS);

    this.selectItem({ text: HEADINGS[0].text });
  }

  update() {
    const editor = this.toolbar.editor();
    const { level } = editor.getAttributes("heading");

    if (!level || level < 1 || level > 6) {
      this.selectItem({ text: HEADINGS[0].text });
    } else {
      this.selectItem({ text: HEADINGS[level].text });
    }

    super.update();
  }
}

export const ALIGNS = [
  {
    icon: Icons.align.left,
    tip: "左对齐",
    class: "tt-full tt-button",
    handler: ({ editor /* option, element, */ }) => {
      editor.chain().focus().unsetTextAlign().run();
    },
    isActive: (editor) => {
      return editor.isActive({ textAlign: "left" });
    },
  },
  {
    icon: Icons.align.center,
    tip: "居中对齐",
    class: "tt-full tt-button",
    handler: ({ editor /* option, element, */ }) => {
      editor.chain().focus().setTextAlign("center").run();
    },
    isActive: (editor) => {
      return editor.isActive({ textAlign: "center" });
    },
  },
  {
    icon: Icons.align.right,
    tip: "右对齐",
    class: "tt-full tt-button",
    handler: ({ editor /* option, element, */ }) => {
      editor.chain().focus().setTextAlign("right").run();
    },
    isActive: (editor) => {
      return editor.isActive({ textAlign: "right" });
    },
  },
  {
    icon: Icons.align.justify,
    tip: "两端对齐",
    class: "tt-full tt-button",
    handler: ({ editor /* option, element, */ }) => {
      editor.chain().focus().setTextAlign("justify").run();
    },
    isActive: (editor) => {
      return editor.isActive({ textAlign: "justify" });
    },
  },
];

export class AlignPicker extends Picker {
  constructor(toolbar) {
    super(toolbar);

    this.fillAttributes({
      class: "tt-align",
    });
    this.label = this.buildButtonLabel({
      //text: '',
      icon: Icons.align.left,
      tip: "对齐",
    });
    this.buildPanel(ALIGNS);

    this.selectItem({ icon: Icons.align.left });
  }

  update() {
    const editor = this.toolbar.editor();
    let opt;

    if (editor.isActive({ textAlign: "left" })) {
      opt = { icon: Icons.align.left, active: false };
    } else if (editor.isActive({ textAlign: "center" })) {
      opt = { icon: Icons.align.center, active: false };
    } else if (editor.isActive({ textAlign: "right" })) {
      opt = { icon: Icons.align.right, active: false };
    } else if (editor.isActive({ textAlign: "justify" })) {
      opt = { icon: Icons.align.justify, active: false };
    } else {
      opt = { icon: Icons.align.left, active: false };
    }

    this.selectItem(opt);

    super.update();
  }
}

const ImageMimetypes = [
  "image/jpeg",
  "image/png",
  "image/gif",
  "image/bmp",
  "image/x-icon",
];

export class ImagePicker extends Picker {
  /**
   *
   * @param {*} toolbar
   * @param {*} options
   *   upload(): Function
   *   insertNetResourceImage(): Function
   */
  constructor(toolbar, options) {
    super(toolbar);
    this.options = options || {};

    this.fillAttributes({
      class: "tt-image",
    });
    this.label = this.buildButtonLabel({
      icon: Icons.image,
      tip: "图片",
    });

    const IMAGES = [
      {
        text: "上传本地图片",
        class: "tt-full tt-button",
        handler: ({ editor /* option, element, */ }) => {
          let fileInput = this.container.querySelector(
            "input.tt-upload-image[type=file]"
          );
          if (fileInput == null) {
            fileInput = document.createElement("input");
            fileInput.style.display = "none";
            fileInput.setAttribute("type", "file");
            fileInput.setAttribute("accept", ImageMimetypes.join(", "));
            fileInput.classList.add("tt-upload-image");
            fileInput.setAttribute("multiple", "multiple");
            fileInput.addEventListener("change", () => {
              const uploadFiles = [];

              // 一次最多50个图片
              const maxCount = Math.min(fileInput.files.length, 50);
              for (var i = 0; i < maxCount; i++) {
                const file = fileInput.files.item(i);
                if (file && ImageMimetypes.includes(file.type)) {
                  uploadFiles.push(file);
                }
              }
              if (uploadFiles.length > 0) {
                if (this.options.upload) {
                  this.options.upload(uploadFiles, editor);
                  editor.chain().focus();
                } else {
                  const promises = uploadFiles.map((file) => {
                    return new Promise((resolve) => {
                      const reader = new FileReader();
                      reader.onload = (e) => {
                        resolve(e.target.result);
                      };
                      reader.readAsDataURL(file);
                    });
                  });
                  Promise.all(promises).then((images) => {
                    images.forEach((img) => {
                      editor.commands.setImage({ src: img });
                    });
                    editor.chain().focus();
                  });
                }
              }

              fileInput.value = "";
            });

            this.container.appendChild(fileInput);
          }
          fileInput.click();
        },
      },
      {
        text: "添加网络图片",
        class: "tt-full tt-button",
        handler: (/* { editor, option, element, } */) => {
          this.showUrlPanel();
        },
        notClosePanel: true,
      },
      /*
      {
        text: '从资源空间选择图片',
        class: 'tt-full tt-button',
        handler: () => {
          if (this.options.insertNetResourceImage) {
            this.options.insertNetResourceImage();
          }
        },
      },
      */
    ];
    this.buildPanel(IMAGES);

    this.buildUrlPanel();
  }

  togglePicker() {
    super.togglePicker();
    this.closeUrlPanel();
  }

  escape() {
    // Close menu and return focus to trigger label
    this.close();
    // Need setTimeout for accessibility to ensure that the browser executes
    // focus on the next process thread and after any DOM content changes
    setTimeout(() => this.label.focus(), 1);
  }

  close() {
    super.close();
    this.closeUrlPanel();
  }

  closeUrlPanel() {
    this.urlPanel.setAttribute("aria-hidden", "true");
  }

  showUrlPanel() {
    this.urlPanel.setAttribute("aria-hidden", "false");
    this.panel.setAttribute("aria-hidden", "true");
  }

  buildPanel(options) {
    const panel = document.createElement("div");
    panel.classList.add("tt-picker-mutipanel");
    panel.setAttribute("aria-hidden", "true");
    panel.tabIndex = "-1";

    this.panel = panel;

    this.buildOptions(options);

    this.container.appendChild(this.panel);
  }

  buildUrlPanel() {
    const panel = document.createElement("div");
    panel.classList.add("tt-picker-mutipanel");
    panel.setAttribute("aria-hidden", "true");
    panel.tabIndex = "-1";

    const urlRow = document.createElement("div");
    urlRow.classList.add("tt-image-urlpanel-urlrow");

    const urlInput = document.createElement("input");
    urlInput.setAttribute("type", "text");
    urlInput.classList.add("tt-image-urlpanel-urlinput");
    urlInput.setAttribute("placeholder", "例如: https://abc.com/123.jpg");
    urlRow.appendChild(urlInput);

    const actionRow = document.createElement("div");
    actionRow.classList.add("tt-image-urlpanel-actionsrow");

    const actionOk = document.createElement("span");
    actionOk.classList.add("tt-image-urlpanel-action");
    actionOk.tabIndex = "0";
    const actionOkIcon = document.createElement("span");
    actionOkIcon.classList.add("tt-panel-icon");
    actionOkIcon.innerHTML = Icons.check;
    actionOk.appendChild(actionOkIcon);
    const actionOkText = document.createElement("span");
    actionOkText.innerHTML = "插入";
    actionOk.appendChild(actionOkText);
    actionRow.appendChild(actionOk);

    const actionCancel = document.createElement("span");
    actionCancel.classList.add("tt-image-urlpanel-action");
    actionCancel.tabIndex = "0";
    const actionCancelIcon = document.createElement("span");
    actionCancelIcon.classList.add("tt-panel-icon");
    actionCancelIcon.innerHTML = Icons.cancel;
    actionCancel.appendChild(actionCancelIcon);
    const actionCancelText = document.createElement("span");
    actionCancelText.innerHTML = "取消";
    actionCancel.appendChild(actionCancelText);
    actionRow.appendChild(actionCancel);

    const actionOkOption = {
      handler: ({ editor /* option, element, */ }) => {
        if (urlInput.value) {
          let src = checkUrl(urlInput.value);
          editor.commands.setImage({ src });
        }
        urlInput.value = "";
        editor.chain().focus();
      },
    };
    this.addOptionClickEventListener(actionOk, actionOkOption, false);
    this.addOptionKeyboardEventListener(actionOk, actionOkOption, false);
    this.addOptionClickEventListener(actionCancel, {}, false);
    this.addOptionKeyboardEventListener(actionCancel, {}, false);

    panel.appendChild(urlRow);
    panel.appendChild(actionRow);
    this.urlPanel = panel;
    this.container.appendChild(this.urlPanel);
  }

  selectItem() {}

  update() {}
}

export const FONT_FAMILYS = [
  {
    text: "默认字体",
    labelAlias: "字体",
    class: "tt-full tt-button",
    handler: ({ editor /* option, element, */ }) => {
      editor.chain().focus().unsetFontFamily().run();
    },
    isActive: (editor) => {
      const { fontFamily } = editor.getAttributes("textStyle");
      return !fontFamily;
    },
  },
  {
    text: "宋体",
    class: "tt-full tt-button tt-fontfamily-SimSun",
    value: "SimSun",
    handler: ({ editor /* option, element, */ }) => {
      editor.chain().focus().setFontFamily("SimSun").run();
    },
    isActive: (editor) => {
      return editor.isActive("textStyle", { fontFamily: "SimSun" });
    },
  },
  {
    text: "黑体",
    class: "tt-full tt-button tt-fontfamily-SimHei",
    value: "SimHei",
    handler: ({ editor /* option, element, */ }) => {
      editor.chain().focus().setFontFamily("SimHei").run();
    },
    isActive: (editor) => {
      return editor.isActive("textStyle", { fontFamily: "SimHei" });
    },
  },
  {
    text: "微软雅黑",
    class: "tt-full tt-button tt-fontfamily-Microsoft-YaHei",
    value: "Microsoft YaHei",
    handler: ({ editor /* option, element, */ }) => {
      editor.chain().focus().setFontFamily("Microsoft YaHei").run();
    },
    isActive: (editor) => {
      return editor.isActive("textStyle", { fontFamily: "Microsoft YaHei" });
    },
  },
  {
    text: "楷体",
    class: "tt-full tt-button tt-fontfamily-KaiTi",
    value: "KaiTi",
    handler: ({ editor /* option, element, */ }) => {
      editor.chain().focus().setFontFamily("KaiTi").run();
    },
    isActive: (editor) => {
      return editor.isActive("textStyle", { fontFamily: "KaiTi" });
    },
  },
  {
    text: "仿宋",
    class: "tt-full tt-button tt-fontfamily-FangSong",
    value: "FangSong",
    handler: ({ editor /* option, element, */ }) => {
      editor.chain().focus().setFontFamily("FangSong").run();
    },
    isActive: (editor) => {
      return editor.isActive("textStyle", { fontFamily: "FangSong" });
    },
  },
  {
    text: "Arial",
    class: "tt-full tt-button tt-fontfamily-Arial",
    value: "Arial, Helvetica, sans-serif",
    handler: ({ editor /* option, element, */ }) => {
      editor
        .chain()
        .focus()
        .setFontFamily("Arial, Helvetica, sans-serif")
        .run();
    },
    isActive: (editor) => {
      return editor.isActive("textStyle", {
        fontFamily: "Arial, Helvetica, sans-serif",
      });
    },
  },
  {
    text: "Times New Roman",
    class: "tt-full tt-button tt-fontfamily-Times-New-Roman",
    value: "Times New Roman, Times, serif",
    handler: ({ editor /* option, element, */ }) => {
      editor
        .chain()
        .focus()
        .setFontFamily("Times New Roman, Times, serif")
        .run();
    },
    isActive: (editor) => {
      return editor.isActive("textStyle", {
        fontFamily: "Times New Roman, Times, serif",
      });
    },
  },
  {
    text: "Courier New",
    class: "tt-full tt-button tt-fontfamily-Courier-New",
    value: "Courier New, Courier, monospace",
    handler: ({ editor /* option, element, */ }) => {
      editor
        .chain()
        .focus()
        .setFontFamily("Courier New, Courier, monospace")
        .run();
    },
    isActive: (editor) => {
      return editor.isActive("textStyle", {
        fontFamily: "Courier New, Courier, monospace",
      });
    },
  },
  {
    text: "Georgia",
    class: "tt-full tt-button tt-fontfamily-Georgia",
    value: "Georgia, serif",
    handler: ({ editor /* option, element, */ }) => {
      editor.chain().focus().setFontFamily("Georgia, serif").run();
    },
    isActive: (editor) => {
      return editor.isActive("textStyle", { fontFamily: "Georgia, serif" });
    },
  },
  {
    text: "Source Code Pro",
    class: "tt-full tt-button tt-fontfamily-Source-Code-Pro",
    value: "Source Code Pro",
    handler: ({ editor /* option, element, */ }) => {
      editor.chain().focus().setFontFamily("Source Code Pro").run();
    },
    isActive: (editor) => {
      return editor.isActive("textStyle", { fontFamily: "Source Code Pro" });
    },
  },
];

export class FontFamilyPicker extends Picker {
  constructor(toolbar) {
    super(toolbar);

    this.fillAttributes({
      class: "tt-font-family",
    });
    this.label = this.buildButtonLabel({
      text: FONT_FAMILYS[0].text,
      tip: "字体",
    });
    this.buildPanel(FONT_FAMILYS);

    this.selectItem({ text: FONT_FAMILYS[0].labelAlias });
  }

  update() {
    const editor = this.toolbar.editor();
    const { fontFamily } = editor.getAttributes("textStyle");

    if (!fontFamily) {
      this.selectItem({ text: FONT_FAMILYS[0].labelAlias });
    } else {
      let index = 1;
      for (; index < FONT_FAMILYS.length; index++) {
        if (fontFamily === FONT_FAMILYS[index].value) {
          this.selectItem({ text: FONT_FAMILYS[index].text });
          break;
        }
      }

      if (index >= FONT_FAMILYS.length) {
        this.selectItem({ text: FONT_FAMILYS[0].labelAlias });
      }
    }

    super.update();
  }
}

export const FONT_SIZES = [
  {
    text: "默认字号",
    labelAlias: "字号",
    class: "tt-full tt-button",
    handler: ({ editor /* option, element, */ }) => {
      editor.chain().focus().unsetFontSize().run();
    },
    isActive: (editor) => {
      const { fontSize } = editor.getAttributes("textStyle");
      return !fontSize;
    },
  },
  {
    text: "9",
    class: "tt-full tt-button",
    value: "9pt",
    handler: ({ editor /* option, element, */ }) => {
      editor.chain().focus().setFontSize("9pt").run();
    },
    isActive: (editor) => {
      return editor.isActive("textStyle", { fontSize: "9pt" });
    },
  },
  {
    text: "10",
    class: "tt-full tt-button",
    value: "10pt",
    handler: ({ editor /* option, element, */ }) => {
      editor.chain().focus().setFontSize("10pt").run();
    },
    isActive: (editor) => {
      return editor.isActive("textStyle", { fontSize: "10pt" });
    },
  },
  {
    text: "11",
    class: "tt-full tt-button",
    value: "11pt",
    handler: ({ editor /* option, element, */ }) => {
      editor.chain().focus().setFontSize("11pt").run();
    },
    isActive: (editor) => {
      return editor.isActive("textStyle", { fontSize: "11pt" });
    },
  },
  {
    text: "12",
    class: "tt-full tt-button",
    value: "12pt",
    handler: ({ editor /* option, element, */ }) => {
      editor.chain().focus().setFontSize("12pt").run();
    },
    isActive: (editor) => {
      return editor.isActive("textStyle", { fontSize: "12pt" });
    },
  },
  {
    text: "13",
    class: "tt-full tt-button",
    value: "13pt",
    handler: ({ editor /* option, element, */ }) => {
      editor.chain().focus().setFontSize("13pt").run();
    },
    isActive: (editor) => {
      return editor.isActive("textStyle", { fontSize: "13pt" });
    },
  },
  {
    text: "14",
    class: "tt-full tt-button",
    value: "14pt",
    handler: ({ editor /* option, element, */ }) => {
      editor.chain().focus().setFontSize("14pt").run();
    },
    isActive: (editor) => {
      return editor.isActive("textStyle", { fontSize: "14pt" });
    },
  },
  {
    text: "15",
    class: "tt-full tt-button",
    value: "15pt",
    handler: ({ editor /* option, element, */ }) => {
      editor.chain().focus().setFontSize("15pt").run();
    },
    isActive: (editor) => {
      return editor.isActive("textStyle", { fontSize: "15pt" });
    },
  },
  {
    text: "16",
    class: "tt-full tt-button",
    value: "16pt",
    handler: ({ editor /* option, element, */ }) => {
      editor.chain().focus().setFontSize("16pt").run();
    },
    isActive: (editor) => {
      return editor.isActive("textStyle", { fontSize: "16pt" });
    },
  },
  {
    text: "18",
    class: "tt-full tt-button",
    value: "18pt",
    handler: ({ editor /* option, element, */ }) => {
      editor.chain().focus().setFontSize("18pt").run();
    },
    isActive: (editor) => {
      return editor.isActive("textStyle", { fontSize: "18pt" });
    },
  },
  {
    text: "20",
    class: "tt-full tt-button",
    value: "20pt",
    handler: ({ editor /* option, element, */ }) => {
      editor.chain().focus().setFontSize("20pt").run();
    },
    isActive: (editor) => {
      return editor.isActive("textStyle", { fontSize: "20pt" });
    },
  },
  {
    text: "22",
    class: "tt-full tt-button",
    value: "22pt",
    handler: ({ editor /* option, element, */ }) => {
      editor.chain().focus().setFontSize("22pt").run();
    },
    isActive: (editor) => {
      return editor.isActive("textStyle", { fontSize: "22pt" });
    },
  },
  {
    text: "24",
    class: "tt-full tt-button",
    value: "24pt",
    handler: ({ editor /* option, element, */ }) => {
      editor.chain().focus().setFontSize("24pt").run();
    },
    isActive: (editor) => {
      return editor.isActive("textStyle", { fontSize: "24pt" });
    },
  },
  {
    text: "30",
    class: "tt-full tt-button",
    value: "30pt",
    handler: ({ editor /* option, element, */ }) => {
      editor.chain().focus().setFontSize("30pt").run();
    },
    isActive: (editor) => {
      return editor.isActive("textStyle", { fontSize: "30pt" });
    },
  },
  {
    text: "36",
    class: "tt-full tt-button",
    value: "36pt",
    handler: ({ editor /* option, element, */ }) => {
      editor.chain().focus().setFontSize("36pt").run();
    },
    isActive: (editor) => {
      return editor.isActive("textStyle", { fontSize: "36pt" });
    },
  },
];

export class FontSizePicker extends Picker {
  constructor(toolbar) {
    super(toolbar);

    this.fillAttributes({
      class: "tt-font-size",
    });
    this.label = this.buildButtonLabel({
      text: FONT_SIZES[0].text,
      tip: "字号",
    });
    this.buildPanel(FONT_SIZES);

    this.selectItem({ text: FONT_SIZES[0].labelAlias });
  }

  update() {
    const editor = this.toolbar.editor();
    const { fontSize } = editor.getAttributes("textStyle");

    if (!fontSize) {
      this.selectItem({ text: FONT_SIZES[0].labelAlias });
    } else {
      let index = 1;
      for (; index < FONT_SIZES.length; index++) {
        if (fontSize === FONT_SIZES[index].value) {
          this.selectItem({ text: FONT_SIZES[index].text });
          break;
        }
      }

      if (index >= FONT_SIZES.length) {
        this.selectItem({ text: FONT_SIZES[0].labelAlias });
      }
    }

    super.update();
  }
}

export const COLORS = [
  { color: "#ffffff", tip: "纯白", showBorder: true },
  { color: "#000000", tip: "纯黑" },
  { color: "#ff0000", tip: "红" },
  { color: "#ff7800", tip: "橙" },
  { color: "#ffd900", tip: "黄" },
  { color: "#a3e043", tip: "葱绿" },
  { color: "#37d9f0", tip: "湖蓝" },
  { color: "#4da8ee", tip: "天色" },
  { color: "#956fe7", tip: "藤紫" },
  { color: "#f3f3f4", tip: "白练" },
  { color: "#cccccc", tip: "白鼠" },
  { color: "#fef2f0", tip: "樱" },
  { color: "#fef5e7", tip: "缟" },
  { color: "#fefcd9", tip: "练" },
  { color: "#edf6e8", tip: "芽" },
  { color: "#e6fafa", tip: "水" },
  { color: "#ebf4fc", tip: "缥" },
  { color: "#f0edf6", tip: "丁香" },
  { color: "#d7d8d9", tip: "灰青" },
  { color: "#a5a5a5", tip: "鼠" },
  { color: "#fbd4d0", tip: "虹" },
  { color: "#ffd7b9", tip: "落柿" },
  { color: "#f9eda6", tip: "花叶" },
  { color: "#d4e9d6", tip: "白绿" },
  { color: "#c7e6ea", tip: "天青" },
  { color: "#cce0f1", tip: "天空" },
  { color: "#dad5e9", tip: "水晶" },
  { color: "#7b7f83", tip: "薄钝" },
  { color: "#494949", tip: "墨" },
  { color: "#ee7976", tip: "甚三红" },
  { color: "#faa573", tip: "珊瑚" },
  { color: "#e6b322", tip: "金" },
  { color: "#98c091", tip: "薄青" },
  { color: "#79c6cd", tip: "白群" },
  { color: "#6eaad7", tip: "薄花" },
  { color: "#9c8ec1", tip: "紫苑" },
  { color: "#41464b", tip: "石墨" },
  { color: "#333333", tip: "黑" },
  { color: "#be1a1d", tip: "绯红" },
  { color: "#b95514", tip: "棕黄" },
  { color: "#ad720e", tip: "土黄" },
  { color: "#1c7231", tip: "苍翠" },
  { color: "#1c7892", tip: "孔雀" },
  { color: "#19439c", tip: "琉璃" },
  { color: "#511b78", tip: "青莲" },
];

export class ColorPicker extends Picker {
  constructor(toolbar) {
    super(toolbar);

    this.fillAttributes({
      class: "tt-color",
    });
    this.label = this.buildButtonLabel({
      icon: Icons.fontColor,
      tip: "颜色",
    });
    this.buildPanel(COLORS);
  }

  /**
   *
   * @param {*} options
   *   [
   *     {
   *       color：颜色
   *       class: String
   *       showBorder: 显示边框
   *       tip: 提示
   *       handler: 处理函数
   *       isActive: Function
   *     }
   *   ]
   */
  buildOptions(options) {
    this.buildRemoveItem();

    const grid = document.createElement("div");
    grid.classList.add("tt-color-panel-grid");

    options.forEach((option) => {
      const item = this.buildItem(option);
      grid.appendChild(item);
    });

    this.panel.appendChild(grid);
  }

  buildRemoveItem() {
    const row = document.createElement("div");

    const item = document.createElement("button");
    item.tabIndex = "0";
    item.setAttribute("type", "button");
    item.setAttribute("class", "tt-button tt-color-panel-remove-color");

    const icon = document.createElement("span");
    icon.classList.add("tt-color-panel-remove-icon");
    icon.innerHTML = Icons.eraser;
    item.appendChild(icon);

    const text = document.createElement("span");
    text.innerHTML = "移除颜色";
    item.appendChild(text);

    const option = {
      handler: ({ editor }) => {
        editor.chain().focus().unsetColor().run();
      },
    };
    this.addOptionClickEventListener(item, option);
    this.addOptionKeyboardEventListener(item, option);

    row.appendChild(item);
    this.panel.appendChild(row);
  }

  buildItem(option) {
    const item = document.createElement("button");
    item.tabIndex = "0";
    item.setAttribute("type", "button");
    item.setAttribute("class", "tt-button tt-color-panel-grid-item");
    item.style.backgroundColor = option.color;

    if (option.tip) {
      item.setAttribute("title", option.tip);
    }

    if (option.showBorder) {
      item.classList.add("tt-color-panel-grid-item-bordered");
    }

    option.handler = ({ editor /* option, element, */ }) => {
      editor.chain().focus().setColor(option.color).run();
    };
    option.isActive = (editor) => {
      return editor.isActive("textStyle", { color: option.color });
    };

    this.addOptionClickEventListener(item, option);
    this.addOptionKeyboardEventListener(item, option);

    this.actives.push({
      element: item,
      isActive: option.isActive,
    });
    return item;
  }

  selectItem() {}
}

export const BACKGROUD_COLORS = [
  { color: "#ffffff", tip: "纯白", showBorder: true },
  { color: "#000000", tip: "纯黑" },
  { color: "#ff0000", tip: "红" },
  { color: "#ff7800", tip: "橙" },
  { color: "#ffd900", tip: "黄" },
  { color: "#a3e043", tip: "葱绿" },
  { color: "#37d9f0", tip: "湖蓝" },
  { color: "#4da8ee", tip: "天色" },
  { color: "#956fe7", tip: "藤紫" },
  { color: "#f3f3f4", tip: "白练" },
  { color: "#cccccc", tip: "白鼠" },
  { color: "#fef2f0", tip: "樱" },
  { color: "#fef5e7", tip: "缟" },
  { color: "#fefcd9", tip: "练" },
  { color: "#edf6e8", tip: "芽" },
  { color: "#e6fafa", tip: "水" },
  { color: "#ebf4fc", tip: "缥" },
  { color: "#f0edf6", tip: "丁香" },
  { color: "#d7d8d9", tip: "灰青" },
  { color: "#a5a5a5", tip: "鼠" },
  { color: "#fbd4d0", tip: "虹" },
  { color: "#ffd7b9", tip: "落柿" },
  { color: "#f9eda6", tip: "花叶" },
  { color: "#d4e9d6", tip: "白绿" },
  { color: "#c7e6ea", tip: "天青" },
  { color: "#cce0f1", tip: "天空" },
  { color: "#dad5e9", tip: "水晶" },
  { color: "#7b7f83", tip: "薄钝" },
  { color: "#494949", tip: "墨" },
  { color: "#ee7976", tip: "甚三红" },
  { color: "#faa573", tip: "珊瑚" },
  { color: "#e6b322", tip: "金" },
  { color: "#98c091", tip: "薄青" },
  { color: "#79c6cd", tip: "白群" },
  { color: "#6eaad7", tip: "薄花" },
  { color: "#9c8ec1", tip: "紫苑" },
  { color: "#41464b", tip: "石墨" },
  { color: "#333333", tip: "黑" },
  { color: "#be1a1d", tip: "绯红" },
  { color: "#b95514", tip: "棕黄" },
  { color: "#ad720e", tip: "土黄" },
  { color: "#1c7231", tip: "苍翠" },
  { color: "#1c7892", tip: "孔雀" },
  { color: "#19439c", tip: "琉璃" },
  { color: "#511b78", tip: "青莲" },
];

export class BackgroundColorPicker extends Picker {
  constructor(toolbar) {
    super(toolbar);

    this.fillAttributes({
      class: "tt-background-color",
    });
    this.label = this.buildButtonLabel({
      icon: Icons.fontBackground,
      tip: "背景色",
    });
    this.buildPanel(BACKGROUD_COLORS);
  }

  /**
   *
   * @param {*} options
   *   [
   *     {
   *       color：颜色
   *       class: String
   *       showBorder: 显示边框
   *       tip: 提示
   *       handler: 处理函数
   *       isActive: Function
   *     }
   *   ]
   */
  buildOptions(options) {
    this.buildRemoveItem();

    const grid = document.createElement("div");
    grid.classList.add("tt-color-panel-grid");

    options.forEach((option) => {
      const item = this.buildItem(option);
      grid.appendChild(item);
    });

    this.panel.appendChild(grid);
  }

  buildRemoveItem() {
    const row = document.createElement("div");

    const item = document.createElement("button");
    item.tabIndex = "0";
    item.setAttribute("type", "button");
    item.setAttribute("class", "tt-button tt-color-panel-remove-color");

    const icon = document.createElement("span");
    icon.classList.add("tt-color-panel-remove-icon");
    icon.innerHTML = Icons.eraser;
    item.appendChild(icon);

    const text = document.createElement("span");
    text.innerHTML = "移除背景色";
    item.appendChild(text);

    const option = {
      handler: ({ editor }) => {
        editor.chain().focus().unsetBackgroundColor().run();
      },
    };
    this.addOptionClickEventListener(item, option);
    this.addOptionKeyboardEventListener(item, option);

    row.appendChild(item);
    this.panel.appendChild(row);
  }

  buildItem(option) {
    const item = document.createElement("button");
    item.tabIndex = "0";
    item.setAttribute("type", "button");
    item.setAttribute("class", "tt-button tt-color-panel-grid-item");
    item.style.backgroundColor = option.color;

    if (option.tip) {
      item.setAttribute("title", option.tip);
    }

    if (option.showBorder) {
      item.classList.add("tt-color-panel-grid-item-bordered");
    }

    option.handler = ({ editor /* option, element, */ }) => {
      editor.chain().focus().setBackgroundColor(option.color).run();
    };
    option.isActive = (editor) => {
      return editor.isActive("textStyle", { backgroundColor: option.color });
    };

    this.addOptionClickEventListener(item, option);
    this.addOptionKeyboardEventListener(item, option);

    this.actives.push({
      element: item,
      isActive: option.isActive,
    });
    return item;
  }

  selectItem() {}
}

export const TABLE_ROW_NUM = 8;
export const TABLE_COLUMN_NUM = 8;

export class TablePicker extends Picker {
  constructor(toolbar) {
    super(toolbar);

    this.fillAttributes({
      class: "tt-table",
    });
    this.label = this.buildButtonLabel({
      icon: Icons.table.table,
      tip: "表格",
    });
    this.buildPanel();
  }

  buildOptions() {
    const optionGridCells = [];

    const optionGrid = document.createElement("div");
    optionGrid.classList.add("tt-table-panel-grid");

    this.optionNote = document.createElement("span");
    this.optionNote.classList.add("tt-table-panel-note");
    this.optionNote.tabIndex = "-1";
    this.optionNote.innerHTML = "0 x 0";

    for (let row = 0; row < TABLE_ROW_NUM; row += 1) {
      const rowCells = [];
      for (let col = 0; col < TABLE_COLUMN_NUM; col += 1) {
        const cell = this.buildItem(row, col);
        rowCells.push(cell);
        optionGrid.appendChild(cell);
      }

      optionGridCells.push(rowCells);
    }

    optionGrid.addEventListener("mouseleave", () => {
      optionGridCells.forEach((rowCells) => {
        rowCells.forEach((cell) => {
          cell.classList.remove("is-active");
        });
      });
      this.activeCell = null;
      this.optionNote.innerHTML = "0 x 0";
    });

    this.activeCell = null;
    this.optionGridCells = optionGridCells;
    this.optionGrid = optionGrid;

    this.panel.appendChild(optionGrid);
    this.panel.appendChild(this.optionNote);
  }

  buildItem(row, col) {
    const cell = document.createElement("span");
    cell.tabIndex = "-1";
    cell.setAttribute("role", "button");
    cell.classList.add("tt-table-panel-cell");
    cell.setAttribute("row", row);
    cell.setAttribute("col", col);

    cell.addEventListener("click", () => {
      const editor = this.toolbar.editor();

      // 默认不带标题行
      editor.commands.insertTable({
        rows: row + 1,
        cols: col + 1,
        withHeaderRow: false,
      });

      this.close();
    });
    cell.addEventListener("mouseenter", () => {
      this.activeCell = { row, col, cell };

      for (let ri = 0; ri < TABLE_ROW_NUM; ri += 1) {
        const rowCells = this.optionGridCells[ri];
        for (let ci = 0; ci < TABLE_COLUMN_NUM; ci += 1) {
          const opcell = rowCells[ci];
          opcell.classList.toggle("is-active", ri <= row && ci <= col);
        }
      }

      this.optionNote.innerHTML = `${row + 1} x ${col + 1}`;
    });

    return cell;
  }

  selectItem() {}

  update() {}
}

export const TOOLBAR_DEFINES = {
  bold: BoldButton,
  italic: ItalicButton,
  underline: UnderlineButton,
  strike: StrikeButton,
  heading: HeadingPicker,
  align: AlignPicker,
  image: ImagePicker,
  "font-family": FontFamilyPicker,
  "font-size": FontSizePicker,
  color: ColorPicker,
  "background-color": BackgroundColorPicker,
  "list-ordered": OrderedListButton,
  "list-bullet": BulletListButton,
  "list-task": TaskListButton,
  superscript: SuperscriptButton,
  subscript: SubscriptButton,
  blockquote: BlockquoteButton,
  indent: IndentButton,
  outdent: OutdentButton,
  link: LinkButton,
  "horizontal-line": HorizontalLineButton,
  redo: RedoButton,
  undo: UndoButton,
  table: TablePicker,
};

export class Toolbar {
  /**
   *
   * @param {*} ttEditor
   * @param {*} options
   *   {
   *     container
   *     items
   *     image: {
   *       options: []
   *       upload(files, editor): Function
   *       insertNetResourceImage(): Function
   *     }
   *   }
   */
  constructor(ttEditor, options) {
    this.ttEditor = ttEditor;
    this.options = options;
    this.tools = [];
    this.buttons = [];
    this.pickers = [];

    if (typeof options.container === "string") {
      this.container = document.querySelector(options.container);
      this.addTools(this.container, options.items);
    } else if (options.container instanceof HTMLElement) {
      this.container = options.container;
      this.addTools(this.container, options.items);
    } else {
      this.container = document.createElement("div");
      this.addTools(this.container, options.items);
      ttEditor.$refs.ttContainer.insertBefore(
        this.container,
        ttEditor.$refs.ttContent.$el
      );
    }

    this.container.classList.add("tt-toolbar");

    const clickListener = (e) => {
      this.pickers.forEach((picker) => {
        if (picker.isExpanded() && !picker.container.contains(e.target)) {
          picker.close();
        }
      });
    };
    document.body.addEventListener("click", clickListener);
  }

  addTools(container, items) {
    if (items) {
      items.forEach((item /* index, array */) => {
        if (item === "|") {
          this.addSeparator(container);
        } else {
          const toolClass = TOOLBAR_DEFINES[item];
          const itemOptions = this.options[item];
          const tool = new toolClass(this, itemOptions);
          if (tool instanceof Button) {
            this.addButton(container, tool);
          } else {
            this.addPicker(container, tool);
          }
        }
      });
    } else {
      this.addDefaultTools(container);
    }
  }

  addDefaultTools(container) {
    this.addPicker(container, new HeadingPicker(this));
    this.addPicker(container, new FontFamilyPicker(this));
    this.addPicker(container, new FontSizePicker(this));
    this.addSeparator(container);
    this.addButton(container, new BoldButton(this));
    this.addButton(container, new ItalicButton(this));
    this.addButton(container, new UnderlineButton(this));
    this.addButton(container, new StrikeButton(this));
    this.addPicker(container, new ColorPicker(this));
    this.addPicker(container, new BackgroundColorPicker(this));
    this.addSeparator(container);
    this.addButton(container, new OrderedListButton(this));
    this.addButton(container, new BulletListButton(this));
    this.addButton(container, new TaskListButton(this));
    this.addButton(container, new IndentButton(this));
    this.addButton(container, new OutdentButton(this));
    this.addPicker(container, new AlignPicker(this));
    this.addButton(container, new SuperscriptButton(this));
    this.addButton(container, new SubscriptButton(this));
    this.addSeparator(container);
    this.addPicker(container, new ImagePicker(this, this.options["image"]));
    this.addPicker(container, new TablePicker(this));
    this.addButton(container, new LinkButton(this));
    this.addButton(container, new BlockquoteButton(this));
    this.addButton(container, new HorizontalLineButton(this));
    this.addSeparator(container);
    this.addButton(container, new UndoButton(this));
    this.addButton(container, new RedoButton(this));
  }

  addButton(container, button) {
    this.tools.push(button);
    this.buttons.push(button);
    container.appendChild(button.button);
  }

  addPicker(container, picker) {
    this.tools.push(picker);
    this.pickers.push(picker);
    container.appendChild(picker.container);
  }

  addSeparator(container) {
    const sep = new Separator();
    container.appendChild(sep.container);
  }

  editor() {
    return this.ttEditor.editor;
  }

  update() {
    this.tools.forEach((tool /* index, array */) => {
      tool.update();
    });
  }
}
