/*
 * A JavaScript implementation of the RSA Data Security, Inc. MD5 Message
 * Digest Algorithm, as defined in RFC 1321.
 * Version 1.1 Copyright (C) Paul Johnston 1999 - 2002.
 * Code also contributed by Greg Holt
 * See http://pajhome.org.uk/site/legal.html for details.
 */

const ReportModels = {
  txt: {
    type: "txt",
    icon: "doc",
    iconClass: "",
    aTarget: "_blank",
  },
  doc: {
    type: "doc",
    icon: "word",
    iconClass: "",
    aTarget: "_self",
  },
  docx: {
    type: "docx",
    icon: "word",
    iconClass: "",
    aTarget: "_self",
  },
  pdf: {
    type: "pdf",
    icon: "pdf",
    iconClass: "",
    aTarget: "_blank",
  },
  rtf: {
    type: "rtf",
    icon: "doc",
    iconClass: "",
    aTarget: "_blank",
  },
};

/**
 * 是否支持的文档类型
 * @param {*} type
 */
function isSupportType(type) {
  const rtype = ReportModels[type];
  return rtype ? true : false;
}

/**
 * 获取文档图标
 * @param {*} type
 */
function getModel(type) {
  const model = ReportModels[type];
  if (model) {
    return model;
  }
  return ReportModels["txt"];
}

/**
 * 获取文档图标
 * @param {*} type
 */
function getIcon(type) {
  let model = ReportModels[type];
  if (!model) {
    model = ReportModels["txt"];
  }
  return model.icon;
}

function isRTF(type) {
  return type === ReportModels.rtf.type;
}

export default {
  ReportModels,
  isSupportType,
  getModel,
  getIcon,
  isRTF,
};
