<!--
 * URL查询参数:
 *   i: id
 *   n: name
 *   t: type[op], type: 0/undefined - 形象报告, 1 - 形象报告模板; op: 0/undefined - add, 1 - edit, 2 - readonly;
 *   title: 标题
-->

<template>
  <div class="ir-container">
    <div
      ref="irToolbarContainer"
      class="ir-toolbar-container"
      :class="{ 'is-show': editable }"
    >
      <div class="ir-toolbar">
        <button
          tabindex="0"
          type="button"
          class="tt-button ir-save"
          :class="{
            is_enable: this.$refs.irEditor && this.$refs.irEditor.changed,
          }"
          @click="onSave"
        >
          保存
        </button>
        <div class="tt-separator"></div>
      </div>
    </div>
    <tiptap-editor
      ref="irEditor"
      :editable="editable"
      :options="editorOptions"
      :content="content"
    />
  </div>
</template>

<script>
import TiptapEditor from "../../components/tiptap/TiptapEditor.vue";
import AliOSS from "../../api/alioss";
import Ocean from "../../api/ocean";
import AppConfig from "../../config";

export default {
  components: {
    TiptapEditor,
  },
  data() {
    return {
      editor: null,
      editable: false,
      editorOptions: {
        toolbar: {
          container: ".ir-toolbar",
          image: {
            upload: (imageFiles, editor) => {
              let images = [];

              for (let file of imageFiles) {
                if (file.size > AppConfig.imageReport.maxImageSize) {
                  this.$message.error(AppConfig.imageReport.maxImageSizeError);
                  return;
                }

                let imgOssKey = AliOSS.getImageKey(
                  AliOSS.getImageName(file.name),
                  this.id
                );
                images.push(imgOssKey);
              }

              Ocean.AddRtfImages({
                rid: this.id,
                images,
              })
                .then(async () => {
                  const client = await AliOSS.getClient();
                  for (let idx = 0; idx < images.length; idx++) {
                    // 第三个参数可以自定义headers
                    await client.put(images[idx], imageFiles[idx]);

                    let imgUrl = AliOSS.getCdnUrl(images[idx]);
                    editor.commands.setImage({ src: imgUrl });
                  }
                })
                .catch(() => {
                  this.$message.error("部分图片上传失败");
                });
            },
          },
        },
      },
      id: 0,
      name: "",
      type: 0,
      op: 0, // 0 - add, 1 - edit, 2 - readonly;
      content: "",
    };
  },
  async created() {
    if (!this.readUrlQuery()) return;

    if (this.op > 0) {
      await this.downloadReport();
    } else {
      this.$nextTick(() => {
        this.editable = this.op !== 2;
        this.$refs.irEditor.initialize();
      });
    }
  },
  beforeRouteLeave(to, from, next) {
    if (this.$refs.irEditor.changed) {
      const answer = window.confirm("要放弃此次修改吗？");
      if (answer) {
        next();
      } else {
        next(false);
      }
    }
  },
  mounted() {
    window.onresize = () => {
      this.setEditorHeight();
    };
    this.setEditorHeight();

    window.addEventListener("scroll", this.handleScroll, true);

    // 不同的浏览器刷新和关闭时对onbeforeunload()和onunload()的执行步骤是不同的。
    // ie、chrome、360：
    //   页面加载时执行unload()；
    //   刷新时先执行onbeforeload()，新页面即将替换旧页面时onunload()，最后unload();
    //   关闭时执行onbeforeload()，再执行onunload().
    // firefox:
    //   刷新时只执行onunload();
    //   关闭时只执行onbeforeunload()。

    const onclose = (e) => {
      if (this.$refs.irEditor.changed) {
        var evt = window.event || e;
        evt.returnValue = "要放弃此次修改吗？";
      }
    };
    window.onbeforeunload = onclose;
    window.onunload = onclose;
  },
  methods: {
    readUrlQuery() {
      let i = this.$route.query.i ? this.$route.query.i.trim() : "";
      let n = this.$route.query.n ? this.$route.query.n.trim() : "";
      let t = this.$route.query.t ? this.$route.query.t.trim() : "";

      if (this.$route.query.title) {
        document.title = this.$route.query.title;
      }

      if (!i || !n || !t) return false;

      this.id = parseInt(i);
      this.name = n;

      if (t.length > 1) {
        this.type = parseInt(t.charAt(0));
        let op = parseInt(t.charAt(1));
        this.op = op ? op : 0;
      } else {
        this.type = parseInt(t);
      }

      return true;
    },
    async downloadReport() {
      let client = null;
      try {
        client = await AliOSS.getClient();
        const result = await client.get(
          AliOSS.getImageReportKey(this.name, this.id)
        );
        this.content = result.content.toString();
        this.$nextTick(() => {
          this.editable = this.op !== 2;
          this.$refs.irEditor.initialize();
        });
      } catch (err) {
        // 404（目标Object不存在），参考https://help.aliyun.com/document_detail/31980.htm?spm=a2c4g.11186623.0.0.b7ff7dbbVRQuEJ#reference-ccf-rgd-5db
        if (err && err.status === 404) {
          this.$nextTick(() => {
            this.editable = this.op !== 2;
            this.$refs.irEditor.initialize();
          });
        } else {
          this.$message.error("无法读取报告");
        }
      }
    },
    setEditorHeight() {
      let exHeight = 0;
      if (this.$refs.irToolbarContainer) {
        const toolbarRect =
          this.$refs.irToolbarContainer.getBoundingClientRect();
        exHeight += toolbarRect.height;
      }
      this.$refs.irEditor.setEditorHeight(
        document.documentElement.clientHeight - exHeight
      );
    },
    handleScroll(/* e */) {
      // 滚动距离超过<tiptap-editor>的padding-top（3px）
      if (this.$refs.irEditor.$el.scrollTop > 3) {
        this.$refs.irToolbarContainer.classList.add("ir-toolbar-shadow");
      } else {
        this.$refs.irToolbarContainer.classList.remove("ir-toolbar-shadow");
      }
    },
    async onSave() {
      if (!this.editable || !this.$refs.irEditor.changed) return;

      const editor = this.$refs.irEditor.editor;

      try {
        const client = await AliOSS.getClient();

        const data = new AliOSS.OSS.Buffer(editor.getHTML());
        await client.put(AliOSS.getImageReportKey(this.name, this.id), data);

        this.$refs.irEditor.changed = false;

        if (this.type === 1) {
          if (this.id) {
            let params = {
              id: this.id,
              oss_updated: true,
            };
            Ocean.updateTeamImgrptTemplate(params)
              .then((/* res */) => {})
              .catch((/* res */) => {});
          }
        } else if (this.id) {
          let params2 = {
            id: this.id,
            report_updated: true,
          };
          Ocean.updateOrderImgRpt(params2)
            .then((/* res */) => {})
            .catch((/* res */) => {});
        }

        this.$message.success("保存完成");
      } catch (e) {
        this.$message.error("保存失败，请重试");
      }
    },
  },
};
</script>

<style scoped lang="scss">
.ir-container {
  height: 100vh;
  box-sizing: border-box;
  background-color: #f7f7f7;

  // This element is a flex container for easier rendering.
  display: flex;
  flex-flow: column nowrap;
}

.ir-toolbar-container {
  display: none;

  padding-top: 5px;
  box-sizing: border-box;

  // Make sure the toolbar container is always above the editable.
  z-index: 99;

  border: none;

  // Create the illusion of the toolbar floating over the editable.
  // box-shadow: 0 0 5px hsla( 0,0%,0%,.2 );

  // transition: height .2s ease-in;
}
.ir-toolbar-container.is-show {
  display: block;
}

.ir-toolbar {
  margin: 0 auto;
  display: flex;
  flex-wrap: wrap;
}

.ir-save {
  height: 26px;
  line-height: 26px;
  padding-left: 10px;
  padding-right: 10px;
  border: 1px solid #e6e6e6;
  border-radius: 16px;
  box-sizing: border-box;
  margin: 1px 10px 1px 0;
  text-align: center;
  color: #d9d9d9;
  box-shadow: rgba(0, 0, 0, 0.11) 0px 1px 1px 0px;
}
.ir-save.is_enable {
  border-color: #cfcfcf;
  color: inherit;
}

.ir-toolbar-shadow {
  box-shadow: 0 3px 2px rgba(20, 20, 20, 0.12);
}

.tt-container {
  overflow-y: auto;
}
</style>
