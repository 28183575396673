<template>
  <div class="container">
    <h2 class="title">服务产品管理</h2>
    <div style="padding: 10px 0; max-width: 600px">
      <el-form
        ref="serviceFilter"
        :model="serviceFilter"
        label-width="100px"
        size="small"
      >
        <el-form-item label="服务产品名">
          <el-input
            v-model="serviceFilter.name"
            placeholder="搜索服务产品名"
            :maxlength="10"
          />
        </el-form-item>
        <el-form-item label="状态">
          <el-dropdown @command="onFilterStateSelected">
            <el-button>
              {{ serviceFilter.stateName }}
              <i class="el-icon-arrow-down el-icon--right"></i>
            </el-button>
            <el-dropdown-menu slot="dropdown">
              <el-dropdown-item command="">全部</el-dropdown-item>
              <el-dropdown-item command="0">正常</el-dropdown-item>
              <el-dropdown-item command="1">已下架</el-dropdown-item>
            </el-dropdown-menu>
          </el-dropdown>
        </el-form-item>
        <el-form-item label="排序">
          <el-dropdown @command="onFilterSortSelected">
            <el-button>
              {{ serviceFilter.sortName }}
              <i class="el-icon-arrow-down el-icon--right"></i>
            </el-button>
            <el-dropdown-menu slot="dropdown">
              <el-dropdown-item command="1">创建时间从晚到早</el-dropdown-item>
              <el-dropdown-item command="2">创建时间从早到晚</el-dropdown-item>
            </el-dropdown-menu>
          </el-dropdown>
        </el-form-item>
        <el-form-item size="large">
          <el-button type="primary" @click="onServiceSearchClick"
            >确定</el-button
          >
          <el-button @click="onServiceSearchReset">重置</el-button>
        </el-form-item>
      </el-form>
    </div>
    <el-table
      :data="services"
      style="width: 100%; min-width: 1000px"
      :header-cell-style="{ 'background-color': '#EFF3F8' }"
    >
      <el-table-column label="服务产品名">
        <template slot-scope="scope">
          <span class="wrs-flexrow--center">
            <el-image
              :src="scope.row.imgThumbnail"
              class="serv-img"
              fit="cover"
              lazy
            >
              <span slot="placeholder" class="serv-img-loading">
                <i class="el-icon-loading"></i> 加载中
              </span>
            </el-image>
            <span class="serv-name wrs-ellipsis">{{ scope.row.name }}</span>
          </span>
        </template>
      </el-table-column>
      <el-table-column label="形象报告" width="100">
        <template slot-scope="scope">{{
          scope.row.imgreport ? "是" : "否"
        }}</template>
      </el-table-column>
      <el-table-column label="单品" width="100">
        <template slot-scope="scope">{{
          scope.row.clothes ? "是" : "否"
        }}</template>
      </el-table-column>
      <el-table-column label="搭配" width="100">
        <template slot-scope="scope">{{
          scope.row.dapei ? "是" : "否"
        }}</template>
      </el-table-column>
      <el-table-column label="咨询" width="100">
        <template slot-scope="scope">{{
          scope.row.zixun ? "是" : "否"
        }}</template>
      </el-table-column>
      <el-table-column
        prop="stateName"
        label="状态"
        width="100"
      ></el-table-column>
      <el-table-column
        prop="create_time"
        label="创建时间"
        width="170"
      ></el-table-column>
      <el-table-column label="操作" width="140">
        <template slot-scope="scope">
          <el-button
            @click="onEditDescClick(scope.$index, scope.row)"
            type="text"
            size="small"
            >编辑描述</el-button
          >
        </template>
      </el-table-column>
    </el-table>
    <el-pagination
      @current-change="onServicePageChange"
      :current-page="servicePage"
      :page-size="10"
      :page-sizes="[10]"
      layout="sizes, prev, next, jumper"
      class="services-pagination"
    >
    </el-pagination>
  </div>
</template>

<script>
import Ocean from "../../../api/ocean";
import AliOSS from "../../../api/alioss";
import UI from "../../../util/ui";
import ViewContext from "../../../viewContext";

export default {
  data() {
    return {
      /**
       * 服务产品列表。
       * 格式: [
       *   {
       *     id:1,
       *     name:'', //服务产品名
       *     ---------------------------------
       *     imgThumbnail
       *     stateName
       *   }
       * ]
       */
      services: [],
      servicePage: 1,

      serviceFilter: {
        name: "",
        serviceState: null,
        stateCommand: "",
        stateName: "全部",
        sortCommand: "1",
        sortName: "创建时间从晚到早",
      },
    };
  },
  mounted() {
    this.getServices();
  },
  methods: {
    /**
     * 获取服务产品列表
     */
    getServices: function () {
      const team = ViewContext.getTeam();
      if (!team) return;

      const { servicePage, serviceFilter } = this;
      let params = {
        team_id: team.id,
        fields: [
          "id",
          "name",
          "imgreport",
          "clothes",
          "dapei",
          "zixun",
          "state",
          "img",
          "create_time",
          "description",
        ],
        sort_by: serviceFilter.sortCommand === "2" ? 2 : 1,
        offset: UI.computePagingOffset(servicePage, 10),
        num: 10,
      };

      if (serviceFilter.serviceState || serviceFilter.serviceState === 0) {
        params.state = serviceFilter.serviceState;
      }

      if (serviceFilter.name) {
        params.name = serviceFilter.name;
      }

      Ocean.getBizServices(params)
        .then((res) => {
          let services = [];
          let rservices = res.data.services;
          if (rservices) {
            for (let serv of rservices) {
              serv.imgThumbnail = serv.img
                ? AliOSS.getImageThumbnailCdnUrl(serv.img)
                : "";
              serv.stateName = serv.state === 0 ? "正常" : "已下架";
              services.push(serv);
            }
          }
          this.services = services;
        })
        .catch((err) => {
          this.$message.error(err.data.msg);
        });
    },

    onFilterStateSelected(command) {
      let { serviceFilter } = this;

      serviceFilter.stateCommand = command;
      switch (command) {
        case "0":
          serviceFilter.serviceState = 0;
          serviceFilter.stateName = "正常";
          break;
        case "1":
          serviceFilter.serviceState = 1;
          serviceFilter.stateName = "已下架";
          break;
        default:
          serviceFilter.serviceState = null;
          serviceFilter.stateName = "全部";
          break;
      }
    },

    onFilterSortSelected(command) {
      let { serviceFilter } = this;

      serviceFilter.sortCommand = command;
      switch (command) {
        case "1":
          serviceFilter.sortName = "创建时间从晚到早";
          break;
        case "2":
          serviceFilter.sortName = "创建时间从早到晚";
          break;
        default:
          break;
      }
    },

    onServiceSearchClick: function () {
      this.servicePage = 1;
      this.getServices();
    },

    onServiceSearchReset() {
      this.serviceFilter = {
        name: "",
        serviceState: null,
        stateCommand: "",
        stateName: "全部",
        sortCommand: "1",
        sortName: "创建时间从晚到早",
      };
    },

    onServicePageChange(pageNum) {
      this.servicePage = pageNum;
      this.getServices();
    },

    onEditDescClick(index, row) {
      if (row.description) {
        window.open(
          `/coa/bsdesc?i=${row.id}&d=${row.description}&t=1&title=${row.name}`,
          "_blank"
        );
      } else {
        const serv = this.services[index];
        let upServiceData = {
          id: serv.id,
          description: AliOSS.getRtfKey(serv.id),
        };

        Ocean.updateBizService(upServiceData)
          .then(() => {
            serv.description = upServiceData.description;

            window.open(
              `/coa/bsdesc?i=${serv.id}&d=${serv.description}&t=1&title=${serv.name}`,
              "_blank"
            );
          })
          .catch((err) => {
            this.$message.error(err.data.msg);
          });
      }
    },
  },
};
</script>

<style scoped lang="scss">
.container {
  width: 100%;
  padding: 50px 50px 60px 270px;
  box-sizing: border-box;
}

.title {
  padding-top: 10px;
  font-size: 24px;
  font-weight: 400;
  line-height: 1;
}

::v-deep .col-ellipsis .cell {
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}

.services-pagination {
  padding-top: 8px;
}

.serv-name {
  margin-left: 10px;
}

.serv-img {
  display: inline-block;
  width: 40px;
  height: 40px;
  border-radius: 3px;
  flex-shrink: 0;
}
.serv-img-loading {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 14px;
  color: #c0c4cc;
  vertical-align: middle;
  background: #f5f7fa;
}
</style>
