var Config = {
  oceanApi: "",
  aliossEndpoint: "",
  aliossEndpointCdn: "",
  aliossAccessKeyId: "",
  aliossRegion: "",
  aliossBucket: "",
};

function setProductionConfig() {
  Config.oceanApi = "https://api.wiruo.com/ocean";
  Config.aliossEndpoint = ""; // https://pflife1.oss-cn-hangzhou.aliyuncs.com
  Config.aliossEndpointCdn = "https://oss1cdn.wiruo.com";
  Config.aliossAccessKeyId = "LTAI5tLh6SwtWAZdRXEfLcvM";
  Config.aliossRegion = "oss-cn-hangzhou";
  Config.aliossBucket = "pflife1";
}

function setTrialConfig() {
  Config.oceanApi = "https://apidev.wiruo.com/ocean";
  Config.aliossEndpoint = ""; // https://pflifedev.oss-cn-hangzhou.aliyuncs.com
  Config.aliossEndpointCdn = "https://ossdev1cdn.wiruo.com";
  Config.aliossAccessKeyId = "LTAI5tLh6SwtWAZdRXEfLcvM";
  Config.aliossRegion = "oss-cn-hangzhou";
  Config.aliossBucket = "pflifedev";
}

function setDevConfig() {
  Config.oceanApi = "https://apidev.wiruo.com/oceand";
  Config.aliossEndpoint = ""; // https://pflifedev.oss-cn-hangzhou.aliyuncs.com
  Config.aliossEndpointCdn = "https://ossdev1cdn.wiruo.com";
  Config.aliossAccessKeyId = "LTAI5tLh6SwtWAZdRXEfLcvM";
  Config.aliossRegion = "oss-cn-hangzhou";
  Config.aliossBucket = "pflifedev";
}

switch (process.env.VUE_APP_ENVIRONMENT) {
  case "production":
    setProductionConfig();
    break;
  case "development":
    setDevConfig();
    break;
  case "test":
    setDevConfig();
    break;
  case "trial":
    setTrialConfig();
    break;
  default:
    break;
}

Config.image = {
  maxSize5M: 5 * 1024 * 1024,
  maxSize5MError: "图片大小不能超过5M",
};

Config.imageReport = {
  // 图片不大于5M
  maxImageSize: 5 * 1024 * 1024,
  maxImageSizeError: "图片大小不能超过5M",
};

Config.docSpace = {
  maxDocSize: 10 * 1024 * 1024,
  maxDocSizeError: "文档大小不能超过10M",

  whiteDocTypes: ["pdf", "doc", "docx"],
  docTypeAllowMessage: "文档允许pdf、doc、docx",
};

export default Config;
