<!--
* <svg-icon svg-name="ic_home_news" />
-->

<template>
  <svg class="svg-icon" :class="svgClass" aria-hidden="true">
    <use :xlink:href="useName" />
  </svg>
</template>

<script>
export default {
  name: "svg-icon",
  props: {
    svgName: {
      type: String,
      required: true,
    },
    svgClass: {
      type: String,
      default: "",
    },
  },
  computed: {
    // 与配置文件的配置格式一致
    useName() {
      return `#icon-${this.svgName}`;
    },
  },
};
</script>

<style>
.svg-icon {
  width: 16px;
  height: 16px;
  vertical-align: -0.15em;
  fill: currentColor;
  overflow: hidden;
}
</style>
