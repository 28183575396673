import Icons from "../../assets/icons";
import { Picker, Button, Separator } from "./toolbar";
import { isNodeSelection, posToDOMRect } from "@tiptap/core";

export const TABLE_COLUMN_MENUS = [
  {
    text: "左侧插入一列",
    class: "tt-full tt-button",
    handler: ({ editor /* option, element, */ }) => {
      editor.chain().focus().addColumnBefore().run();
    },
  },
  {
    text: "右侧插入一列",
    class: "tt-full tt-button",
    handler: ({ editor /* option, element, */ }) => {
      editor.chain().focus().addColumnAfter().run();
    },
  },
  {
    text: "删除本列",
    class: "tt-full tt-button",
    handler: ({ editor /* option, element, */ }) => {
      editor.chain().focus().deleteColumn().run();
    },
  },
];

export class TableColumnPicker extends Picker {
  constructor(toolbar) {
    super(toolbar);

    this.fillAttributes({
      class: "tt-table-column-menu",
    });
    this.label = this.buildButtonLabel({
      icon: Icons.table.column,
      tip: "列",
    });
    this.buildPanel(TABLE_COLUMN_MENUS);
  }

  selectItem() {}

  update() {}
}

export const TABLE_ROW_MENUS = [
  {
    text: "在上面插入一行",
    class: "tt-full tt-button",
    handler: ({ editor /* option, element, */ }) => {
      editor.chain().focus().addRowBefore().run();
    },
  },
  {
    text: "在下面插入一行",
    class: "tt-full tt-button",
    handler: ({ editor /* option, element, */ }) => {
      editor.chain().focus().addRowAfter().run();
    },
  },
  {
    text: "删除本行",
    class: "tt-full tt-button",
    handler: ({ editor /* option, element, */ }) => {
      editor.chain().focus().deleteRow().run();
    },
  },
  {
    text: "切换标题行",
    class: "tt-full tt-button",
    handler: ({ editor /* option, element, */ }) => {
      editor.chain().focus().toggleHeaderRow().run();
    },
  },
];

export class TableRowPicker extends Picker {
  constructor(toolbar) {
    super(toolbar);

    this.fillAttributes({
      class: "tt-table-row-menu",
    });
    this.label = this.buildButtonLabel({
      icon: Icons.table.row,
      tip: "行",
    });
    this.buildPanel(TABLE_ROW_MENUS);
  }

  selectItem() {}

  update() {}
}

export const TABLE_CELL_MENUS = [
  {
    text: "合并单元格",
    class: "tt-full tt-button",
    handler: ({ editor /* option, element, */ }) => {
      editor.chain().focus().mergeCells().run();
    },
  },
  {
    text: "拆分单元格",
    class: "tt-full tt-button",
    handler: ({ editor /* option, element, */ }) => {
      editor.chain().focus().splitCell().run();
    },
  },
  {
    text: "切换标题单元格",
    class: "tt-full tt-button",
    handler: ({ editor /* option, element, */ }) => {
      editor.chain().focus().toggleHeaderCell().run();
    },
  },
];

export class TableCellPicker extends Picker {
  constructor(toolbar) {
    super(toolbar);

    this.fillAttributes({
      class: "tt-table-cell-menu",
    });
    this.label = this.buildButtonLabel({
      icon: Icons.table.mergeCell,
      tip: "单元格",
    });
    this.buildPanel(TABLE_CELL_MENUS);
  }

  selectItem() {}

  update() {}
}

export const TABLE_PROPERTIES_MENUS = [
  {
    text: "删除表格",
    class: "tt-full tt-button",
    handler: ({ editor /* option, element, */ }) => {
      editor.chain().focus().deleteTable().run();
    },
  },
  {
    text: "修复表格",
    class: "tt-full tt-button",
    handler: ({ editor /* option, element, */ }) => {
      editor.chain().focus().fixTables().run();
    },
  },
];

export class TablePropertiesPicker extends Picker {
  constructor(toolbar) {
    super(toolbar);

    this.fillAttributes({
      class: "tt-table-properties-menu",
    });
    this.label = this.buildButtonLabel({
      icon: Icons.table.properties,
      tip: "表格",
    });
    this.buildPanel(TABLE_PROPERTIES_MENUS);
  }

  selectItem() {}

  update() {}
}

export class MenuBase {
  /**
   *
   * @param {*} ttEditor
   * @param {*} options
   *   {
   *     container
   *     items
   *   }
   * @param {*} tippyInstance
   */
  constructor(ttEditor, options, tippyInstance) {
    this.ttEditor = ttEditor;
    this.options = options;
    this.tippyInstance = tippyInstance;

    if (typeof options.container === "string") {
      this.container = document.querySelector(options.container);
      this.build();
    } else if (options.container instanceof HTMLElement) {
      this.container = options.container;
      this.build();
    }
  }

  build() {}

  /**
   *
   * @param {*} element
   * @param {*} option
   *   handler
   *   hideMenu: false
   */
  addClickEventListener(element, option) {
    element.addEventListener("click", () => {
      if (option.handler) {
        const editor = this.editor();
        option.handler({ editor, option, element });
      }
      if (option.hideMenu) {
        this.hide();
      }
      this.update();
    });
  }

  /**
   *
   * @param {*} element
   * @param {*} option
   *   handler
   *   hideMenu: false
   */
  addKeyboardEventListener(element, option) {
    element.addEventListener("keydown", (event) => {
      switch (event.key) {
        case "Enter":
          if (option.handler) {
            const editor = this.editor();
            option.handler({ editor, option, element });
          }
          if (option.hideMenu) {
            this.hide();
          }
          this.update();
          event.preventDefault();
          break;
        case "Escape":
          this.escape();
          event.preventDefault();
          break;
        default:
      }
    });
  }

  editor() {
    return this.ttEditor.editor;
  }

  hide() {
    this.tippyInstance.hide();
  }

  update() {}
}

export class Menubar extends MenuBase {
  build() {
    this.tools = [];
    this.pickers = [];
    this.container.classList.add("tt-menu");
    this.addTools(this.container, this.options.items);

    const clickListener = (e) => {
      this.pickers.forEach((picker) => {
        if (picker.isExpanded() && !picker.container.contains(e.target)) {
          picker.close();
        }
      });
    };
    this.container.addEventListener("click", clickListener);
  }

  addTools() {}

  addModelTools(container, items, models) {
    if (items) {
      items.forEach((item /* index, array */) => {
        if (item === "|") {
          this.addSeparator(container);
        } else {
          const toolClass = models[item];
          const tool = new toolClass(this);
          if (tool instanceof Button) {
            this.addButton(container, tool);
          } else {
            this.addPicker(container, tool);
          }
        }
      });
    } else {
      this.addDefaultTools(container);
    }
  }

  addDefaultTools(/* container */) {}

  addButton(container, button) {
    this.tools.push(button);
    container.appendChild(button.button);
  }

  addPicker(container, picker) {
    this.tools.push(picker);
    this.pickers.push(picker);
    container.appendChild(picker.container);
  }

  addSeparator(container) {
    const sep = new Separator();
    container.appendChild(sep.container);
  }

  hide() {
    super.hide();
    // TippyOptions.onHide中关闭pickers。
    // this.closePickers();
  }

  update() {
    this.tools.forEach((tool /* index, array */) => {
      tool.update();
    });
  }

  closePickers() {
    this.pickers.forEach((picker) => {
      if (picker.isExpanded()) {
        picker.close();
      }
    });
  }
}

export const TABLE_MENUBAR_DEFINES = {
  tableColumn: TableColumnPicker,
  tableRow: TableRowPicker,
  tableCell: TableCellPicker,
  tableProperties: TablePropertiesPicker,
};

export class TableMenubar extends Menubar {
  build() {
    super.build();
    this.container.classList.add("tt-table-menubar");
  }

  addTools(container, items) {
    if (items) {
      this.addModelTools(container, items, TABLE_MENUBAR_DEFINES);
    } else {
      this.addDefaultTools(container);
    }
  }

  addDefaultTools(container) {
    this.addPicker(container, new TableColumnPicker(this));
    this.addPicker(container, new TableRowPicker(this));
    this.addPicker(container, new TableCellPicker(this));
    this.addPicker(container, new TablePropertiesPicker(this));
  }
}

export class LinkMenu extends MenuBase {
  build() {
    this.container.classList.add("tt-menu");
    this.container.classList.add("tt-link-menu");
    this.buildPanel();
  }

  buildPanel() {
    this.urlPreview = document.createElement("a");
    this.urlPreview.classList.add("tt-link-urlpreview");
    this.urlPreview.setAttribute("target", "_blank");
    this.urlPreview.setAttribute("rel", "noopener noreferrer");
    this.container.appendChild(this.urlPreview);

    this.urlInput = document.createElement("input");
    this.urlInput.setAttribute("type", "text");
    this.urlInput.classList.add("tt-link-urlinput");
    this.urlInput.setAttribute("placeholder", "例如: https://abc.com");
    this.container.appendChild(this.urlInput);

    const actionEdit = document.createElement("span");
    actionEdit.tabIndex = "0";
    actionEdit.setAttribute("class", "tt-action tt-panel-icon tt-link-edit");
    actionEdit.innerHTML = Icons.pencil;
    this.container.appendChild(actionEdit);

    const actionDelete = document.createElement("span");
    actionDelete.tabIndex = "0";
    actionDelete.setAttribute(
      "class",
      "tt-action tt-panel-icon tt-link-delete"
    );
    actionDelete.innerHTML = Icons.unlink;
    this.container.appendChild(actionDelete);

    const actionSave = document.createElement("span");
    actionSave.tabIndex = "0";
    actionSave.setAttribute("class", "tt-action tt-panel-icon tt-link-save");
    actionSave.innerHTML = Icons.check;
    this.container.appendChild(actionSave);

    const actionCancel = document.createElement("span");
    actionCancel.tabIndex = "0";
    actionCancel.setAttribute(
      "class",
      "tt-action tt-panel-icon tt-link-cancel"
    );
    actionCancel.innerHTML = Icons.cancel;
    this.container.appendChild(actionCancel);

    this.addClickEventListener(
      actionEdit,
      {
        handler: (/* { editor, option, element, } */) => {
          this.container.classList.add("tt-editing");
        },
      },
      false
    );
    this.addClickEventListener(
      actionDelete,
      {
        hideMenu: true,
        handler: ({ editor /* option, element, */ }) => {
          editor.chain().focus().unsetLink().run();
        },
      },
      false
    );
    this.addClickEventListener(
      actionSave,
      {
        handler: ({ editor /* option, element, */ }) => {
          let url = this.urlInput.value;
          if (url) {
            editor
              .chain()
              .focus()
              .extendMarkRange("link")
              .setLink({ href: url })
              .run();
            this.urlPreview.setAttribute("href", url);
            this.urlPreview.innerHTML = url;
          } else {
            editor.chain().focus().extendMarkRange("link").unsetLink().run();
            this.urlPreview.setAttribute("href", "");
            this.urlPreview.innerHTML = "";
          }
          this.preview();
        },
      },
      false
    );
    this.addClickEventListener(
      actionCancel,
      {
        handler: (/* { editor, option, element, } */) => {
          this.container.classList.remove("tt-editing");
        },
      },
      false
    );
  }

  show(editing) {
    if (editing) {
      this.container.classList.add("tt-editing");
    } else {
      this.container.classList.remove("tt-editing");
    }

    const editor = this.editor();

    this.tippyInstance.setProps({
      getReferenceClientRect: () => {
        const editorView = editor.view;
        const { state } = editorView;
        const { selection } = state;
        const { ranges } = selection;
        const from = Math.min(...ranges.map((range) => range.$from.pos));
        const to = Math.max(...ranges.map((range) => range.$to.pos));
        if (isNodeSelection(state.selection)) {
          const node = editorView.nodeDOM(from); // as HTMLElement
          if (node) {
            return node.getBoundingClientRect();
          }
        }
        return posToDOMRect(editorView, from, to);
      },
    });

    this.fillLink();

    this.tippyInstance.show();
  }

  fillLink() {
    const editor = this.editor();
    const url = editor.getAttributes("link").href;
    if (url) {
      this.urlPreview.setAttribute("href", url);
      this.urlPreview.innerHTML = url;
      this.urlInput.value = url;
    } else {
      this.urlPreview.setAttribute("href", "");
      this.urlPreview.innerHTML = "";
      this.urlInput.value = "";
    }
  }

  preview() {
    this.container.classList.remove("tt-editing");
  }

  isEditing() {
    const linkMenuDom =
      this.tippyInstance.popper.querySelector(".tt-link-menu");
    return linkMenuDom.classList.contains("tt-editing");
  }
}
