<template>
  <div class="container">
    <div class="page-title">服务产品订单</div>
    <div style="padding-bottom: 10px; max-width: 600px">
      <el-form
        ref="orderFilter"
        :model="orderFilter"
        label-width="100px"
        size="small"
      >
        <el-form-item label="订单名">
          <el-input
            v-model="orderFilter.name"
            placeholder="搜索订单名"
            :maxlength="10"
          />
        </el-form-item>
        <el-form-item label="价格">
          <div class="wrs-flexrow--center">
            <el-input
              type="number"
              placeholder="最低"
              v-model="orderFilter.priceMin"
              style="flex: 1"
            >
              <span slot="prefix" style="padding-left: 4px">￥</span>
            </el-input>
            <span style="padding: 0 5px">-</span>
            <el-input
              type="number"
              placeholder="最高"
              v-model="orderFilter.priceMax"
              style="flex: 1"
            >
              <span slot="prefix" style="padding-left: 4px">￥</span>
            </el-input>
          </div>
        </el-form-item>
        <el-form-item label="下单时间">
          <div class="wrs-flexrow--center">
            <el-date-picker
              v-model="orderFilter.createTimeStart"
              type="datetime"
              placeholder="起始时间"
            >
            </el-date-picker>
            <span style="padding: 0 5px">-</span>
            <el-date-picker
              v-model="orderFilter.createTimeEnd"
              type="datetime"
              placeholder="截止时间"
            >
            </el-date-picker>
          </div>
        </el-form-item>
        <el-form-item label="状态">
          <el-dropdown @command="onFilterStateSelected">
            <el-button>
              {{ orderFilter.stateName }}
              <i class="el-icon-arrow-down el-icon--right"></i>
            </el-button>
            <el-dropdown-menu slot="dropdown">
              <el-dropdown-item command="0">全部</el-dropdown-item>
              <el-dropdown-item command="1">服务中</el-dropdown-item>
              <el-dropdown-item command="2">取消</el-dropdown-item>
              <el-dropdown-item command="3">退款</el-dropdown-item>
            </el-dropdown-menu>
          </el-dropdown>
        </el-form-item>
        <el-form-item label="排序">
          <el-dropdown @command="onFilterSortSelected">
            <el-button>
              {{ orderFilter.sortName }}
              <i class="el-icon-arrow-down el-icon--right"></i>
            </el-button>
            <el-dropdown-menu slot="dropdown">
              <el-dropdown-item command="1">下单时间从晚到早</el-dropdown-item>
              <el-dropdown-item command="2">下单时间从早到晚</el-dropdown-item>
            </el-dropdown-menu>
          </el-dropdown>
        </el-form-item>
        <el-form-item size="large">
          <el-button type="primary" @click="onOrderSearchClick">确定</el-button>
          <el-button @click="onOrderSearchReset">重置</el-button>
        </el-form-item>
      </el-form>
    </div>
    <el-table
      :data="orders"
      style="width: 100%; min-width: 1200px"
      :header-cell-style="{ 'background-color': '#EFF3F8' }"
    >
      <el-table-column label="订单名">
        <template slot-scope="scope">
          <span class="wrs-flexrow--center">
            <el-image
              :src="scope.row.imgThumb"
              class="order-img-small"
              fit="cover"
              lazy
            >
              <span slot="placeholder" class="order-img-loading">
                <i class="el-icon-loading"></i> 加载中
              </span>
            </el-image>
            <span class="order-name wrs-ellipsis">
              {{ scope.row.name }}
            </span>
          </span>
        </template>
      </el-table-column>
      <el-table-column label="买家">
        <template slot-scope="scope">
          <span class="wrs-flexrow--center">
            <el-image
              class="order-user-avatar"
              fit="cover"
              :src="scope.row.avatarThumb"
            ></el-image>
            <span class="order-user-name wrs-ellipsis">
              {{ scope.row.uname }}
            </span>
          </span>
        </template>
      </el-table-column>
      <el-table-column
        label="价格(元)"
        prop="price"
        width="140"
      ></el-table-column>
      <el-table-column label="形象报告" width="100">
        <template slot-scope="scope">
          {{ scope.row.imgreport ? "是" : "否" }}
        </template>
      </el-table-column>
      <el-table-column label="单品" width="100">
        <template slot-scope="scope">
          {{ scope.row.clothes ? "是" : "否" }}
        </template>
      </el-table-column>
      <el-table-column label="搭配" width="100">
        <template slot-scope="scope">
          {{ scope.row.dapei ? "是" : "否" }}
        </template>
      </el-table-column>
      <el-table-column label="咨询" width="100">
        <template slot-scope="scope">
          {{ scope.row.zixun ? "是" : "否" }}
        </template>
      </el-table-column>
      <el-table-column
        label="状态"
        prop="stateName"
        class-name="col-ellipsis"
        width="140"
      ></el-table-column>
      <el-table-column
        label="下单时间"
        prop="createDate"
        width="170"
      ></el-table-column>
      <el-table-column label="操作" width="140">
        <template slot-scope="scope">
          <el-button
            @click="onOrderDetailClick(scope.$index, scope.row)"
            type="text"
            size="small"
            >详情</el-button
          >
          <el-button
            v-if="scope.row.imgreport"
            @click="onImageReportsClick(scope.$index, scope.row)"
            type="text"
            size="small"
            >形象报告</el-button
          >
        </template>
      </el-table-column>
    </el-table>
    <el-pagination
      @current-change="onOrderPageChange"
      :current-page="orderPage"
      :page-size="10"
      :page-sizes="[10]"
      layout="sizes, prev, next, jumper"
      class="orders-pagination"
    >
    </el-pagination>

    <!-- 订单详情 -->
    <div v-if="showDetail" class="order-detail">
      <div class="order-header">
        <i
          class="el-icon-back order-header-back"
          @click="onDetailBackClick()"
        ></i>
        <div v-if="order" class="order-header-title wrs-ellipsis">
          {{ order.name }}
        </div>
      </div>
      <el-tabs
        v-if="order"
        type="card"
        v-model="tabsActiveName"
        @tab-click="onDetailTabClick"
      >
        <el-tab-pane label="订单详情" name="1">
          <el-descriptions :column="1" border>
            <el-descriptions-item label="订单名">
              <span class="wrs-flexrow--center">
                <el-image :src="order.imgThumb" class="order-img" fit="cover">
                  <span slot="placeholder" class="order-img-loading">
                    <i class="el-icon-loading"></i> 加载中
                  </span>
                </el-image>
                <span class="order-name">{{ order.name }}</span>
              </span>
            </el-descriptions-item>
            <el-descriptions-item label="价格(元)">
              {{ order.price }}
            </el-descriptions-item>
            <el-descriptions-item label="用户">
              <span class="wrs-flexrow--center">
                <el-image
                  class="order-user-avatar"
                  fit="cover"
                  :src="order.avatarThumb"
                ></el-image>
                <span class="order-user-name">{{ order.uname }}</span>
              </span>
            </el-descriptions-item>
            <el-descriptions-item label="状态">
              {{ order.stateName }}
            </el-descriptions-item>
            <el-descriptions-item label="服务功能">
              <el-checkbox
                label="形象报告"
                :checked="order.imgreport"
                disabled
              ></el-checkbox>
              <el-checkbox
                label="单品"
                :checked="order.clothes"
                disabled
              ></el-checkbox>
              <el-checkbox
                label="搭配"
                :checked="order.dapei"
                disabled
              ></el-checkbox>
              <el-checkbox
                label="咨询"
                :checked="order.zixun"
                disabled
              ></el-checkbox>
            </el-descriptions-item>
            <el-descriptions-item label="下单时间">{{
              order.create_time
            }}</el-descriptions-item>
          </el-descriptions>
        </el-tab-pane>
        <el-tab-pane label="形象报告" name="2" v-if="order.imgreport" lazy>
          <div>
            <div style="padding: 10px 0">
              <el-input
                placeholder="搜索"
                v-model="imgReportSearchTitle"
                size="medium"
                style="max-width: 400px"
              >
                <el-button
                  slot="append"
                  icon="el-icon-search"
                  @click="onImgReportSearchClick"
                ></el-button>
              </el-input>
            </div>
            <el-table
              :data="imgReports"
              style="width: 100%; min-width: 700px"
              :header-cell-style="{ 'background-color': '#EFF3F8' }"
            >
              <el-table-column label="报告名">
                <template slot-scope="scope">
                  <div class="ord-ir-colname-cell">
                    <svg-icon
                      :svg-name="scope.row.icon"
                      svg-class="ord-ir-colname-icon"
                    />
                    <span style="margin-left: 10px">{{ scope.row.title }}</span>
                  </div>
                </template>
              </el-table-column>
              <el-table-column
                prop="updateDate"
                label="最后更新"
                width="170"
              ></el-table-column>
              <el-table-column
                prop="createDate"
                label="创建时间"
                width="170"
              ></el-table-column>
              <el-table-column label="操作" width="100">
                <template slot-scope="scope">
                  <el-button
                    v-if="scope.row.allowOpen"
                    @click="onImgReportOpenClick(scope.$index, scope.row)"
                    type="text"
                    style="padding: 0"
                  >
                    打开
                  </el-button>
                  <el-button
                    v-if="scope.row.allowDownload"
                    @click="onImgReportDownloadClick(scope.$index, scope.row)"
                    type="text"
                    style="padding: 0"
                  >
                    下载
                  </el-button>
                </template>
              </el-table-column>
            </el-table>
            <el-pagination
              @current-change="onImgReportPageChange"
              :current-page="imgReportPage"
              :page-size="10"
              :page-sizes="[10]"
              layout="sizes, prev, next, jumper"
              class="ord-ir-pagination"
            >
            </el-pagination>
          </div>
        </el-tab-pane>
        <!--
        <el-tab-pane label="单品" name="3" v-if="order.clothes" lazy>
        </el-tab-pane>
        <el-tab-pane label="搭配" name="4" v-if="order.dapei" lazy>
        </el-tab-pane>
        -->
      </el-tabs>
    </div>
  </div>
</template>

<script>
import Ocean from "../../api/ocean";
import AliOSS from "../../api/alioss";
import UI from "../../util/ui";
import moment from "moment";
import merge from "webpack-merge";
import ViewContext from "../../viewContext";
import OrderUtil from "../../util/order";
import Icoffice from "../../icoffice";
import ImgReport from "../../util/imgreport";
import UserUtil from "../../util/user";

const TabNames = ["1", "2", "3", "4"];

export default {
  data() {
    return {
      /**
       * 订单列表
       * ---------------------------------
       * createDate: ""
       */
      orders: [],
      orderPage: 0,

      orderFilter: {
        name: "",
        priceMin: "",
        priceMax: "",
        createTimeStart: null,
        createTimeEnd: null,
        stateCommand: "0",
        stateName: "全部",
        orderStates: [],
        orderAStates: [],
        sortCommand: "1",
        sortName: "下单时间从晚到早",
      },

      /**
       * 订单详情
       *   id
       *   name
       */
      order: null,
      orderOps: null,
      showDetail: false,

      /**
       * 形象报告列表
       *
       * ---------------------------------
       * allowOpen: false
       * allowDownload: false
       */
      imgReports: [],
      imgReportPage: 0,
      imgReportSearchTitle: "",

      /**
       * 单品列表
       *
       * ---------------------------------
       */
      // garments: [],
      // garmentPage: 0,
      // garmentSearchTitle: "",

      tabsActiveName: "",
    };
  },
  async mounted() {
    const query = this.$route.query;

    if (query.id) {
      let orderId = parseInt(query.id);

      this.tabsActiveName = TabNames[0];

      if (orderId) {
        await this.getOrder(orderId);
      }

      if (this.order) {
        this.checkInitOrder(this.order);
      } else {
        this.$message("返回服务产品订单列表");
        this.$router.replace({
          query: "",
        });
        this.initOrdersData();
      }
    } else {
      this.initOrdersData();
    }
  },
  methods: {
    /**
     * 获取订单列表
     */
    getOrders: function () {
      const team = ViewContext.getTeam();
      if (!team) return;

      const { orderFilter } = this;
      let params = {
        team_id: team.id,
        by_team: true,
        fields: [
          "id",
          "name",
          "uid",
          "bsid",
          "imgreport",
          "clothes",
          "dapei",
          "zixun",
          "state",
          "astate",
          "price",
          "create_time",
          "bsimg",
          "uname",
          "avatar",
          "is_fullavatar",
        ],
        sort_by: orderFilter.sortCommand === "2" ? 2 : 1,
        offset: UI.computePagingOffset(this.orderPage, 10),
        num: 10,
      };

      if (orderFilter.orderStates.length > 0) {
        params.states = orderFilter.orderStates;
      }
      if (orderFilter.orderAStates.length > 0) {
        params.astates = orderFilter.orderAStates;
      }
      if (orderFilter.name) {
        params.name = orderFilter.name;
      }
      if (orderFilter.priceMin) {
        params.price_min = Number(orderFilter.priceMin);
      }
      if (orderFilter.priceMax) {
        params.price_max = Number(orderFilter.priceMax);
      }
      if (orderFilter.createTimeStart) {
        let createTimeStart = moment(orderFilter.createTimeStart);
        params.create_time_start = createTimeStart.format(
          "YYYY-MM-DD HH:mm:ss"
        );
      }
      if (orderFilter.createTimeEnd) {
        let createTimeEnd = moment(orderFilter.createTimeEnd);
        params.create_time_end = createTimeEnd.format("YYYY-MM-DD HH:mm:ss");
      }

      Ocean.getBizOrders(params)
        .then((res) => {
          let orders = [];
          let rorders = res.data.orders;
          if (rorders) {
            for (let item of rorders) {
              item.imgThumb = item.bsimg
                ? AliOSS.getImageThumbnailCdnUrl(item.bsimg)
                : "";

              item.avatarThumb = UserUtil.makeAvatarThumb(
                {
                  id: item.uid,
                  avatar: item.avatar,
                  is_fullavatar: item.is_fullavatar,
                },
                {
                  useDefault: true,
                }
              );

              if (item.create_time) {
                let createTime = moment(item.create_time);
                item.createDate = createTime.format("YYYY-MM-DD HH:mm:ss");
              }

              item.stateName = this.makeStateNameOnList(item);

              // 管理员有权限删除订单
              // 暂时不支持删除订单
              // item.allowDelete = isManage && OrderUtil.checkAllowDelete(item)
              // item.showOpMore = item.allowDelete

              orders.push(item);
            }
          }
          this.orders = orders;
        })
        .catch((err) => {
          this.$message.error(err.data.msg);
        });
    },
    makeStateNameOnList: function (order) {
      const { BsOrderAfterState } = Ocean;
      if (order.astate === BsOrderAfterState.WaitingTeamCancel) {
        return "取消中";
      } else if (order.astate === BsOrderAfterState.WaitingTeamRefund) {
        return "退款中";
      }

      return Ocean.getBsOrderStateName(order.state, true, order.price);
    },
    initOrdersData() {
      this.orderPage = 1;
      this.getOrders();
    },
    onOrderSearchClick() {
      this.orderPage = 1;
      this.getOrders();
    },
    onOrderSearchReset() {
      this.orderFilter = {
        name: "",
        priceMin: "",
        priceMax: "",
        createTimeStart: null,
        createTimeEnd: null,
        stateCommand: "0",
        stateName: "全部",
        orderStates: [],
        orderAStates: [],
        sortCommand: "1",
        sortName: "下单时间从晚到早",
      };
    },
    onFilterStateSelected(command) {
      let { orderFilter } = this;
      const { BsOrderState, BsOrderAfterState } = Ocean;

      orderFilter.stateCommand = command;
      orderFilter.orderStates = [];
      orderFilter.orderAStates = [];
      switch (command) {
        case "0":
          orderFilter.stateName = "全部";
          break;
        case "1":
          orderFilter.stateName = "服务中";
          orderFilter.orderStates = [
            BsOrderState.InService,
            BsOrderState.Created,
            BsOrderState.Paid,
            BsOrderState.ServiceDone,
          ];
          orderFilter.orderAStates = [
            BsOrderAfterState.None,
            BsOrderAfterState.TeamRejectCancel,
            BsOrderAfterState.TeamRejectRefund,
          ];
          break;
        case "2":
          orderFilter.stateName = "取消";
          orderFilter.orderAStates = [
            BsOrderAfterState.WaitingTeamCancel,
            BsOrderAfterState.Canceled,
          ];
          break;
        case "3":
          orderFilter.stateName = "退款";
          orderFilter.orderAStates = [
            BsOrderAfterState.WaitingTeamRefund,
            BsOrderAfterState.Refunded,
          ];
          break;
        default:
          break;
      }
    },
    onFilterSortSelected(command) {
      let { orderFilter } = this;

      orderFilter.sortCommand = command;
      switch (command) {
        case "1":
          orderFilter.sortName = "下单时间从晚到早";
          break;
        case "2":
          orderFilter.sortName = "下单时间从早到晚";
          break;
        default:
          break;
      }
    },
    onOrderPageChange(pageNum) {
      this.orderPage = pageNum;
      this.getOrders();
    },
    async onOrderDetailClick(index, row) {
      this.tabsActiveName = TabNames[0];

      this.showDetail = true;
      this.$router.replace({
        query: merge(this.$route.query, {
          id: row.id,
        }),
      });

      await this.getOrder(row.id);
    },
    async onImageReportsClick(index, row) {
      this.tabsActiveName = TabNames[1];

      this.showDetail = true;
      this.$router.replace({
        query: merge(this.$route.query, {
          id: row.id,
        }),
      });

      await this.getOrder(row.id);

      if (this.order) {
        this.initTabData(this.tabsActiveName);
      }
    },

    onDetailBackClick: function () {
      this.showDetail = false;

      this.$router.replace({
        query: "",
      });

      this.resetOrderDetail();

      // 页面以订单详情打开时，返回列表需显示订单列表
      if (this.orderPage === 0) {
        this.initOrdersData();
      }
    },
    resetOrderDetail: function () {
      this.order = null;

      this.imgReports = [];
      this.imgReportPage = 0;
      this.imgReportSearchTitle = "";

      this.tabsActiveName = "";
    },

    /**
     * 获取订单信息
     */
    getOrder: async function (id) {
      const fields = [
        "id",
        "name",
        "uid",
        "teamid",
        "bsid",
        "imgreport",
        "clothes",
        "dapei",
        "zixun",
        "state",
        "astate",
        "price",
        "create_time",
        "bsimg",
        "uname",
        "avatar",
        "is_fullavatar",
      ];
      await Ocean.getBizOrder(id, true, fields)
        .then((res) => {
          let order = res.data.order;

          order.imgThumb = order.bsimg
            ? AliOSS.getImageThumbnailCdnUrl(order.bsimg)
            : "";

          order.avatarThumb = UserUtil.makeAvatarThumb(
            {
              id: order.uid,
              avatar: order.avatar,
              is_fullavatar: order.is_fullavatar,
            },
            {
              useDefault: true,
            }
          );

          order.stateName = this.makeStateNameOnDetail(order);
          this.order = order;
          this.orderOps = this.makeOpsOnDetail(order);
        })
        .catch((err) => {
          this.$message.error(err.data.msg);
        });
    },
    makeStateNameOnDetail: function (order) {
      const { BsOrderAfterState } = Ocean;
      if (order.astate === BsOrderAfterState.WaitingTeamCancel) {
        return "买家申请取消订单";
      } else if (order.astate === BsOrderAfterState.WaitingTeamRefund) {
        return "买家申请退款";
      }

      return Ocean.getBsOrderStateName(order.state, true, order.price);
    },
    makeOpsOnDetail: function (order) {
      // let { isManage } = this.data
      let ops = OrderUtil.checkOps(order);
      // 管理员有权限删除订单
      // 暂时不支持删除订单
      // ops.allowDelete = isManage && OrderUtil.checkAllowDelete(order)
      // ops.showMoreOp = ops.allowDelete
      ops.showOps =
        ops.allowAgreeCancel ||
        ops.allowRejectCancel ||
        ops.allowAgreeRefund ||
        ops.allowRejectRefund ||
        ops.allowServiceDone ||
        ops.allowAlter ||
        ops.showMoreOp;
      return ops;
    },

    /**
     * 检查订单。
     * 1. 如果订单所属顾问号不是用户参与的顾问号，则报错并跳转页面。
     */
    checkInitOrder: function (order) {
      let user = Icoffice.getUser();
      let params = {
        team_id: order.teamid,
        uid: user.id,
        fields: ["id"],
      };
      Ocean.getMembers(params)
        .then((res) => {
          let rmembers = res.data.members;
          if (rmembers && rmembers.length > 0) {
            this.showDetail = true;
          } else {
            this.$message("返回服务产品订单列表");
            this.$router.replace({
              query: "",
            });
            this.initOrdersData();
          }
        })
        .catch((err) => {
          this.$message.error(err.data.msg);
        });
    },

    onDetailTabClick: function (tab /* event */) {
      if (this.order) {
        this.initTabData(tab.name);
      }
    },
    initTabData: function (tabName) {
      switch (tabName) {
        case "2":
          if (this.imgReportPage === 0) {
            this.imgReportPage = 1;
            this.getReports();
          }
          break;
        // case "3":
        //   if (this.garmentPage === 0) {
        //     this.garmentPage = 1;
        //     this.getReports();
        //   }
        //   break;
        // case "4":
        //   break;
        default:
          break;
      }
    },

    /**
     * 获取形象报告列表
     */
    getReports: function () {
      let { order, imgReportPage, imgReportSearchTitle } = this;
      let params = {
        orderid: order.id,
        fields: ["id", "title", "url", "doctype", "update_time", "create_time"],
        offset: UI.computePagingOffset(imgReportPage, 10),
        num: 10,
      };

      if (imgReportSearchTitle) {
        params.title = imgReportSearchTitle;
      }

      Ocean.getOrderImgRpts(params)
        .then((res) => {
          let imgReports = [];
          if (res.data.reports) {
            for (let m of res.data.reports) {
              if (m.doctype) {
                const rptModel = ImgReport.getModel(m.doctype);
                m.icon = rptModel.icon;
                m.allowOpen = m.doctype === "rtf";
                m.allowDownload = !m.allowOpen;
              }
              if (m.update_time) {
                let updateTime = moment(m.update_time);
                m.updateDate = updateTime.format("YYYY-MM-DD HH:mm:ss");
              }
              if (m.create_time) {
                let createTime = moment(m.create_time);
                m.createDate = createTime.format("YYYY-MM-DD HH:mm:ss");
              }
              imgReports.push(m);
            }
          }
          this.imgReports = imgReports;
        })
        .catch((err) => {
          this.$message.error(err.data.msg);
        });
    },
    onImgReportSearchClick: function () {
      this.imgReportPage = 1;
      this.getReports();
    },
    onImgReportPageChange: function (pageNum) {
      this.imgReportPage = pageNum;
      this.getReports();
    },
    onImgReportOpenClick: function (index, row) {
      window.open(
        `/coa/bsimagereport?i=${row.id}&n=${row.url}&t=1&title=${row.title}`,
        "_blank"
      );
    },
    onImgReportDownloadClick: async function (index, row) {
      let ossClient = await AliOSS.getClientCdn();
      const rptModel = ImgReport.getModel(row.doctype);
      let link = document.createElement("a");
      link.target = rptModel.aTarget;
      link.download = row.title;
      if (!link.download.endsWith(`.${row.doctype}`)) {
        link.download += `.${row.doctype}`;
      }
      const rptOssKey = AliOSS.getImageReportKey(row.url, row.id);
      link.href = ossClient.signatureUrl(rptOssKey, {
        response: {
          "content-disposition": `attachment; filename=${encodeURIComponent(
            link.download
          )}`,
        },
      });
      link.click();
    },
  },
};
</script>

<style scoped lang="scss">
.container {
  width: 100%;
  padding: 50px 50px 60px 270px;
  box-sizing: border-box;
}

.title {
  padding-top: 10px;
  font-size: 24px;
  font-weight: 400;
  line-height: 1;
}

::v-deep .col-ellipsis .cell {
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}

.orders-pagination {
  padding-top: 8px;
}

.order-detail {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  padding: 50px 50px 60px 270px;
  box-sizing: border-box;
  background-color: #ffffff;
  overflow: auto;
  z-index: 99;
}

.order-header {
  padding: 24px 0 20px 0;
  font-size: 20px;
  line-height: 1;
  background-color: #ffffff;
}

.order-header-back {
  font-size: 28px;
  font-weight: 500;
  cursor: pointer;
}

.order-header-title {
  display: inline-block;
  margin-left: 8px;
  max-width: 400px;
}

.order-name {
  margin-left: 10px;
}

.order-img {
  width: 60px;
  height: 60px;
  border-radius: 3px;
  flex-shrink: 0;
}
.order-img-small {
  width: 40px;
  height: 40px;
  border-radius: 3px;
  flex-shrink: 0;
}
.order-img-loading {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 14px;
  color: #c0c4cc;
  vertical-align: middle;
  background: #f5f7fa;
}

.order-user-avatar {
  width: 36px;
  height: 36px;
  border-radius: 50%;
  background-color: #f5f5f5;
  flex-shrink: 0;
}

.order-user-name {
  margin-left: 10px;
}

.ord-ir-colname-cell {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.ord-ir-colname-icon {
  width: 24px;
  height: 24px;
  border-radius: 3px;
  flex-shrink: 0;
}

.ord-ir-pagination {
  padding-top: 8px;
}
</style>
